export enum Sportsbooks {
	BetMGM = 'BetMGM',
	Caesars = 'Caesars',
	DraftKings = 'DraftKings',
	ESPNBet = 'ESPN Bet',
	FanDuel = 'FanDuel',
	HardRock = 'Hard Rock',
	Fanatics = 'Fanatics'
}

export default Sportsbooks
