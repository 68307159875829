import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'stores'

export const RequireUser = observer(
	({ children }: { children: JSX.Element }): JSX.Element => {
		const { authStore } = useStore()
		const location = useLocation()
		const navigate = useNavigate()

		useEffect(() => {
			if (!authStore.token) {
				navigate('/start', {
					replace: true,
					state: { from: location.state?.from }
				})
			}
		}, [authStore.token])
		return children
	}
)
