import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { LayoutApp, Typography } from 'components'
import { ApplePricingTable, StripePricingTable } from 'components/subscription'
import { observer } from 'mobx-react'
import { useStore } from 'stores'
import { IAPProduct } from 'interfaces'
import pricingText from 'json/translations/pages/subscriptionPricing.json'
import styles from './styles.module.scss'

declare const window: Window & {
	// eslint-disable-next-line
	webkit?: any
}

export const SubscriptionPricing: React.FC = observer((): JSX.Element => {
	const location = useLocation()
	const navigate = useNavigate()
	const { authStore, userStore, appleIAPStore } = useStore()
	const { userId } = userStore.user

	// Reroute user to home page if they are subscribed and just authenticated.
	useEffect(() => {
		if (
			location.state?.from?.pathname?.includes('signin') &&
			userStore.isSubscribed &&
			authStore.token
		) {
			navigate('/home', {
				replace: true
			})
		}
	}, [userStore.isSubscribed, authStore.token])

	useEffect(() => {
		if (!userId && !authStore.showSignInFooter) {
			authStore.showSignInPopUp()
		}

		return () => {
			authStore.closeSignInPopUp()
		}
	}, [userId, authStore.showSignInFooter])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Subscription Pricing'
		})

		// First initialize the IAP Store to retrieve the product subscriptions
		// from the native Swift layer if the user is in iOS app
		// Use webkit messageHandlers and window event listeners to fetch subscription products
		// from native Swift layer
		if (
			window?.webkit?.messageHandlers?.['iap-purchase-request'] &&
			window?.webkit?.messageHandlers?.['iap-products-request']
		) {
			appleIAPStore.setIsIOSIAPCapable(true)

			// Listen for subscription products result
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.addEventListener('iap-products-result', (event: CustomEvent) => {
				if (event?.detail) {
					const subscriptions = JSON.parse(event.detail) as IAPProduct[]
					appleIAPStore.setSubscriptions(subscriptions)
				}
			})

			// Then send message event to retrieve subscription products
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.webkit?.messageHandlers?.['iap-products-request']?.postMessage(
				appleIAPStore.subscriptionProductIds.slice()
			)
		}
	}, [])

	return (
		<LayoutApp footer headerSpacing isLoading={false}>
			<div
				className={clsx(styles.containerHome, 'overflow-y-scroll bg-black-900')}
				style={{
					height: '100vh',
					paddingBottom: '20rem',
					paddingTop: '1.5rem'
				}}
			>
				{appleIAPStore.isIOSIAPCapable ? (
					<ApplePricingTable />
				) : (
					<StripePricingTable />
				)}
				<br />
				<div className="border-b border-b-neutral-200/30 my-[1rem] mx-[1.5rem]" />
				<div className="flex flex-row items-center text-center justify-center py-[24px]">
					<div className="mx-[16px] gap-2">
						<Link to="/privacy-policy" replace>
							<Typography
								type="title-small"
								label={t`Privacy Policy`}
								className="text-neutral-50"
							/>
						</Link>
					</div>
					<div className="mx-[16px] gap-2">
						<Link to="/terms" replace>
							<Typography
								type="title-small"
								label={t`Terms and Conditions`}
								className="text-neutral-50"
							/>
						</Link>
					</div>
				</div>
				{appleIAPStore.isIOSIAPCapable && (
					<div className="items-center text-center px-[1rem]">
						<Typography
							type="label-small"
							label={t`${pricingText.applePaymentSummation}`}
							className="text-neutral-200"
						/>
					</div>
				)}
			</div>
		</LayoutApp>
	)
})
