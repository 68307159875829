import { BaseURL } from 'api'
import axios from 'axios'
import { AppleSubscriptionResponse, IAPTransaction } from 'interfaces'
import Cookies from 'universal-cookie'

const token = new Cookies()

export const createAppleSubscription = async (body: {
	userId: string
	transaction: IAPTransaction
}): Promise<AppleSubscriptionResponse> => {
	const { data } = await axios.post(`${BaseURL}/subscriptions/apple`, body, {
		headers: {
			Authorization: `Bearer ${token.get('token')}`
		}
	})
	return data
}
