import axios from 'axios'
import Cookies from 'universal-cookie'
import { BaseURL } from 'api'

export interface DeleteSubscriptionResponse {
	message?: string
	error?: Record<string, unknown> | string
}

export const cancelSubscription = async (
	userId: string
): Promise<DeleteSubscriptionResponse | null> => {
	const cookies = new Cookies()
	let res = null as DeleteSubscriptionResponse | null
	await axios
		.delete(`${BaseURL}/subscriptions`, {
			headers: {
				Authorization: `Bearer ${cookies.get('token')}`
			},
			params: {
				user_id: userId
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)
		.catch(console.error)
	return res
}
