import { Button, Icon, Typography } from 'components'
import { Icons } from 'interfaces'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ModalSuccess: React.FC = (): JSX.Element => {
	const navigate = useNavigate()
	const handleClick = (): void => {
		navigate('/home', { replace: true })
	}
	return (
		<div className="w-[358px] h-[272px] flex flex-col justify-center items-center gap-8 rounded-[20px] p-9 bg-black-400">
			<div className="w-full flex flex-col justify-center items-center gap-2">
				<div className="w-14 h-14 border-4 border-primary-500 rounded-full">
					<Icon src={Icons.check} fillPath className="text-primary-500" />
				</div>
				<div className="w-full flex flex-col justify-center items-center gap-1">
					<Typography
						type="headline-small"
						label="Welcome!"
						className="text-[#FFF]"
					/>
					<Typography
						type="headline-xxsmall"
						label="Registration completed"
						className="text-[#FFF]"
					/>
				</div>
			</div>
			<Button
				type="button"
				label="Continue"
				className="w-60 py-4 border-none"
				onClick={() => handleClick()}
			>
				Continue
			</Button>
		</div>
	)
}
