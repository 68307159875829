/* eslint-disable no-restricted-globals */
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { t } from '@lingui/macro'
import { login, registerx } from 'api'
import clsx from 'clsx'
import {
	Button,
	CountdownSendCode,
	Icon,
	Loading,
	Typography
} from 'components'
import { useToast } from 'hooks'
import { Icons } from 'interfaces'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { CodePhone } from '../select/selectCodePhone'

export type FormDataLogin = {
	email: string | null
	phone: number | null
	digit: string | null
}

const Tabs = [
	// {
	// 	id: 'Phone',
	// 	label: 'Phone'
	// },
	{
		id: 'Email',
		label: 'Email'
	}
]
export interface RegisterLoginProps {
	setDataRegister: React.Dispatch<
		React.SetStateAction<{
			email: string | null
			phone: string | null
			digitValidation: string
			expTime: number
		}>
	>
}

export const RegisterLogin: React.FC<RegisterLoginProps> = ({
	setDataRegister
}): JSX.Element => {
	const location = useLocation()
	const { showToast } = useToast()

	const [labelTab, setLabelTab] = useState('Email')
	const [sent, setSent] = useState(false)
	const [disabledBtn, setDisabledBtn] = useState(false)
	const [showInputValidation, setShowInputValidation] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [codePhone, setCodePhone] = useState('+1')
	// const [errorDigit, setErrorDigit] = useState(false)

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, isValid },
		watch
	} = useForm<FormDataLogin>({
		mode: 'all',
		defaultValues: {
			email: null,
			phone: null,
			digit: ''
		}
	})
	const watchEmail = watch('email')
	const validEmailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	useEffect(() => {
		reset()

		return () => {
			setShowInputValidation(false)
			setSent(false)
			setDisabledBtn(false)
		}
	}, [labelTab])

	const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const val = e.target.value
		let cadena = val
		if (isNaN(parseInt(val, 10))) {
			cadena = val.substring(0, val.length - 1)
			setValue('digit', cadena)
		}
		setDataRegister(prev => ({
			...prev,
			digitValidation: cadena.replace(/[^\d]/g, '')
		}))
	}

	const onSubmit: SubmitHandler<FormDataLogin> = async data => {
		setIsLoading(true)
		setSent(true)
		setValue('digit', '')
		setDataRegister(prev => ({
			...prev,
			digitValidation: ''
		}))
		if (location.pathname === '/signin') {
			try {
				const res = await login({
					...data,
					phone:
						parseInt(data.phone?.toString().replaceAll('-', '') || '', 10) ||
						null,
					code: codePhone
				})
				if (res?.message) {
					showToast('Error', `${res?.message}`, 'error')
					setShowInputValidation(false)
					setSent(false)
					setDisabledBtn(false)
					reset()
				} else {
					setDataRegister(prev => ({
						...prev,
						email: data.email || null,
						phone: data.phone?.toString() || null,
						expTime: res ? res.expirationTime : 0
					}))
					setShowInputValidation(true)
				}
			} catch (error) {
				setIsLoading(false)
				setShowInputValidation(false)
				showToast('Error', `Error: ${error}`, 'error')
				setSent(false)
			} finally {
				setIsLoading(false)
			}
		}
		if (location.pathname === '/register') {
			try {
				const res = await registerx({ ...data, code: codePhone })
				if (res?.message) {
					showToast('Error', `Error: ${res?.message}`, 'error')
					setSent(false)
					setDisabledBtn(false)
					setShowInputValidation(false)
				} else {
					setDataRegister(prev => ({
						...prev,
						email: data.email || null,
						phone: data.phone?.toString() || null,
						expTime: res ? res.expirationTime : 0
					}))
					setShowInputValidation(true)
				}
			} catch (error) {
				setSent(false)
				setDisabledBtn(false)
				setShowInputValidation(false)
				showToast('Error', `Error: ${error}`, 'error')
			} finally {
				setIsLoading(false)
			}
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="w-full flex justify-center items-center gap-6 pb-4">
				{Tabs.map(tab => (
					<div
						key={tab.id}
						className={clsx(
							'w-32 flex justify-center items-center pb-2 border-b-2',
							labelTab === tab.label
								? 'border-b-primary-500'
								: 'border-b-transparent'
						)}
						aria-hidden
						onClick={() => setLabelTab(tab.label)}
					>
						<Typography
							type="title-small"
							label={tab.label}
							className={
								labelTab === tab.label ? 'text-neutral-50' : 'text-neutral-700'
							}
						/>
					</div>
				))}
			</div>
			<div className="w-full flex flex-col gap-4">
				{labelTab === 'Phone' && (
					<div className="w-full flex flex-col justify-start gap-2">
						<div
							className={clsx(
								'w-full h-12 flex items-center px-[10px] py-2',
								'bg-transparent border border-neutral-300 rounded-md'
							)}
						>
							<CodePhone setCodePhone={setCodePhone} />
							<input
								type="number"
								placeholder="Phone number"
								autoComplete="off"
								className="w-full h-full bg-transparent appearance-none focus:outline-none text-neutral-200 text-3 leading-4"
								{...register('phone', {
									required: 'This field is required',
									minLength: {
										value: 10,
										message: 'Invalid phone. Must be min length 10 characters'
									},
									maxLength: {
										value: 10,
										message: 'Invalid phone. Must be max length 10 characters'
									}
								})}
							/>
						</div>
						{errors && errors.phone && (
							<div className="w-full flex gap-1">
								<div className="w-4 h-4">
									<ExclamationCircleIcon className="w-4 h-4 text-danger-500" />
								</div>
								<Typography
									type="body-small"
									label={`${errors.phone.message}`}
									className="text-danger-500 text-left"
								/>
							</div>
						)}
					</div>
				)}
				{labelTab === 'Email' && (
					<div className="w-full flex flex-col justify-start gap-2">
						<div
							className={clsx(
								'w-full h-12 flex items-center gap-2 px-[10px] py-2',
								'bg-transparent border border-neutral-300 rounded-md'
							)}
						>
							<div className="w-6 h-6">
								<Icon src={Icons.mail} fillPath className="text-neutral-100" />
							</div>
							<input
								type="email"
								placeholder="example@email.com"
								autoComplete="off"
								className="w-full h-full bg-transparent appearance-none focus:outline-none text-neutral-200 text-3 leading-4"
								{...register('email', {
									required: 'This field is required',
									pattern: {
										value: validEmailRegex,
										message: 'Invalid email address.'
									}
								})}
							/>
						</div>
						{errors && errors.email && (
							<div className="w-full flex gap-1">
								<div className="w-4 h-4">
									<ExclamationCircleIcon className="w-4 h-4 text-danger-500" />
								</div>
								<Typography
									type="body-small"
									label={`${errors.email.message}`}
									className="text-danger-500 text-left"
								/>
							</div>
						)}
					</div>
				)}
				<div
					className={clsx(
						'w-full flex',
						sent ? 'justify-between' : 'justify-end'
					)}
				>
					{sent && (
						<CountdownSendCode
							deadline={moment(Date.now()).add(30, 'seconds').toDate()}
							setSent={setSent}
							setDisabledBtn={setDisabledBtn}
						/>
					)}
					<Button
						type="submit"
						label={t`Send Code`}
						color="Secondary"
						size="small"
						className={clsx({ 'border-none': isValid })}
						disabled={
							disabledBtn ||
							watchEmail === '' ||
							watchEmail === null ||
							!watchEmail.match(validEmailRegex)
						}
					/>
				</div>
				{showInputValidation && (
					<>
						<input
							type="text"
							placeholder="Enter 5-digit code"
							autoComplete="off"
							className={clsx(
								'w-full h-12 bg-neutral-400 rounded appearance-none focus:outline-none',
								'text-[12px] leading-[16px] font-medium font-Poppins text-white-200 pl-[10px]',
								'placeholder:text-[14px] placeholder:leading-[20px] placeholder:text-white-200/50'
							)}
							{...register('digit', {
								// required: 'this value is required',
								pattern: {
									value: /^[0-9]{5}$/,
									message: 'Invalid code. You can only enter numbers'
								},
								min: {
									value: 0,
									message: 'value negative not allowed'
								},
								minLength: {
									value: 5,
									message: 'Invalid value. Must be min length 5 characters'
								},
								maxLength: {
									value: 5,
									message: 'Invalid value. Must be max length 5 characters'
								}
							})}
							onChange={handleChangeNumber}
							min={0}
							max={5}
							maxLength={5}
						/>
						{errors && errors.digit && (
							<div className="w-full flex gap-1">
								<div className="w-4 h-4">
									<ExclamationCircleIcon className="w-4 h-4 text-danger-500" />
								</div>
								<Typography
									type="body-small"
									label={`${errors.digit.message}`}
									className="text-danger-500 text-left"
								/>
							</div>
						)}
					</>
				)}
			</div>
			{isLoading && (
				<div className="absolute inset-0 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
					<Loading size="large" />
				</div>
			)}
		</form>
	)
}
