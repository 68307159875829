/* eslint-disable react/button-has-type */
import clsx from 'clsx'
import React from 'react'
import { ButtonProps } from 'interfaces/index'
import { Icon } from 'components/icon'
import { Link } from 'react-router-dom'

const ButtonContent: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	label,
	disabled = false,
	color = 'Primary',
	fill = 'fill',
	rounded = false,
	size = 'full',
	iconLeft,
	iconRight,
	loading,
	className,
	buttonLabelClassName,
	iconRightDivClassName,
	iconRightClassName,
	onClick,
	...props
}): JSX.Element => {
	return (
		<button
			{...props}
			data-testid={`test_${label}`}
			disabled={disabled}
			onClick={onClick}
			className={clsx(
				'flex justify-center items-center px-4 gap-2 group',
				{
					'bg-primary-500 text-white-100 hover:bg-opacity-80 hover:border-primary-500':
						!disabled && color === 'Primary' && fill === 'fill'
				},
				{
					'bg-neutral-50 text-primary-500 border border-primary-500 hover:bg-primary-500 hover:text-white-100':
						!disabled && color === 'Primary' && fill === 'no-fill'
				},
				{
					'bg-transparent text-primary-500 border border-primary-500':
						!disabled && color === 'Primary' && fill === 'transparent'
				},
				{
					'bg-neutral-500 text-neutral-200 border border-neutral-200 hover:text-white-50':
						color === 'Secondary' && fill === 'fill'
				},
				{
					'bg-neutral-300 text-neutral-200/50 border border-neutral-300 cursor-not-allowed':
						disabled || color === 'Disabled'
				},
				{
					'bg-neutral-300 text-neutral-50': !disabled && color === 'Inactive'
				},
				{
					'bg-transparent text-danger-500 border border-danger-500 hover:bg-danger-500 hover:text-white-100':
						!disabled && color === 'Danger'
				},
				rounded ? 'rounded-full' : 'rounded-lg',
				{ 'w-full h-14 text-base': size === 'full' },
				{ 'w-full h-10 text-base': size === 'medium' },
				{ 'w-auto h-10 text-base': size === 'small' },
				{ 'w-auto h-8 text-sm': size === 'extra-small' },
				className
			)}
		>
			{iconLeft && (
				<div className="w-4 h-4 flex justify-center items-center">
					<Icon fillPath className="max-h-full max-w-full" src={iconLeft} />
				</div>
			)}
			{loading && (
				<span className="w-full flex gap-2 items-center justify-center">
					<svg
						width="20"
						height="20"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						className="animate-spin fill-white"
					>
						<path
							d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
							opacity=".25"
						/>
						<path
							// eslint-disable-next-line max-len
							d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
						/>
					</svg>
					Loading...
				</span>
			)}
			{label && (
				<span
					className={clsx('text-[14px] font-poppins', buttonLabelClassName)}
				>
					{label}
				</span>
			)}
			{iconRight && (
				<div
					className={clsx(
						'w-4 h-4 flex items-center justify-center',
						iconRightDivClassName
					)}
				>
					<Icon
						fillPath
						className={clsx('max-h-full max-w-full', iconRightClassName)}
						src={iconRight}
					/>
				</div>
			)}
		</button>
	)
}

export const Button: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	label,
	disabled = false,
	color = 'Primary',
	rounded = false,
	size = 'full',
	iconLeft,
	linkTo = null,
	onClick,
	className,
	classNameDiv,
	iconRightDivClassName,
	iconRightClassName,
	...props
}) => {
	return (
		<div className={clsx('w-max h-max', classNameDiv)}>
			{linkTo ? (
				<Link to={linkTo} replace>
					<ButtonContent
						{...props}
						label={label}
						disabled={disabled}
						color={color}
						rounded={rounded}
						size={size}
						iconLeft={iconLeft}
						className={className}
						iconRightDivClassName={iconRightDivClassName}
						iconRightClassName={iconRightClassName}
					/>
				</Link>
			) : (
				<ButtonContent
					{...props}
					label={label}
					disabled={disabled}
					color={color}
					rounded={rounded}
					size={size}
					iconLeft={iconLeft}
					onClick={onClick}
					className={className}
					iconRightDivClassName={iconRightDivClassName}
					iconRightClassName={iconRightClassName}
				/>
			)}
		</div>
	)
}

export default Button
