import { BaseURL, BaseURLv2 } from 'api'
import axios from 'axios'
import {
	LastEvaluatedKey,
	StoryFilterInputs,
	updateImageProps
} from 'interfaces'
import { StoryProbly } from '.'

export interface UserProfile {
	userId: string
	userName: string
	email: string
	phone: string
	avatarUrl: string
	lastOptCode: string
	subscriptionStatus: string
	subscriptionPlanId: string
	createAt: string
	isVerified: string
	updateAt: string
	isAppleSubscriber?: boolean
}

export interface PollResponses {
	bets: number
	YesProbly: number
	NoProbly: number
}

export interface ProfileResponse {
	user: UserProfile
	pollResponses: PollResponses
}

export const getProfile = async (
	token: string
): Promise<ProfileResponse | null> => {
	let res = null as ProfileResponse | null
	if (token) {
		await axios
			.get(`${BaseURL}/user/profile`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}

export const getUploadImage = async (
	typeImg: string,
	token: string
): Promise<updateImageProps | null> => {
	let res = null as updateImageProps | null
	await axios
		.get(`${BaseURL}/user/profile/upload_image?file_type=${typeImg || 'png'}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export interface TermsResponse {
	title: string
	description: string
	htmlContent: string
	error?: string
}

export const getTerms = async (): Promise<TermsResponse | null> => {
	let res = null as TermsResponse | null
	await axios.get(`${BaseURL}/terms/1`).then(
		async response => {
			res = response.data
		},
		error => {
			console.error(error)
		}
	)
	return res
}

export interface FAQ {
	faqsId: string
	question: string
	answer: string
}
export interface FaqsResponse {
	count: number
	count_page: number
	data: FAQ[]
	next: number | null
	previous: number | null
	page: number
	records: number
}

export const getFAQs = async ({
	page,
	token
}: {
	page: number
	token: string
}): Promise<FaqsResponse | null> => {
	let res = null as FaqsResponse | null
	await axios
		.get(`${BaseURL}/faqs?records=${page * 20}&page=${page}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export interface TrackingEventsResponse {
	count: number
	count_page: number
	data: StoryProbly[]
	next: number | null
	previous: number | null
	page: number
	records: number
}

export interface TrackingEventsResponseV2 {
	LastEvaluatedKey?: LastEvaluatedKey
	data: StoryProbly[]
}

export interface TrackingEventsOptions extends StoryFilterInputs {
	search?: string
	lastEvaluatedKey?: LastEvaluatedKey
	token: string
}

export const getTrackingEventsByKey = async (
	options?: TrackingEventsOptions
): Promise<TrackingEventsResponseV2> => {
	return (
		await axios.post(
			`${BaseURLv2}/stories/track`,
			{
				LastEvaluatedKey: options?.lastEvaluatedKey
			},
			{
				params: {
					search: options?.search,
					probabilityMin: options?.probabilityMin,
					probabilityMax: options?.probabilityMax,
					problyScoreMin: options?.problyScoreMin,
					lastUpdatedMax: options?.lastUpdatedMax,
					isLive: options?.isLive
				},
				headers: {
					Authorization: `Bearer ${options?.token}`
				}
			}
		)
	).data
}

export const getTrackingEvents = async (
	page: number,
	search: string,
	token: string
): Promise<TrackingEventsResponse | null> => {
	let res = null as TrackingEventsResponse | null
	await axios
		.get(
			`${BaseURL}/stories/track?page=${page}&records=10${
				search ? `&search=${search}` : ''
			}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
