import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { deleteProfile } from 'api'
import {
	Button,
	HeaderProfile,
	LayoutApp,
	Typography,
	ModalDelete
} from 'components'
import { useToast } from 'hooks'
import { Icons } from 'interfaces'
import { useStore } from 'stores'

export const Settings: React.FC = () => {
	const navigate = useNavigate()
	const { authStore } = useStore()
	const { showToast } = useToast()
	const [showModal, setShowModal] = useState(false)

	const handleDelete = async (): Promise<void> => {
		try {
			const res = await deleteProfile()
			if (!res?.error) {
				console.warn('success', res)
				showToast('Delete', `${res?.message}`, 'success')
				setShowModal(false)
				authStore.signOut()
				navigate('/', { replace: true })
			} else {
				console.warn('error', res)
				showToast('Delete', `${res?.error}`, 'error')
				setShowModal(false)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setShowModal(false)
		}
	}

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Settings'
		})
	}, [])

	return (
		<LayoutApp desktop>
			<>
				<HeaderProfile
					title="Settings"
					icon={Icons.arrowBackward}
					to="/profile"
				/>
				<div className="w-full h-[calc(100vh-52px)] px-4 py-6 bg-black-500">
					<div className="flex flex-col items-center gap-6 w-full h-full overflow-y-scroll hide-scroll-bar">
						<Typography
							type="body-medium"
							label={t`Manage your settings. Remember that some of the following actions are not reversible.`}
							className="text-neutral-200"
						/>
						<div className="w-full flex flex-col gap-4 pb-4 border-b border-b-neutral-200/30">
							<Typography
								type="headline-xsmall"
								label={t`Manage settings`}
								className="text-white-200"
							/>
							<div
								className="flex justify-between cursor-pointer"
								aria-hidden
								onClick={() => setShowModal(true)}
							>
								<Typography
									type="headline-xxsmall"
									label={t`Delete account`}
									className="text-neutral-200"
								/>
								<Button
									color="Secondary"
									className="!h-6 !w-6 !p-[unset] !border-none !bg-neutral-500/0"
									iconRight={Icons.personRemove}
									iconRightDivClassName="!w-full !h-full"
									iconRightClassName="text-primary-500"
								/>
							</div>
						</div>
						{/* <div className="relative w-full flex flex-col gap-4">
							<Typography
								type="headline-xsmall"
								label={t`Notification system`}
								className="text-white-200"
							/>
							<div className="relative w-full h-[200px] self-center">
								<div className="absolute inset-0" />
								<img
									src={Images.notifications}
									className="w-full h-full object-fill"
									alt="Mock performance section"
								/>
							</div>
						</div> */}
					</div>
				</div>
				<ModalDelete
					open={showModal}
					setOpen={(val: boolean) => setShowModal(val)}
					onClick={() => handleDelete()}
				>
					<>
						<Typography
							type="title-medium"
							label="Delete Account"
							className="text-white-100"
						/>
						<Typography
							type="body-medium"
							label="Are you sure you want to delete your account?"
							className="text-white-100"
						/>
					</>
				</ModalDelete>
			</>
		</LayoutApp>
	)
}
