import React, { useEffect } from 'react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { v4 as uuidv4 } from 'uuid'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toaster } from 'react-hot-toast'
import CacheBuster from 'react-cache-buster'
import { RootStoreProvider } from 'stores'
import 'App.css'
// RequireUser
import { useContextUser, UserContext } from 'context'
import { messages as enMessages } from 'locales/en/messages.js'
import { messages as esMessages } from 'locales/es/messages.js'
import { AuthProvider, EventProvider } from 'provider'
import { RoutesElements } from 'routes'
import packageJson from '../package.json'

import 'tailwindcss/tailwind.css'

// eslint-disable-next-line
declare const AppleID: any

declare const window: Window & {
	// eslint-disable-next-line
	webkit?: any
}

const App = (): JSX.Element => {
	const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod'
	const userContextValue = useContextUser()
	const queryClient = new QueryClient()
	i18n.load({
		en: enMessages,
		es: esMessages
	})

	useEffect(() => {
		window.localStorage.setItem('language', 'en')
		i18n.activate('en')

		// Initialize auth for Apple id
		if (
			window?.webkit?.messageHandlers?.['iap-purchase-request'] &&
			window?.webkit?.messageHandlers?.['iap-products-request'] &&
			AppleID?.auth
		) {
			AppleID.auth.init({
				clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
				scope: 'name email',
				redirectURI: window.location?.href,
				state: 'Initial User Authentication Request',
				nonce: uuidv4(),
				usePopup: false
			})
		}
	}, [])

	return (
		<CacheBuster
			currentVersion={packageJson.version}
			isEnabled
			isVerboseMode={!isProduction}
			loadingComponent={<div>Reloading...</div>}
			metaFileDirectory="."
			reloadOnDowngrade
		>
			<I18nProvider i18n={i18n}>
				<RootStoreProvider>
					<QueryClientProvider client={queryClient}>
						<AuthProvider>
							<EventProvider>
								<UserContext.Provider value={userContextValue}>
									<RoutesElements />
									<Toaster
										position="top-right"
										toastOptions={{
											duration: 1500
										}}
									/>
								</UserContext.Provider>
							</EventProvider>
						</AuthProvider>
					</QueryClientProvider>
				</RootStoreProvider>
			</I18nProvider>
		</CacheBuster>
	)
}

export default App
