import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'stores'
import { GET_PROFILE, getProfile } from 'api'

export const RequireSubscriber = observer(
	({ children }: { children: JSX.Element }): JSX.Element => {
		const location = useLocation()
		const { authStore, userStore } = useStore()
		const navigate = useNavigate()
		const { token } = authStore
		const { data, isLoading } = useQuery(
			[GET_PROFILE, token],
			() => getProfile(token),
			{
				cacheTime: 60 * 1000, // 1 minute
				refetchOnWindowFocus: true
			}
		)

		useEffect(() => {
			if (!isLoading && data?.user) userStore.setUser(data.user)
		}, [data, isLoading])

		useEffect(() => {
			if (isLoading) return

			if (!userStore.isSubscribed) {
				if (!authStore.token) {
					navigate('/start', {
						replace: true,
						state: { from: location.state?.from }
					})
				} else {
					navigate('/subscribe', {
						replace: true,
						state: { from: location.state?.from }
					})
				}
			}
		}, [authStore.token, userStore.user?.subscriptionStatus, isLoading])
		return children
	}
)
