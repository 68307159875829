import React from 'react'
import { t } from '@lingui/macro'
import { Modal, Typography } from 'components'
import { StoryPost } from 'interfaces'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

interface IScoresModalProps extends React.HTMLAttributes<HTMLDivElement> {
	story: StoryPost
	isModalOpen: boolean
	agree?: string
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	onClose?: () => void
}

export const ScoresExplanationModal: React.FC<IScoresModalProps> = ({
	story,
	isModalOpen,
	agree,
	onClose,
	setIsModalOpen
}): JSX.Element => {
	let topParagraph
	switch (agree) {
		case 'yes':
			topParagraph = `${t`Do you agree that the`} ${
				story.marketDescription
			} ${t`bet in`} ${story.vs} ${t`will have a`} ${Math.round(
				story.probability
			)}% ${t`probability to win?`}`
			break
		case 'no':
			topParagraph = `${t`Do you disagree that the`} ${
				story.marketDescription
			} ${t`bet in`} ${story.vs} ${t`will have a`} ${Math.round(
				story.probability
			)}% ${t`probability to win?`}`
			break
		default:
			topParagraph = `${t`Top markets around the world project the`} ${
				story.marketDescription
			} ${t`bet in`} ${story.vs} ${t`to have a`} ${Math.round(
				story.probability
			)}% ${t`probability to win.`}`
			break
	}
	const oddSign = parseFloat(story.betOdds || '') >= 0 ? '+' : ''
	const bottomParagraph =
		`${t`The best available bet is on`} ${
			story.sportsBook
		} ${t`at`} ${oddSign}${parseFloat(story.betOdds || '')
			?.toFixed(0)
			.toString()} ` +
		`${t`odds. That gives the bet a`} ` +
		`${parseFloat(story.problyScore?.toString() || '')
			?.toFixed(1)
			?.toString()} ${t`PROBLY Score, which means it wins`} ` +
		`${parseFloat(story.problyScore?.toString() || '')
			?.toFixed(1)
			?.toString()}% ` +
		`${t`more money than it loses if played infinitely at those odds.`}`

	const lastUpdated = new Date(
		story.lastUpdated ? story.lastUpdated * 1000 : Date.now()
	)
	const lastUpdatedString = `${dayjs
		.utc(lastUpdated)
		.tz('America/New_York')
		.format('M/DD h:mma')} ET`

	const handleModalClose = (): void => {
		if (onClose) onClose()
	}
	const lastUpdatedText = `${t`Last Updated`}: ${lastUpdatedString}`
	return (
		<Modal
			title=""
			open={isModalOpen}
			setOpen={setIsModalOpen}
			onClose={handleModalClose}
			className="w-[358px]"
		>
			<Typography
				type="body-medium"
				label={topParagraph}
				className="text-white-200 !font-semibold"
			/>
			<br />
			<Typography
				type="body-medium"
				label={bottomParagraph}
				className="text-white-200 !font-semibold"
			/>
			<br />
			{story.lastUpdated && (
				<div
					style={{
						display: 'flex',
						justifyItems: 'center',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center'
					}}
				>
					<Typography
						type="label-medium"
						label={lastUpdatedText}
						className="text-white-200 !font-bold"
					/>
				</div>
			)}
		</Modal>
	)
}

ScoresExplanationModal.defaultProps = {
	agree: 'neutral',
	onClose: undefined
}
