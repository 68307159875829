/* eslint-disable react/require-default-props */
import React, { Fragment, useEffect } from 'react'
import { Button, Icon, Typography } from 'components'
import { Icons, IconsType } from 'interfaces'
import { Dialog, Transition } from '@headlessui/react'
import { t } from '@lingui/macro'
import clsx from 'clsx'

export interface ModalProps {
	title: string
	open?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
	icon?: IconsType
	className?: string
	showButtons?: boolean
	onClose?: () => void
	children?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
	open = false,
	setOpen,
	title,
	icon,
	showButtons = false,
	className,
	onClose,
	children
}): JSX.Element => {
	const handleClickConfirm = (): void => {
		if (setOpen) setOpen(false)
	}

	const handleClose = (): void => {
		handleClickConfirm()
		if (onClose) onClose()
	}

	const handleClickCancel = (): void => {
		if (setOpen) setOpen(true)
	}

	useEffect(() => {
		if (!open && onClose) onClose()
	}, [open])

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="z-30 fixed w-screen h-screen inset-0 overflow-hidden"
				open={open}
				onClose={handleClose}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div
						className="absolute inset-0 z-10 w-screen h-screen filter backdrop-blur-[2px] bg-black-500/80"
						aria-hidden="true"
						onClick={() => handleClickConfirm()}
					/>
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<div
						className={clsx(
							'absolute z-20 top-1/2 left-1/2 transform translate-y-[-50%] translate-x-[-50%]',
							'flex flex-col justify-between overflow-hidden',
							'md:max-w-md px-4 py-7 text-left rounded-2xl bg-neutral-600',
							'align-middle shadow-xl transition-all',
							className
						)}
					>
						<div className="w-full h-full flex flex-col">
							<div
								className={clsx(
									'w-full flex items-center justify-between h-10'
								)}
							>
								{icon && (
									<div className="w-4 h-4 flex justify-center items-center">
										<Icon
											fillPath
											className="max-h-full max-w-full"
											src={icon}
										/>
									</div>
								)}
								<Typography
									type="label-large"
									label={title}
									className="text-neutral-200"
								/>
								<Button
									className="!h-8 !w-8 !p-[unset] border-none"
									color="Primary"
									fill="transparent"
									iconRight={Icons.close}
									iconRightDivClassName="!w-full !h-full"
									iconRightClassName="w-[18px] h-[18px] text-white-100"
									onClick={handleClickConfirm}
								/>
							</div>
							<div className="mt-3 h-full text-center sm:mt-0 sm:ml-4 sm:text-left">
								{children}
							</div>
						</div>
						{showButtons && (
							<div className="flex w-full justify-end gap-4 sm:mt-4">
								<Button
									size="extra-small"
									className="w-[100px]"
									color="Secondary"
									label={t`Cancel`}
									onClick={handleClickCancel}
								/>
								<Button
									size="extra-small"
									className="w-[100px]"
									color="Secondary"
									label={t`Save`}
									onClick={handleClose}
								/>
							</div>
						)}
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}

export default Modal
