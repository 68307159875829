import React, {
	ChangeEvent,
	HTMLInputTypeAttribute,
	useRef,
	useState
} from 'react'
import { Button } from 'components'
import { Icons, InputProps } from 'interfaces'
import clsx from 'clsx'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { t } from '@lingui/macro'
import './input.scss'

export const Input: React.FC<
	InputProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
	name,
	title,
	register,
	rules,
	error,
	rightImg,
	leftImg,
	rightClick,
	leftClick,
	showEye,
	className,
	type,
	rulesOff = false,
	rangeOptions,
	setValue,
	colorStyle = 'Transparent',
	...props
}): JSX.Element => {
	const [isVisible, setIsVisible] = useState(false)
	const [isDelete, setIsDelete] = useState(false)
	const [rangeValue, setRangeValue] = useState(0)
	const fileInput = useRef<HTMLInputElement>(null)
	let rulesInput = rules
	if (type === 'email')
		rulesInput = {
			...rules,
			pattern: {
				value:
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				message: 'Invalid email address'
			}
		}
	if (showEye && !rulesOff) {
		rulesInput = {
			...rules,
			pattern: {
				value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/,
				message: t`Invalid password`
			}
		}
	}
	if (name === 'nit') {
		rulesInput = {
			...rules,
			pattern: {
				value: /^[0-9]{9}-[0-9]$/,
				message: t`Invalid NIT number`
			}
		}
	}
	if (name === 'digit') {
		rulesInput = {
			...rules,
			pattern: {
				value: /^[0-9]*$/,
				message: 'Only numbers'
			}
		}
	}
	if (name === 'phone') {
		rulesInput = {
			...rules,
			pattern: {
				value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
				message: t`Invalid phone number`
			}
		}
	}
	const registerInput = register && register(name, rulesInput)

	const handleShowPass = (): void => {
		setIsVisible(!isVisible)
	}

	const onButtonClick = (): void => {
		// Programmatically trigger a click on the input element
		fileInput.current?.click()
	}

	const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (setValue) {
			setValue(name, URL.createObjectURL(event.target.files?.[0] as File))
		}
	}

	const renderInput = (
		inputType: HTMLInputTypeAttribute | undefined
	): JSX.Element => {
		switch (inputType) {
			case 'range':
				return (
					<div className="relative w-full inputRange">
						<div
							className="absolute top-[-120%] flex items-center justify-center"
							style={{
								left:
									rangeOptions &&
									`calc(${
										((rangeValue - rangeOptions.min) * 100) /
										(rangeOptions.max - rangeOptions.min)
									}% + ${10 - rangeValue * 0.2}px)`
							}}
						>
							<span className="absolute flex justify-center items-center w-[32px] h-[28px] bg-[#1266F1] rounded-[6px] font-Roboto font-medium text-white-100">
								{rangeValue}
							</span>
						</div>
						<input
							{...registerInput}
							{...props}
							id={name}
							type={type}
							{...rangeOptions}
							value={rangeValue}
							className={clsx(
								'block w-full mt-8 border cursor-pointer border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none',
								className
							)}
							style={{
								backgroundSize:
									rangeOptions &&
									`${
										((rangeValue - rangeOptions.min) * 100) /
										(rangeOptions.max - rangeOptions.min)
									}% 100%`
							}}
							onChange={event => {
								setRangeValue(Number(event.target.value))
							}}
						/>
					</div>
				)
			case 'file':
				return (
					<div>
						<input
							{...registerInput}
							{...props}
							type="file"
							className="hidden"
							ref={fileInput}
							onChange={onFileChange}
						/>
						<Button
							type="button"
							className="!h-8 !w-8 !p-[unset] border-2 border-white rounded-[50%]"
							classNameDiv="ml-auto"
							iconRight={Icons.edit}
							iconRightDivClassName="!w-full !h-full"
							iconRightClassName="w-4 h-4 text-white-100"
							onClick={onButtonClick}
						/>
					</div>
				)
			default:
				return (
					<div
						className={clsx(
							'flex items-center rounded-md shadow-sm',
							{
								'bg-transparent border-2 border-neutral-300':
									colorStyle === 'Transparent'
							},
							{ 'bg-neutral-400 border-none': colorStyle === 'Gray' }
						)}
					>
						{leftImg && (
							<Button
								color="Secondary"
								fill="no-fill"
								classNameDiv="pl-2"
								className="!h-5 !w-5 !p-[unset] !border-none"
								iconRight={leftImg}
								iconRightDivClassName="!w-full !h-full"
								iconRightClassName="text-white-200"
								onClick={leftClick}
							/>
						)}
						<input
							{...registerInput}
							{...props}
							id={name}
							type={!showEye ? type : isVisible ? 'text' : 'password'}
							className={clsx(
								'block w-full px-3 py-2 bg-transparent appearance-none focus:outline-none ',
								{ 'pr-8': rightImg || showEye },
								{ 'pl-2': leftImg },
								'font-poppins text-white-200 placeholder-neutral-900 text-[14px] md:text-lg',
								className
							)}
							onKeyDown={
								name === 'digit' || name === 'nit' || name === 'phone'
									? (event: React.KeyboardEvent<HTMLInputElement>) => {
											const code = event.which || event.keyCode
											if (code === 8) {
												setIsDelete(true)
											} else {
												setIsDelete(false)
											}
										}
									: props.onKeyDown
							}
							onChange={
								name === 'nit'
									? (event: React.ChangeEvent<HTMLInputElement>): void => {
											const { value } = event.target
											const aux1 = Number(
												value
													.replace(/\s/g, '')
													.replaceAll('(', '')
													.replaceAll(')', '')
													.replaceAll('-', '')
											)
											let newFormat = false
											if (setValue) {
												if (!isDelete) {
													if (!Number.isNaN(aux1)) {
														let format = ''
														if (aux1.toString().length === 9) {
															newFormat = true
															format = aux1
																.toString()
																.replace(/([0-9]{9})/g, '$1-')
														} else if (aux1.toString().length === 10) {
															newFormat = true
															format = aux1
																.toString()
																.replace(/([0-9]{9})([0-9])/g, '$1-$2')
														}
														setValue(name, newFormat ? format : value)
													} else {
														const aux2 = value.substring(0, value.length - 1)
														setValue(name, aux2)
													}
												}
												setIsDelete(false)
											}
										}
									: name === 'phone'
										? (event: React.ChangeEvent<HTMLInputElement>): void => {
												const { value } = event.target
												const aux1 = Number(
													value
														.replace(/\s/g, '')
														.replaceAll('(', '')
														.replaceAll(')', '')
														.replaceAll('-', '')
												)
												let newFormat = false
												if (setValue) {
													if (!isDelete) {
														if (!Number.isNaN(aux1)) {
															let format = ''
															if (aux1.toString().length === 3) {
																newFormat = true
																format = aux1
																	.toString()
																	.replace(/([0-9]{3})/g, '$1-')
															} else if (aux1.toString().length === 6) {
																newFormat = true
																format = aux1
																	.toString()
																	.replace(/([0-9]{3})([0-9]{3})/g, '$1-$2-')
															} else if (aux1.toString().length === 10) {
																newFormat = true
																format = aux1
																	.toString()
																	.replace(
																		/([0-9]{3})([0-9]{3})([0-9]{4})/g,
																		'$1-$2-$3'
																	)
															}
															setValue(name, newFormat ? format : value)
														} else {
															const aux2 = value.substring(0, value.length - 1)
															setValue(name, aux2)
														}
													}
													setIsDelete(false)
												}
											}
										: props.onChange
							}
						/>
						{showEye && (
							<Button
								color="Secondary"
								className="!h-5 !w-5 !p-[unset] !border-none"
								iconRight={isVisible ? Icons.visibility : Icons.visibilityOff}
								iconRightDivClassName="!w-full !h-full"
								iconRightClassName="text-white-200"
								onClick={handleShowPass}
							/>
						)}
						{rightImg && !showEye && (
							<Button
								color="Secondary"
								className="!h-5 !w-5 !p-[unset] !border-none"
								iconRight={rightImg}
								iconRightDivClassName="!w-full !h-full"
								iconRightClassName="text-white-200"
								onClick={rightClick}
							/>
						)}
					</div>
				)
		}
	}

	return (
		<div className={clsx('flex flex-col w-full gap-2', className)}>
			{title && (
				<label
					htmlFor={name}
					className={clsx(
						'text-[12px] leading-[16px] font-medium font-poppins text-neutral-200 text-left'
					)}
				>
					{title}
				</label>
			)}
			<div className={clsx('relative w-full')}>{renderInput(type)}</div>
			{error && error.message && (
				<div className="flex items-center text-red-500">
					<div className="mr-1 w-4 h-4">
						<ExclamationCircleIcon
							className="w-4 h-4 text-red-500"
							aria-hidden="true"
						/>
					</div>
					<h1 className="text-xs">{error.message}</h1>
				</div>
			)}
		</div>
	)
}
