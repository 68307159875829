import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'
import { registerServiceWorker } from './registerServiceWorker'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const container = document.getElementById('root')
// eslint-disable-next-line
const root = createRoot(container!)
root.render(
	<BrowserRouter basename="/">
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</BrowserRouter>
)

reportWebVitals()
if (!isDesktop) {
	registerServiceWorker()
}
