import { XIcon } from '@heroicons/react/solid'
import { observer } from 'mobx-react'
import { GET_SPORTS, LeagueItem, getSports } from 'api'
import clsx from 'clsx'
import {
	Drawer,
	DrawerSearchLeague,
	DropdownOrganize,
	Icon,
	Loading,
	SportsItem,
	Typography
} from 'components'
import { useStore } from 'stores'
import { Icons } from 'interfaces'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

export interface SportType {
	sports: {
		logoUrl: string
		sportId: string
		sportName: string
		totalBets: number
	}[]
}

export const Header: React.FC = observer((): JSX.Element => {
	const ALL_SPORTS_ID = 'all'
	const { storyFilterFormStore } = useStore()
	const { sportID, setSportID, setLeagueID, storySlided } = storyFilterFormStore
	const [openDrawer, setOpenDrawer] = useState(false)
	const [league, setLeague] = useState('')
	const sportsDivRef = useRef<HTMLDivElement>(null)

	const { data: sportsData, isLoading } = useQuery(
		[GET_SPORTS],
		() => getSports(),
		{
			cacheTime: 5 * 60 * 1000 // 5 minutes
		}
	)

	useEffect(() => {
		if (!sportID) {
			setSportID(ALL_SPORTS_ID)
		}
	}, [sportID])

	const handleClick = (id: string): void => {
		if (storySlided) return
		if (id !== sportID) {
			setLeagueID('')
			setSportID(id)
		}
	}

	const sportsOrdered = (): {
		logoUrl: string
		sportId: string
		sportName: string
		totalBets: number
	}[] => {
		if (!sportsData) return []
		const selectedSport = sportsData.sports.find(
			sport => sport.sportId === sportID
		)
		const unselectedSports = sportsData.sports.filter(
			sport => sport.sportId !== sportID
		)
		if (!selectedSport) return unselectedSports
		return [selectedSport, ...unselectedSports]
	}

	const moveSportsSection = (movement: number): void => {
		const { current } = sportsDivRef
		if (!current) return
		current.scrollLeft += current.clientLeft - movement
	}

	const handleMouseMove = (e: MouseEvent): void => {
		moveSportsSection(e.movementX)
	}

	const handleLeagueChange = (leagueItem: LeagueItem): void => {
		setSportID(leagueItem.sportId)
		setLeague(leagueItem.leagueName)
	}

	const makeDraggableSportSection = React.useCallback(() => {
		const { current } = sportsDivRef
		if (!current) return
		current.addEventListener('mousemove', handleMouseMove)
	}, [])

	const removeDraggableSportSection = React.useCallback(() => {
		const { current } = sportsDivRef
		if (!current) return
		current.removeEventListener('mousemove', handleMouseMove)
	}, [])

	return (
		<div className="w-full h-full flex flex-col justify-center items-center gap-[6px] bg-neutral-500">
			{isLoading ? (
				<div className="w-full h-20 flex gap-6 px-4 overflow-hidden overflow-x-scroll hide-scroll-bar">
					{[1, 2, 3, 4, 5].map(load => (
						<div
							key={load}
							className="w-14 h-20 flex flex-col gap-1 justify-center items-center"
						>
							<Typography
								type="label-medium"
								label="sports"
								className="text-neutral-200"
							/>
							<div
								className={clsx(
									'relative w-14 h-14 flex justify-center items-center',
									'rounded-full border-2 border-primary-500'
								)}
							>
								<Loading size="small" />
							</div>
						</div>
					))}
				</div>
			) : (
				<div
					className="w-full h-20 flex gap-6 px-4 overflow-hidden overflow-x-scroll hide-scroll-bar select-none"
					ref={sportsDivRef}
					role="menubar"
					tabIndex={0}
					onMouseDown={makeDraggableSportSection}
					onMouseUp={removeDraggableSportSection}
					onMouseLeave={removeDraggableSportSection}
				>
					{sportsOrdered()
						.filter(sport => sport.totalBets > 0)
						.map((sport, index) => (
							<SportsItem
								key={`${sport?.sportId}-${index}`}
								{...sport}
								selected={sportID || 'all'}
								handleClick={handleClick}
							/>
						))}
				</div>
			)}
			<div className="relative w-full h-6 px-4 flex justify-between">
				{!openDrawer && <DropdownOrganize />}
				<button
					type="button"
					className={clsx('w-1/2 h-6 flex justify-end', 'opacity-100')}
					onClick={() => {
						if (storySlided) {
							setOpenDrawer(false)
							return
						}
						if (!league) {
							setOpenDrawer(true)
						} else {
							setLeague('')
							setLeagueID('')
						}
					}}
				>
					{!league ? (
						<div className="w-[100px] h-full flex justify-center items-center gap-[6px] rounded bg-neutral-300">
							<Typography
								type="body-medium"
								label="Leagues"
								className="text-neutral-200"
							/>
							<div className="w-4 h-4">
								<Icon
									src={Icons.search}
									fillPath
									className="text-primary-500"
								/>
							</div>
						</div>
					) : (
						<div className="w-44 h-6 px-3 bg-primary-500 rounded flex justify-between items-center gap-[6px]">
							<Typography
								type="label-large"
								label={league}
								className="text-neutral-50 truncate overflow-hidden"
							/>
							<div className="w-4 h-4">
								<XIcon className="w-full h-full text-neutral-100" />
							</div>
						</div>
					)}
				</button>
				{openDrawer && (
					<Drawer
						open={openDrawer}
						setOpen={(val: boolean) => setOpenDrawer(val)}
						mobileFull
					>
						<DrawerSearchLeague
							onClose={(val: boolean) => setOpenDrawer(val)}
							onLeagueChange={handleLeagueChange}
							sportSelected={sportID || 'all'}
						/>
					</Drawer>
				)}
			</div>
		</div>
	)
})
