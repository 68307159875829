import { t } from '@lingui/macro'
import clsx from 'clsx'
import { Button, Icon, Typography } from 'components'
import { useClickOutside, useToast } from 'hooks'
import { Icons } from 'interfaces'
import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import {
	FacebookShareButton,
	TelegramShareButton,
	WhatsappShareButton
} from 'react-share'

const portalShare = document.getElementById('portal-share') as HTMLElement

export interface DrawerShareProps {
	storyId: string
	onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const ButtonShareIcon: React.FC<{ icon: Icons }> = ({ icon }) => (
	<div className="bg-neutral-500 text-neutral-200 text-white-50 w-[58px] h-[58px] p-4 rounded-lg">
		<Icon fillPath src={icon} />
	</div>
)

export const DrawerShareStory: React.FC<DrawerShareProps> = ({
	storyId,
	onClose
}): JSX.Element => {
	const divRef = useRef<HTMLDivElement | null>(null)
	useClickOutside(divRef, () => onClose(false))
	const { showToast } = useToast()
	const siteUrl = `${window.location.protocol}//${window.location.hostname}${
		window.location.port ? `:${window.location.port}` : ''
	}`

	const copyStoryLink = async (): Promise<void> => {
		try {
			await navigator.clipboard.writeText(`${siteUrl}/market/${storyId}`)
			showToast(t`Link copied!`, t`Story link copied to clipboard`, 'success')
			onClose(false)
		} catch (error) {
			showToast(t`Error`, t`Error copying link`, 'error')
		}
	}

	return createPortal(
		<>
			<div
				className={clsx(
					'fixed w-screen h-screen inset-0 backdrop-blur-[1px] flex justify-center items-end bg-neutral-600/50'
				)}
			/>
			<div
				ref={divRef}
				className="fixed bottom-0 w-full sm:w-[390px] flex flex-col justify-between items-center gap-[16px] bg-black-500 rounded-t-2xl py-4 left-[50%]"
				style={{ transform: 'translate(-50%, 0)' }}
			>
				<Typography
					type="headline-xsmall"
					label="Share"
					className="text-neutral-50"
				/>
				<div className="w-full flex justify-evenly items-center">
					<TelegramShareButton
						url={`${siteUrl}/market/${storyId}`}
						title={`Check this probly and you'll be a winner`}
					>
						<ButtonShareIcon icon={Icons.telegramBlack} />
					</TelegramShareButton>
					<FacebookShareButton
						url={`${siteUrl}/market/${storyId}`}
						title={`Check this probly and you'll be a winner`}
					>
						<ButtonShareIcon icon={Icons.facebookBlack} />
					</FacebookShareButton>
					<WhatsappShareButton
						url={`${siteUrl}/market/${storyId}`}
						title={`Check this probly and you'll be a winner`}
					>
						<div className="bg-neutral-500 text-neutral-200 text-white-50 w-[58px] h-[58px] p-4 rounded-lg">
							<Icon fillPath src={Icons.whatsappBlack} />
						</div>
					</WhatsappShareButton>
					<button type="button" aria-label="share" onClick={copyStoryLink}>
						<ButtonShareIcon icon={Icons.link} />
					</button>
				</div>
				<Button
					label="Cancel"
					size="extra-small"
					className="w-[100px]"
					color="Secondary"
					onClick={() => onClose(false)}
				/>
			</div>
		</>,
		portalShare
	)
}
