import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { HistoryBoxProps, Icons } from 'interfaces'
import React from 'react'

export const HistoryBox: React.FC<HistoryBoxProps> = ({
	result,
	description
}) => {
	return (
		<div className="flex justify-between items-center min-h-[68px] px-4 py-2 bg-neutral-1000 rounded-[16px]">
			<div className="min-h-[68px] basis-[68%] flex flex-col justify-center gap-1">
				<Typography
					type="label-medium"
					label={description}
					className="text-white-100 line-clamp-2"
				/>
				{/* <div className="flex items-center gap-2">
					<div className="w-3 h-3 flex justify-center items-center">
						<Icon src={Icons.schedule} fillPath className="text-primary-500" />
					</div>
					<div className="flex items-center gap-1">
						<Typography
							type="label-medium"
							label="Your prediction:"
							className="inline text-neutral-100"
						/>
						{result ? (
							<Typography
								type="label-medium"
								label="Success"
								className="inline text-primary-500"
							/>
						) : (
							<Typography
								type="label-medium"
								label="Not Success"
								className="inline text-danger-500"
							/>
						)}
					</div>
				</div> */}
			</div>
			<div
				className={clsx(
					result === 'Yes' ? 'bg-primary-500' : 'bg-danger-500',
					'flex items-center gap-1 w-[96px] h-8 px-4 py-2 rounded-[8px]'
				)}
			>
				<div className="w-5 h-5">
					<Icon
						src={result === 'Yes' ? Icons.thumbUp : Icons.thumbDown}
						fillPath
						className="text-white-100"
					/>
				</div>
				<Typography
					type="label-medium"
					label="Result"
					className="text-white-100"
				/>
			</div>
		</div>
	)
}
