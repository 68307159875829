import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react'
import {
	getUploadImage,
	updateImage,
	updateProfile,
	updateProfileAvatar
} from 'api'
import clsx from 'clsx'
import { Button, CodePhone, Icon, Loading, Typography } from 'components'
import { useToast } from 'hooks'
import { Icons, ProfileInputs } from 'interfaces'
import { useStore } from 'stores'

export interface FormProfileProps {
	userProfile: {
		userId: string
		userName: string
		email: string
		phone: string
		avatarUrl: string
		lastOptCode: string
	}
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	onSave: () => void
}

export const FormProfile: React.FC<FormProfileProps> = observer(
	({ userProfile, setModalOpen, onSave }): JSX.Element => {
		const { showToast } = useToast()
		const { authStore } = useStore()
		const [isLoading, setIsLoading] = useState(false)
		const [selectImage, setSelectImage] = useState<string | undefined>()
		const [codePhone, setCodePhone] = useState('+1')
		const finalToken = authStore.token

		const {
			register,
			handleSubmit,
			formState: { errors, isValid }
		} = useForm<ProfileInputs>({
			mode: 'all',
			defaultValues: {
				email: userProfile.email,
				phone: userProfile.phone,
				userName: userProfile.userName,
				avatarUrl: userProfile.avatarUrl
			}
		})

		const handleMediaUpload = (file: File): void => {
			const reader = new FileReader()
			reader.onload = e => {
				const uploadedObj = { file, url: e.target?.result as string }
				setSelectImage(uploadedObj.url)
			}
			reader.readAsDataURL(file) // convert to base64 string
		}

		const updatedAvatar = async (url: string): Promise<void> => {
			const body = {
				avatarUrl: url
			}
			try {
				await updateProfileAvatar(body)
			} catch (error) {
				console.error(error)
			}
		}

		const updatePhoto = async (image: string): Promise<void> => {
			const credentials = await getUploadImage('png', finalToken)
			if (!credentials) return
			const getImage = await fetch(image)
			const imageBlob = await getImage.blob()
			await updateImage(credentials.url, {
				...credentials.fields,
				file: imageBlob
			})
			await updatedAvatar(`${credentials.url}${credentials.fields.key}`)
		}

		const onSubmitProfile: SubmitHandler<ProfileInputs> = async datax => {
			setIsLoading(true)
			const body = {
				email: datax.email,
				phone: parseInt(datax.phone.replaceAll('-', ''), 10),
				userName: datax.userName,
				code: codePhone
			}
			if (selectImage) await updatePhoto(selectImage)
			try {
				const res = await updateProfile(body)
				if (!res?.error) {
					showToast(
						'Update profile',
						`user was updated successfully`,
						'success'
					)
					onSave()
					setModalOpen(false)
					setIsLoading(false)
				} else {
					showToast('Update profile', `${res.error}`, 'error')
					setModalOpen(true)
				}
			} catch (error) {
				showToast(t`Error`, t`Error updating profile`, 'error')
				console.error(error)
			} finally {
				setIsLoading(false)
			}
		}

		return (
			<form
				onSubmit={handleSubmit(onSubmitProfile)}
				className="flex flex-col justify-between h-full"
				encType="multipart/form-data"
			>
				<div className="flex flex-col gap-4">
					<div className="relative">
						<div className="w-[75px] h-[75px] flex justify-center items-center mx-auto rounded-[50%] border-white border-2 overflow-hidden">
							{selectImage || userProfile.avatarUrl ? (
								<img
									src={
										selectImage ||
										`${userProfile.avatarUrl}?${Date.now()}` ||
										'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png'
									}
									alt="profile pic"
									className="w-full h-full"
								/>
							) : (
								<span className="text-[48px] leading-10 font-bold text-primary-500">
									{userProfile.userName.split('')[0]?.toUpperCase()}
								</span>
							)}
						</div>
						<input
							id="avatarUrl"
							type="file"
							className="hidden"
							{...register('avatarUrl')}
							onChange={event => {
								if (event.target.files) {
									handleMediaUpload(event.target.files[0])
								}
							}}
							accept="image/png, image/jpeg, image/gif"
						/>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label
							htmlFor="avatarUrl"
							className="cursor-pointer absolute z-10 bottom-0 right-[120px] w-8 h-8 bg-primary-500 rounded-full flex justify-center items-center border border-white-100"
						>
							<Icon
								src={Icons.edit}
								fillPath
								className="w-5 h-5 text-white-100"
							/>
						</label>
					</div>
					<div className="w-full flex flex-col justify-start gap-2">
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="username" className="w-full flex justify-start">
							<Typography
								type="label-large"
								label="Username"
								className="text-neutral-200"
							/>
						</label>
						<div
							className={clsx(
								'w-full h-[39px] flex items-center gap-2 px-[10px] py-2',
								'bg-transparent border border-neutral-300 rounded-md'
							)}
						>
							<div className="w-6 h-6">
								<Icon
									src={Icons.person}
									fillPath
									className="text-neutral-100"
								/>
							</div>
							<input
								type="text"
								placeholder="username"
								autoComplete="off"
								className={clsx(
									'w-full h-full bg-transparent appearance-none focus:outline-none text-neutral-50 text-[14px] leading-4 font-medium font-Poppins',
									'placeholder:text-neutral-50/40 placeholder:text-[14px]'
								)}
								{...register('userName', {
									required: 'This field is required',
									minLength: {
										value: 2,
										message:
											'Invalid email address. Must be min length 2 characters'
									},
									maxLength: {
										value: 20,
										message:
											'Invalid email address. Must be max length 20 characters'
									}
								})}
							/>
						</div>
						{errors && errors.userName && (
							<div className="w-full flex gap-1">
								<div className="w-4 h-4">
									<ExclamationCircleIcon className="w-4 h-4 text-danger-500" />
								</div>
								<Typography
									type="body-small"
									label={`${errors.userName.message}`}
									className="text-danger-500 text-left"
								/>
							</div>
						)}
					</div>
					<div className="w-full flex flex-col justify-start gap-2">
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="phone" className="w-full flex justify-start">
							<Typography
								type="label-large"
								label="Phone number"
								className="text-neutral-200"
							/>
						</label>
						<div
							className={clsx(
								'w-full h-[39px] flex items-center px-[10px] py-2',
								'bg-transparent border border-neutral-300 rounded-md'
							)}
						>
							<CodePhone setCodePhone={setCodePhone} />
							<input
								type="number"
								placeholder="Phone number"
								autoComplete="off"
								className={clsx(
									'w-full h-full bg-transparent appearance-none focus:outline-none text-neutral-50 text-[14px] leading-4 font-medium font-Poppins',
									'placeholder:text-neutral-50/40 placeholder:text-[14px]'
								)}
								{...register('phone', {
									required: 'This field is required',
									pattern: {
										value: /^([1-9](\d)*)$/g,
										message: t`Invalid phone. Must start with a number between 1 and 9`
									},
									minLength: {
										value: 10,
										message: 'Invalid phone. Must be min length 10 characters'
									},
									maxLength: {
										value: 10,
										message: 'Invalid phone. Must be max length 10 characters'
									}
								})}
							/>
						</div>
						{errors && errors.phone && (
							<div className="w-full flex gap-1">
								<div className="w-4 h-4">
									<ExclamationCircleIcon className="w-4 h-4 text-danger-500" />
								</div>
								<Typography
									type="body-small"
									label={`${errors.phone.message}`}
									className="text-danger-500 text-left"
								/>
							</div>
						)}
					</div>
					<div className="w-full flex flex-col justify-start gap-2">
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="email" className="w-full flex justify-start">
							<Typography
								type="label-large"
								label="Email"
								className="text-neutral-200"
							/>
						</label>
						<div
							className={clsx(
								'w-full h-[39px] flex items-center gap-2 px-[10px] py-2',
								'bg-transparent border border-neutral-300 rounded-md'
							)}
						>
							<div className="w-6 h-6">
								<Icon src={Icons.mail} fillPath className="text-neutral-100" />
							</div>
							<input
								type="email"
								placeholder="example@email.com"
								autoComplete="off"
								className={clsx(
									'w-full h-full bg-transparent appearance-none focus:outline-none text-neutral-50 text-[14px] leading-4 font-medium font-Poppins',
									'placeholder:text-neutral-50/40 placeholder:text-[14px]'
								)}
								{...register('email', {
									required: 'This field is required',

									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: 'Invalid email address.'
									}
								})}
							/>
						</div>
						{errors && errors.email && (
							<div className="w-full flex gap-1">
								<div className="w-4 h-4">
									<ExclamationCircleIcon className="w-4 h-4 text-danger-500" />
								</div>
								<Typography
									type="body-small"
									label={`${errors.email.message}`}
									className="text-danger-500 text-left"
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex w-full justify-end gap-4 sm:mt-4">
					<Button
						label={t`Cancel`}
						size="extra-small"
						className="w-[100px]"
						color="Secondary"
						type="button"
						onClick={() => setModalOpen(false)}
					/>
					<Button
						type="submit"
						label={t`Save`}
						size="extra-small"
						className={clsx('w-[100px] active:bg-primary-500')}
						buttonLabelClassName="font-medium"
						color="Secondary"
						disabled={!isValid}
					/>
				</div>
				{isLoading && (
					<div className="absolute inset-0 z-10 w-full flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				)}
			</form>
		)
	}
)
