import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { observer } from 'mobx-react'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper'
import { Images } from 'interfaces'
import { Button } from 'components'
import { useStore } from 'stores'
import styles from './landingPage.module.scss'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export const LandingPage: React.FC = observer((): JSX.Element => {
	const navigate = useNavigate()
	const { userStore, authStore } = useStore()
	const location = useLocation()
	const [activeIndex, setActiveIndex] = useState<number>(0)

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + (window.location.search || ''),
			title: 'Start'
		})

		if (authStore.token) {
			if (userStore.isSubscribed) {
				navigate('/home', { replace: true })
			} else {
				navigate('/subscribe', { replace: true })
			}
		}
	}, [])

	return (
		<div className="w-screen min-h-screen max-h-screen flex flex-col justify-start items-center overflow-hidden sm:bg-black-400 bg-neutral-500">
			<div className="w-full sm:w-[390px] h-[100vh]">
				<Swiper
					spaceBetween={30}
					centeredSlides
					autoplay={{
						delay: 6000,
						disableOnInteraction: false
					}}
					pagination={{
						clickable: true
					}}
					navigation
					modules={[Autoplay, Pagination, Navigation]}
					className={clsx(
						'w-full h-[100vh] items-center justify-center',
						styles.landingPageSwiperContainer
					)}
					onSlideChange={swp => setActiveIndex(swp?.realIndex || 0)}
				>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel1 || ''}
							alt="Landing Page Carousel Slide 1"
							className="w-full h-full"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel2 || ''}
							alt="Landing Page Carousel Slide 2"
							className="w-full h-full"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel3 || ''}
							alt="Landing Page Carousel Slide 3"
							className="w-full h-full"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel4 || ''}
							alt="Landing Page Carousel Slide 4"
							className="w-full h-full"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel5 || ''}
							alt="Landing Page Carousel Slide 5"
							className="w-full h-full"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel6 || ''}
							alt="Landing Page Carousel Slide 6"
							className="w-full h-full"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={Images?.landingPageCarousel7 || ''}
							alt="Landing Page Carousel Slide 7"
							className="w-full h-full"
						/>
					</SwiperSlide>
				</Swiper>
				<div className="z-[105] flex flex-col gap-3 absolute bottom-0 w-full sm:w-[390px] max:h-[20vh] pb-[1rem] pt-[1rem] px-[1rem] bg-neutral-500">
					<div className="w-full flex gap-2 items-center justify-center mb-[0.5rem]">
						{/* Note: Array.from(Array(n).keys()) outputs [0, 1, 2, ...n] */}
						{/* This is used to create the dots stepper for the carousel */}
						{Array.from(Array(7).keys()).map(index => (
							<div
								key={`stepper-dot-${index.toString()}`}
								className={clsx(
									'w-[6px] h-[6px] rounded-full transition',
									activeIndex === index ? 'bg-primary-500' : 'bg-neutral-200'
								)}
							/>
						))}
					</div>
					<div className="flex gap-8 text-center items-center justify-center mb-[0.5rem]">
						<Link to="/register" state={{ from: location }} tabIndex={0}>
							<div className="w-full">
								<Button
									label={t`Register`}
									classNameDiv="!w-[8.5rem]"
									buttonLabelClassName="!text-[16px] !font-medium"
								/>
							</div>
						</Link>
						<Link to="/signin" state={{ from: location }} tabIndex={0}>
							<div className="w-full">
								<Button
									label={t`Log In`}
									classNameDiv="!w-[8.5rem]"
									buttonLabelClassName="!text-[16px] !font-medium"
								/>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
})
