import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { optVerification } from 'api'
import clsx from 'clsx'
import {
	Button,
	FooterRegisterLogin,
	LayoutApp,
	Loading,
	ModalSuccess,
	RegisterLogin,
	Typography
} from 'components'
import { useToast } from 'hooks'
import { Icons } from 'interfaces'
import { useStore } from 'stores'

export const Register: React.FC = (): JSX.Element => {
	const store = useStore()
	const navigate = useNavigate()
	const location = useLocation()
	const { showToast } = useToast()

	const [showModal, setShowModal] = useState(false)
	const [dataRegister, setDataRegister] = useState<{
		email: string | null
		phone: string | null
		digitValidation: string
		expTime: number
	}>({
		email: '',
		phone: '',
		digitValidation: '',
		expTime: 0
	})
	const [isLoading, setIsLoading] = useState(false)

	const handleClick = async (): Promise<void> => {
		setIsLoading(true)
		try {
			const res = await optVerification(dataRegister)
			if (res?.message) {
				setShowModal(false)
				showToast('Error', `Error: ${res.message}`, 'error')
			} else {
				store.authStore.signIn(res?.accessToken)
				// setShowModal(true)
				if (res) store.userStore.setUserWithOtpVerificationResponse(res)
				showToast(t`Welcome!`, t`Registration Completed`, 'success')
				navigate('/subscribe')
			}
		} catch (error) {
			setShowModal(false)
			showToast('Error', `Error: ${error}`, 'error')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Register'
		})
	}, [])

	return (
		<LayoutApp desktop>
			<div
				className={clsx(
					'relative w-full h-full flex flex-col justify-center items-center bg-black-500'
				)}
			>
				<div className="flex items-center justify-between w-full h-[52px] px-4 bg-neutral-500">
					<Button
						color="Secondary"
						className="!h-6 !w-6 !p-[unset] !border-none"
						iconRight={Icons.arrowBackward}
						iconRightDivClassName="!w-full !h-full"
						iconRightClassName="text-neutral-200"
						onClick={() => {
							navigate(location?.state?.from?.pathname || '/')
						}}
					/>
					<Typography
						type="headline-xsmall"
						label={t`Sign Up`}
						className="text-white-200"
					/>
					<div className="w-6 h-6" />
				</div>
				<div className="w-full h-[calc(100dvh-244px)] px-4 py-6">
					<RegisterLogin setDataRegister={setDataRegister} />
				</div>
				<FooterRegisterLogin
					type={location.pathname}
					onClick={handleClick}
					digitValidation={dataRegister.digitValidation}
				/>

				{showModal && (
					<div className="absolute w-full h-screen flex justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<ModalSuccess />
					</div>
				)}

				{isLoading && (
					<div className="absolute inset-0 h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				)}
			</div>
		</LayoutApp>
	)
}
