import { getStory, GET_STORY, StoryV2 } from 'api'
import clsx from 'clsx'
import { LayoutApp, PosterBox } from 'components'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import styles from './styles.module.scss'

export const PostStory = (): JSX.Element => {
	const { storyId = '' } = useParams()
	const [postStory, setPostStory] = useState<StoryV2 | null>(null)
	const { data, isLoading } = useQuery(
		[GET_STORY, storyId],
		() => getStory(storyId),
		{
			cacheTime: 60 * 1000 * 5 // 5 minutes
		}
	)

	useEffect(() => {
		if (!isLoading && data?.storyId) setPostStory(data)
	}, [data, isLoading])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: (window.location.pathname || '') + (window.location.search || ''),
			title: 'Story'
		})
	}, [])

	return (
		<LayoutApp footer headerSpacing isLoading={isLoading}>
			<div
				className={clsx(
					styles.containerHome,
					'relative h-[calc(100vh-69px)] overflow-hidden overflow-y-scroll hide-scroll-bar'
				)}
			>
				{!isLoading && postStory?.storyId && (
					<PosterBox
						post={{
							...(postStory || {}),
							vs: `${postStory?.homeTeam || ''} vs ${postStory?.awayTeam || ''}`,
							startTime: moment(
								postStory?.startTime || new Date().toISOString()
							)?.toDate()
						}}
					/>
				)}
			</div>
		</LayoutApp>
	)
}
