import { createContext } from 'react'

const EventContextDefaultValue = {
	sportID: '',
	setSportID: () => null,
	leagueID: '',
	setLeagueID: () => null,
	orderKey: '',
	setOrderKey: () => null,
	storySlided: null,
	setStorySlided: () => null,
	orderLocked: false,
	setOrderLocked: () => null
}

export const EventContext = createContext<{
	sportID: string
	setSportID: React.Dispatch<React.SetStateAction<string>>
	leagueID: string
	setLeagueID: React.Dispatch<React.SetStateAction<string>>
	orderKey: string
	setOrderKey: React.Dispatch<React.SetStateAction<string>>
	storySlided: string | null
	setStorySlided: React.Dispatch<React.SetStateAction<string | null>>
	orderLocked: boolean
	setOrderLocked: React.Dispatch<React.SetStateAction<boolean>>
}>(EventContextDefaultValue)
