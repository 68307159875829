import React, { useEffect } from 'react'
import { Typography } from 'components'
import { useCountdown } from 'hooks'
import { t } from '@lingui/macro'

export interface CountdownSendCodeProps {
	deadline: Date
	setSent: React.Dispatch<React.SetStateAction<boolean>>
	setDisabledBtn: React.Dispatch<React.SetStateAction<boolean>>
}

export const CountdownSendCode: React.FC<CountdownSendCodeProps> = ({
	deadline,
	setSent,
	setDisabledBtn
}): JSX.Element => {
	const { seconds } = useCountdown(deadline)

	useEffect(() => {
		if (seconds >= 0 && seconds < 30) {
			setDisabledBtn(true)
			setSent(true)
		} else {
			setSent(false)
			setDisabledBtn(false)
		}
	}, [seconds])

	return (
		<div className="w-auto flex justify-center items-center gap-1">
			<Typography
				type="label-large"
				label={t`Done! Send again in: `}
				className="text-neutral-200"
			/>
			<Typography
				type="label-large"
				label={`00:${seconds < 10 ? `0${seconds}` : seconds}`}
				className="text-neutral-200"
			/>
		</div>
	)
}
