/* eslint-disable max-len */
import { t } from '@lingui/macro'
import clsx from 'clsx'
import ReactHTMLParser from 'react-html-parser'
import ReactGA from 'react-ga4'
import { getFAQs, GET_FAQS } from 'api'
import {
	Button,
	Dropdown,
	HeaderProfile,
	LayoutApp,
	Loading,
	Typography
} from 'components'
import { Icons } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import Cookies from 'universal-cookie'
import styles from './faq.module.scss'

export interface FaqsType {
	faqsId: string
	question: string
	answer: string
}

export const FAQ: React.FC = () => {
	const [faqs, setFaqs] = useState<FaqsType[]>([])
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { data, isLoading } = useInfiniteQuery(
		[GET_FAQS],
		({ pageParam = 1 }) => getFAQs({ page: pageParam, token }),
		{
			cacheTime: 60 * 60 * 1000, // 1 hour
			getNextPageParam: lastPage => (lastPage?.next ? lastPage.next : undefined)
		}
	)

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as FaqsType[]
			data.pages.forEach(item => {
				const auxRes = item?.data
				if (auxRes) auxData = [...auxData, ...auxRes]
			})
			setFaqs(auxData)
		}
	}, [data])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'FAQs'
		})
	}, [])

	const handleSupportEmailClick = (): void => {
		window.location.href = 'mailto:support@probly.com'
	}

	return (
		<LayoutApp desktop>
			<>
				<HeaderProfile
					title={t`Support and faqs`}
					icon={Icons.arrowBackward}
					to="/profile"
				/>
				<div className="w-full h-[calc(100dvh-54px)] flex flex-col items-center gap-6 px-4 py-6 bg-black-500 overflow-hidden overflow-y-scroll hide-scroll-bar">
					<div className="flex flex-col gap-4 w-full">
						<Typography
							type="headline-xsmall"
							label={t`Support`}
							className="text-white-200"
						/>
						<div className="flex justify-between">
							<Typography
								type="headline-xxsmall"
								label={t`Contact us at support@probly.com`}
								className="text-neutral-200"
							/>
							<Button
								color="Secondary"
								className="!h-6 !w-6 !p-[unset] !border-none !bg-neutral-500/0"
								iconRight={Icons.outgoingMail}
								iconRightDivClassName="!w-full !h-full"
								iconRightClassName="text-primary-500"
								onClick={handleSupportEmailClick}
							/>
						</div>
					</div>
					<div className="w-full border-[1px] border-neutral-200 opacity-[38%]" />
					<div className="flex flex-col gap-4 w-full">
						<Typography
							type="headline-xsmall"
							label={t`FAQ's`}
							className="text-white-200"
						/>
						<div className="flex flex-col">
							{faqs?.map((item, index) => {
								return (
									<Dropdown
										key={index}
										label={t`${item?.question}`}
										border
										hideView
										profile
									>
										<div className="pt-2 pb-4">
											<div
												className={clsx(
													styles.answerText,
													'w-full text-neutral-200 text-[14px] leading-5 font-Poppins'
												)}
											>
												{ReactHTMLParser(item?.answer) as React.ReactNode}
											</div>
										</div>
									</Dropdown>
								)
							})}
						</div>
					</div>
				</div>
				{isLoading && (
					<div className="absolute inset-0 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				)}
			</>
		</LayoutApp>
	)
}
