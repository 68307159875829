import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react'
import ReactGA from 'react-ga4'
import { getProfile, GET_PROFILE } from 'api'
import {
	Button,
	FormProfile,
	HeaderProfile,
	LayoutApp,
	Modal,
	// TrackingEventsProfileBox,
	Typography
} from 'components'
import { useAuth } from 'hooks'
import { Icons } from 'interfaces'
import { useStore } from 'stores'

export const Profile: React.FC = observer((): JSX.Element => {
	const store = useStore()
	const navigate = useNavigate()
	const location = useLocation()
	const { signOut } = useAuth()
	const [modalOpen, setModalOpen] = useState(false)
	const finalToken = store.authStore.token
	// const trackingEventsTypes = [t`Bets`]

	const { data, isLoading, refetch } = useQuery([GET_PROFILE], () =>
		getProfile(finalToken)
	)

	const userName = data?.user?.userName || data?.user?.email || 'N/A'

	const handleSubscribe = (): void => {
		navigate('/subscribe', { state: { from: location } })
	}

	const handleCancelSubscription = (): void => {
		navigate('/subscription/cancel', { state: { from: location } })
	}

	useEffect(() => {
		if (data?.user) store.userStore.setUser(data.user)
	}, [data])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Profile'
		})
	}, [])
	return (
		<LayoutApp footer desktop isLoading={isLoading}>
			<div className="w-full h-full bg-black-500">
				<HeaderProfile
					title="My profile"
					icon={Icons.settings}
					to="/settings"
				/>
				<div className="w-full min-h-[calc(100dvh-120px)] px-4 py-6 bg-black-500 overflow-hidden overflow-y-scroll hide-scroll-bar">
					<div className="w-full h-full flex flex-col items-center overflow-hidden overflow-y-scroll hide-scroll-bar">
						<div className="flex gap-3 items-center w-full">
							<div className="w-[54px] h-[54px] flex justify-center items-center border-2 border-white rounded-full">
								{data?.user?.avatarUrl ? (
									<img
										src={
											`${data?.user?.avatarUrl}?${Date.now()}` ||
											'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png'
										}
										alt="profile pic"
										className="w-full h-full rounded-[50%] border-white border-2"
									/>
								) : (
									<span className="text-[32px] leading-10 font-bold text-primary-500">
										{userName?.split('')[0]?.toUpperCase()}
									</span>
								)}
							</div>
							<div className="flex flex-col">
								<Typography
									type="title-medium"
									label={`${t`Hello`}, ${userName}`}
									className="text-white-200"
								/>
								<Typography
									type="headline-xxsmall"
									label={`@${userName}`}
									className="text-neutral-700"
								/>
							</div>
							<Button
								className="!h-8 !w-8 !p-[unset] border-2 border-white rounded-[50%]"
								classNameDiv="ml-auto"
								iconRight={Icons.edit}
								iconRightDivClassName="!w-full !h-full"
								iconRightClassName="w-4 h-4 text-white-100"
								onClick={() => setModalOpen(true)}
							/>
							<Modal
								title={t`Profile Photo`}
								open={modalOpen}
								setOpen={setModalOpen}
								className="w-[358px] h-[508px]"
							>
								{data && data.user && (
									<FormProfile
										userProfile={data.user}
										setModalOpen={setModalOpen}
										onSave={refetch}
									/>
								)}
							</Modal>
						</div>
						{/* <div className="w-full border-[1px] mt-6 border-neutral-200/30" /> */}
						{/* <div
							className="flex flex-col gap-4 w-full"
							style={{ paddingTop: '1rem' }}
						>
							<div className="flex justify-between items-center">
								<Typography
									type="title-medium"
									label={t`Saved Bets`}
									className="text-white-200"
								/>
								<Button
									label={t`View`}
									color="Primary"
									fill="transparent"
									size="extra-small"
									className="group hover:bg-primary-500"
									buttonLabelClassName="text-primary-500 group-hover:text-white-100"
									iconRight={Icons.arrowForwardIos}
									iconRightDivClassName="!w-6 !h-6"
									iconRightClassName="text-primary-500 group-hover:text-white-100"
									onClick={() => {
										navigate('/tracking-events', { state: { from: location }})
									}}
								/>
							</div>
							<div className="flex justify-center gap-10">
								{data &&
									Object.values(data.pollResponses).map((value, index) => {
										return (
											<TrackingEventsProfileBox
												key={index}
												type={trackingEventsTypes[index] || ''}
												amount={value}
											/>
										)
									})}
							</div>
						</div> */}
						{/* <div className="w-full border-[1px] my-6 border-neutral-200/30" /> */}
						{/* <div className="relative flex flex-col gap-4 w-full">
							<Typography
								type="title-medium"
								label={t`Performance`}
								className="text-white-200"
							/>
							<div className="realtive w-[356px] h-[124px] self-center">
								<div className="absolute inset-0" />
								<img
									src={Images.performanceProfile}
									className="w-full h-full object-fill"
									alt="Mock performance section"
								/>
							</div>
						</div> */}
						<div className="w-full border-[1px] my-6 border-neutral-200/30" />
						<div className="flex flex-col gap-6 w-full">
							<Typography
								type="title-medium"
								label={t`Information`}
								className="text-white-200"
							/>
							<div className="flex flex-col gap-3">
								<div
									className="flex justify-between border-b border-b-neutral-200/30 pb-4 cursor-pointer"
									aria-hidden="true"
									onClick={() => {
										navigate('/tracking-events', { state: { from: location } })
									}}
								>
									<Typography
										type="title-small"
										label={t`Saved Bets`}
										className="text-neutral-200"
									/>
									<Button
										color="Secondary"
										fill="no-fill"
										className="!h-6 !w-6 !p-[unset]"
										iconRight={Icons.arrowForwardIos}
										iconRightDivClassName="!w-full !h-full"
										iconRightClassName="text-neutral-200"
									/>
								</div>
								<div
									className="flex justify-between border-b border-b-neutral-200/30 pb-4 cursor-pointer"
									aria-hidden="true"
									onClick={
										store.userStore.user?.subscriptionStatus === 'active'
											? handleCancelSubscription
											: handleSubscribe
									}
								>
									<Typography
										type="title-small"
										label={t`${
											store.userStore.user?.subscriptionStatus === 'active'
												? 'Cancel Subscription'
												: 'Subscribe'
										}`}
										className="text-neutral-200"
									/>
									<Button
										color="Secondary"
										fill="no-fill"
										className="!h-6 !w-6 !p-[unset]"
										iconRight={Icons.arrowForwardIos}
										iconRightDivClassName="!w-full !h-full"
										iconRightClassName="text-neutral-200"
									/>
								</div>
								<div
									className="flex justify-between border-b border-b-neutral-200/30 pb-4  cursor-pointer"
									aria-hidden="true"
									onClick={() => {
										navigate('/faq', { state: { from: location } })
									}}
								>
									<Typography
										type="title-small"
										label={t`Support / FAQ`}
										className="text-neutral-200"
									/>
									<Button
										color="Secondary"
										fill="no-fill"
										className="!h-6 !w-6 !p-[unset]"
										iconRight={Icons.arrowForwardIos}
										iconRightDivClassName="!w-full !h-full"
										iconRightClassName="text-neutral-200"
									/>
								</div>
								<div
									className="flex justify-between border-b border-b-neutral-200/30 pb-4 cursor-pointer"
									aria-hidden="true"
									onClick={() => {
										navigate('/terms', {
											state: { from: location }
										})
									}}
								>
									<Typography
										type="title-small"
										label={t`Terms and conditions`}
										className="text-neutral-200"
									/>
									<Button
										color="Secondary"
										fill="no-fill"
										className="!h-6 !w-6 !p-[unset]"
										iconRight={Icons.arrowForwardIos}
										iconRightDivClassName="!w-full !h-full"
										iconRightClassName="text-neutral-200"
									/>
								</div>
								<div
									className="flex justify-between cursor-pointer"
									aria-hidden="true"
									onClick={signOut}
								>
									<Typography
										type="title-small"
										label={t`Log out`}
										className="text-neutral-200"
									/>
									<Button
										color="Secondary"
										fill="no-fill"
										className="!h-6 !w-6 !p-[unset]"
										iconRight={Icons.logOut}
										iconRightDivClassName="!w-full !h-full"
										iconRightClassName="text-neutral-200"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LayoutApp>
	)
})
