import React from 'react'
import { observer } from 'mobx-react'
import { t } from '@lingui/macro'
import { useStore } from 'stores'
import { Icon, Typography } from 'components'
import { Icons } from 'interfaces'

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'stripe-pricing-table': React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLElement>,
				HTMLElement
			>
		}
	}
}

export const StripePricingTable: React.FC = observer((): JSX.Element => {
	const { userStore } = useStore()
	const { userId, email } = userStore.user
	const stripePublishableKey = process.env.REACT_APP_STRIPE_PUB_KEY || ''
	const priceTableId = process.env.REACT_APP_STRIPE_PRICE_TABLE_ID || ''
	return (
		<div>
			<div className="w-full mt-[0.25rem] mb-[1.5rem]">
				<Typography
					type="headline-small"
					label={t`Choose Your Plan`}
					className="text-white-100 text-center"
				/>
			</div>
			<stripe-pricing-table
				pricing-table-id={priceTableId}
				publishable-key={stripePublishableKey}
				client-reference-id={userId}
				customer-email={email}
			/>
			<div className="border-b border-b-neutral-200/30 my-[2rem] mx-[1.5rem]" />
			<table
				className="table-fixed mx-[2rem] my-[1.5rem] border-seperate border-spacing-y-24"
				role="table"
			>
				<thead>
					<tr aria-label="Subcription Breakdown Table Header">
						<th className="text-left align-top" colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`What's included`}
								className="text-white-100 font-[600] pr-[1rem]"
							/>
						</th>
						<th className="text-left px-1 align-top">
							<Typography
								type="headline-xsmall"
								label="Probly+"
								className="text-white-100 font-[600] pr-[1rem]"
							/>
						</th>
						<th className="text-left px-1 align-top">
							<Typography
								type="headline-xsmall"
								label="Probly+ Live"
								className="text-white-100 font-[600]"
							/>
						</th>
					</tr>
				</thead>
				<tbody aria-label="Subscription Breakdown Table Body">
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Filter By Probability`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Find bets based upon the likelihood they have to win according to data from top sportsbooks around the world.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Filter By Profitability`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Browse bets by PROBLY Score, our proprietary Expected Value calculation.`}
								className="text-white-100 mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Filter By Last Updated Time`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Search for the most recently updated bets or ones that have stayed available for longer.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Search For Bets By Team or Player`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Users can find and view all markets we have data for by searching for a team or player.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`View Bets And PROBLY Scores For LIVE Games`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Take advantage of the most inefficient — and profitable — markets in betting with Probly's live in-game data.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Blank space" />
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Future Products And Features`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Get all future products that Probly develops, including a daily fantasy optimizer and more.`}
								className="text-white-100 text-left"
							/>
						</td>
						<td aria-label="Blank space" />
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
})
