import { BaseURL } from 'api'
import axios from 'axios'
import {
	LoginResponse,
	optDataProps,
	otpResponse,
	RegisterResponse,
	SocialResponse
} from 'interfaces'

// body: email or phone
export const registerx = async (body: {
	email: string | null
	phone: number | null
	code: string | null
}): Promise<RegisterResponse | null> => {
	let res = null as RegisterResponse | null
	await axios
		.post(`${BaseURL}/auth/register`, {
			email: body.email,
			phone: body.phone,
			code: body.code
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)
	// console.warn(res)
	return res
}

// body: email or phone
export const login = async (body: {
	email: string | null
	phone: number | null
	code: string | null
}): Promise<LoginResponse | null> => {
	let res = null as LoginResponse | null
	await axios
		.post(`${BaseURL}/auth/login`, {
			email: body.email?.toLocaleLowerCase(),
			phone: body.phone,
			code: body.code
		})
		.then(
			async response => {
				if (response.status === 200) res = response.data
			},
			error => {
				res = error.response.data
			}
		)
	return res
}

export const optVerification = async (
	optData: optDataProps
): Promise<otpResponse | null> => {
	let res = null as otpResponse | null
	await axios
		.post(`${BaseURL}/auth/otp_verification`, {
			email: optData.email ? optData.email.toLowerCase() : null,
			phone: optData.phone ? optData.phone.replaceAll('-', '') : null,
			code: optData.digitValidation,
			exp_time: optData.expTime
		})
		.then(
			async response => {
				if (response.status === 200) res = response.data
			},
			error => {
				res = error.response.data
			}
		)

	return res
}

export const socialLogin = async (
	email: string | undefined,
	accessToken: string,
	socialNetwork: string
): Promise<SocialResponse | null> => {
	let res = null as SocialResponse | null
	await axios
		.post(`${BaseURL}/auth/social_auth`, {
			email,
			access_token: accessToken,
			social_network: socialNetwork
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)
	// console.warn(res)
	return res
}
