import { makeAutoObservable } from 'mobx'

export class AdStore {
	private should_show_apple_interstitial = false

	private should_show_web_interstitial = false

	private has_shown_ad = false

	private has_shown_site_explanation_modal = false

	constructor() {
		makeAutoObservable(this)
	}

	get shouldShowAppleInterstitial(): boolean {
		return this.should_show_apple_interstitial
	}

	get shouldShowWebInterstitial(): boolean {
		return this.should_show_web_interstitial
	}

	get hasShownSiteExplanationModal(): boolean {
		return this.has_shown_site_explanation_modal
	}

	get hasShownAd(): boolean {
		return this.has_shown_ad
	}

	setHasShownAd(hasShownAd = false): void {
		this.has_shown_ad = hasShownAd
	}

	setHasShownSiteExplanationModal = (
		hasShownSiteExplanationModal = false
	): void => {
		this.has_shown_site_explanation_modal = hasShownSiteExplanationModal
	}

	showAppleInterstitial = (): void => {
		this.should_show_apple_interstitial = true
	}

	showWebInterstitial = (): void => {
		this.should_show_web_interstitial = true
	}

	closeAds = (): void => {
		this.should_show_apple_interstitial = false
		this.should_show_web_interstitial = false
	}
}
