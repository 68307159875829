/* eslint-disable react/require-default-props */
import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { IconsType } from 'interfaces'
import React from 'react'

interface SportsItemProps {
	sportId: string
	sportName: string
	logoUrl: string
	selected: string
	handleClick: (val: string) => void
}

export const SportsItem: React.FC<SportsItemProps> = ({
	sportId,
	sportName,
	logoUrl,
	selected,
	handleClick
}): JSX.Element => {
	const srcUrl = logoUrl as IconsType
	const isSportSelected = selected === sportId
	const onClick = (): void => {
		handleClick(sportId)
	}
	const onKeyDown: React.KeyboardEventHandler = (evt: React.KeyboardEvent) => {
		if (evt.key === 'Enter') {
			onClick()
		}
	}
	return (
		<div className="w-14 h-20 flex flex-col gap-1 justify-center items-center">
			<Typography
				type="label-medium"
				label={sportName}
				className={clsx(
					isSportSelected ? 'text-white-100' : 'text-neutral-200'
				)}
			/>
			<div
				className={clsx(
					'relative w-14 h-14 flex justify-center items-center',
					'rounded-full border-2 border-primary-500',
					isSportSelected ? 'bg-primary-500' : 'bg-neutral-400'
				)}
				aria-hidden
				onClick={onClick}
				onKeyDown={onKeyDown}
			>
				<div className="w-6 h-6">
					<Icon
						src={srcUrl}
						fillPath
						className={clsx(
							isSportSelected ? 'text-white-100' : 'text-neutral-200'
						)}
					/>
				</div>
			</div>
		</div>
	)
}
