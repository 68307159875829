/* eslint-disable react/require-default-props */
import React from 'react'
import { Icon } from 'components/icon'
import { Icons } from 'interfaces'
import clsx from 'clsx'
import { Typography } from 'components'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStore } from 'stores'
import styles from './lock-box.module.scss'

export interface BoxLockProps {
	lock?: boolean
}

export const BoxLock: React.FC<BoxLockProps> = ({
	lock = false
}): JSX.Element => {
	const { authStore } = useStore()
	const navigate = useNavigate()
	const location = useLocation()
	const handleLockClick = (): void => {
		if (!authStore.token) {
			authStore.setShowSignInFooter(true)
		} else {
			navigate('/subscribe', { state: { from: location } })
		}
	}

	const handleLockKeyDown: React.KeyboardEventHandler = (
		evt: React.KeyboardEvent
	): void => {
		if (evt.key === 'Enter') {
			handleLockClick()
		}
	}
	const tabIndex = Math.floor(Math.random() * 10000)
	return (
		<div
			className={clsx(
				styles.lockbox,
				'w-[78px] inline-flex justify-center items-center gap-[6px]',
				'bg-neutral-300 rounded px-[6px] py-1'
			)}
			onClick={handleLockClick}
			onKeyDown={handleLockKeyDown}
			role="button"
			tabIndex={tabIndex}
		>
			<div className="w-3 h-4">
				<Icon src={Icons.lock} fillPath className="text-neutral-200" />
			</div>
			<Typography
				type="body-medium"
				label={lock ? 'Lock' : 'Unlock'}
				className="text-neutral-200"
			/>
		</div>
	)
}

export default BoxLock
