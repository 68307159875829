import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { Button } from 'components/buttons'
import React, { Fragment } from 'react'

export interface ModalDeleteProps {
	open: boolean
	setOpen: (val: boolean) => void
	onClick: () => void
	children: JSX.Element
}

export const ModalDelete: React.FC<ModalDeleteProps> = ({
	open,
	setOpen,
	onClick,
	children
}): JSX.Element => {
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="z-30 fixed inset-0"
				open={open}
				onClose={() => setOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 backdrop-blur-[2px] bg-opacity-25" />
				</Transition.Child>

				<div
					aria-hidden="true"
					className="fixed inset-0 overflow-y-auto"
					onClick={() => setOpen(false)}
				/>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<div
						className={clsx(
							'absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]',
							'w-full max-w-xs transform overflow-hidden rounded-2xl bg-neutral-400 p-6 text-left',
							'align-middle shadow-xl transition-all'
						)}
					>
						<div className="flex justify-center items-center gap-3 pb-2">
							<div
								className={clsx(
									'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-danger-500 bg-opacity-50'
									// 'sm:mx-0 sm:h-10 sm:w-10'
								)}
							>
								<ExclamationIcon
									className="h-6 w-6 text-white-100 opacity-100"
									aria-hidden="true"
								/>
							</div>
							<div className="text-left">{children}</div>
						</div>
						<div className="w-full flex justify-end gap-4 pt-2">
							<Button
								label="Ok"
								color="Primary"
								size="medium"
								className="w-32"
								onClick={() => (onClick ? onClick() : setOpen(false))}
							/>
							<Button
								label="Cancel"
								color="Secondary"
								size="medium"
								className="w-32 border border-neutral-200"
								onClick={() => setOpen(false)}
							/>
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}
