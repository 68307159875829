import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { Stack } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'stores'
import { Loading, CustomSwitch, Button, Typography, Icon } from 'components'
import { createAppleSubscription } from 'api/post/subscriptions'
import { ApplePurchaseStatus, IAPTransaction, Icons } from 'interfaces'

declare const window: Window & {
	// eslint-disable-next-line
    webkit?: any
}

export const ApplePricingTable: React.FC = observer((): JSX.Element => {
	const { appleIAPStore, userStore } = useStore()
	const { subscriptions } = appleIAPStore
	const { subscriptionPlanId, subscriptionStatus, userId } = userStore.user
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [showYearlyPlans, setShowYearlyPlans] = useState<boolean>(false)
	const isSubscribed = subscriptionStatus === 'active'
	const buttonText = isSubscribed ? t`Subscribed` : t`Subscribe Now`
	const subPlansToDisplay = showYearlyPlans
		? subscriptions.filter(sub => sub?.attributes?.offerName?.includes('year'))
		: subscriptions.filter(sub => sub?.attributes?.offerName?.includes('month'))
	subPlansToDisplay.sort((a, b) => {
		return (
			parseFloat(a.attributes?.offers?.[0]?.price?.toString() || '0') -
			parseFloat(b.attributes?.offers?.[0]?.price?.toString() || '1')
		)
	})
	const [selectedPlanId, setSelectedPlanId] = useState<string>(
		subPlansToDisplay[0]?.attributes?.offerName || ''
	)
	const handlePurchaseRequest = (offerName: string): void => {
		setIsLoading(true)
		window.webkit?.messageHandlers?.['iap-purchase-request'].postMessage(
			JSON.stringify({ productID: offerName, quantity: 1 })
		)
	}
	const handlePlanChange = (): void => {
		const shouldShowYearlyNewValue = !showYearlyPlans
		const newSubPlans = shouldShowYearlyNewValue
			? subscriptions.filter(sub =>
					sub?.attributes?.offerName?.includes('year')
				)
			: subscriptions.filter(sub =>
					sub?.attributes?.offerName?.includes('month')
				)
		setShowYearlyPlans(shouldShowYearlyNewValue)
		setSelectedPlanId(newSubPlans[0]?.attributes?.offerName)
	}

	const handlePlanSelection = (planId: string): void => {
		setSelectedPlanId(planId)
	}

	const handlePlanSelectionKeyDown = (
		evt: React.KeyboardEvent,
		planId: string
	): void => {
		if (evt.key === 'Enter') {
			handlePlanSelection(planId)
		}
	}

	useEffect(() => {
		// Set up event handlers for communication between native Swift layer and javascript
		// webview layer

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.addEventListener('iap-transactions-result', (event: CustomEvent) => {
			if (event?.detail) {
				appleIAPStore.setProductTransactions(
					JSON.parse(event.detail) as IAPTransaction[]
				)
			}
		})

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.addEventListener('iap-purchase-result', (event: CustomEvent) => {
			if (event?.detail) {
				const result = event.detail as ApplePurchaseStatus
				appleIAPStore.setPurchaseStatus(result)
				setIsLoading(false)
			}
		})

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.addEventListener(
			'iap-purchase-transaction',
			async (event: CustomEvent) => {
				// This event is only sent when the purchase transaction has successfully completed
				if (event?.detail) {
					const transaction = JSON.parse(event.detail) as IAPTransaction
					const transactionId = transaction?.transactionId
					if (transactionId) {
						setIsLoading(true)
						try {
							const res = await createAppleSubscription({ userId, transaction })
							if (res?.statusCode === 200) {
								appleIAPStore.setIsSubRecordCreated(true)
							}
						} catch (err) {
							console.error(err)
						}
					}
					navigate(`/subscribe/apple/confirmation`)
				}
			}
		)
	}, [])

	useEffect(() => {
		if (!selectedPlanId && subscriptions.length > 0) {
			setSelectedPlanId(subPlansToDisplay[0]?.attributes?.offerName || '')
		}
	}, [subscriptions, selectedPlanId])

	return isLoading ? (
		<div className="flex w-full h-full items-center justify-center">
			<Loading size="large" />
		</div>
	) : (
		<div className="px-8 flex flex-col items-center bg-black-900">
			<div className="w-full mt-[0.5rem]">
				<Typography
					type="headline-small"
					label={t`Choose Your Plan`}
					className="text-white-100 text-center"
				/>
			</div>
			<Stack
				direction="row"
				spacing={1}
				alignItems="center"
				className="my-[2rem]"
			>
				<Typography
					type="title-medium"
					label={t`Monthly`}
					className="text-white-100"
				/>
				<CustomSwitch
					checked={showYearlyPlans}
					onChange={handlePlanChange}
					inputProps={{ 'aria-label': 'Subscription Plan Toggle' }}
				/>
				<Typography
					type="title-medium"
					label={t`Yearly`}
					className="text-white-100"
				/>
			</Stack>
			<div className="flex gap-6">
				{subPlansToDisplay?.map(subscription =>
					// Don't show user sub plan if they already are subscribed to that plan
					subscriptionPlanId === subscription?.attributes?.offerName &&
					subscriptionStatus === 'active' ? null : (
						<div
							className={clsx(
								'border-solid border-2 hover:border-double border-neutral-400 rounded-md',
								'w-[50%] h-[160px] sm:w-[180px] py-[1rem] px-[1rem] my-[1rem]',
								{
									'border border-primary-500 bg-neutral-400':
										subscription?.attributes?.offerName === selectedPlanId,
									'bg-neutral-600':
										subscription?.attributes?.offerName !== selectedPlanId
								}
							)}
							onClick={() => {
								handlePlanSelection(subscription?.attributes?.offerName)
							}}
							onKeyDown={evt => {
								handlePlanSelectionKeyDown(
									evt,
									subscription?.attributes?.offerName
								)
							}}
							aria-label="Probly Subscription Plan Selection"
							role="button"
							tabIndex={0}
						>
							<Typography
								type="title-large"
								className="text-white-100 text-center font-[600] h-[48px]"
								label={subscription?.attributes?.name
									?.replace(' (1 month)', '')
									.replace(' (1 Month)', '')
									.replace(' (1 Year)', '')
									.replace(' (1 year)', '')}
							/>
							<br />
							<div className="justify-center text-center">
								<Typography
									type="title-medium"
									className="text-white-100 font-[600]"
									label={`${
										subscription?.attributes?.offers?.[0]?.priceFormatted
									} ${showYearlyPlans ? t`per year` : t`per month`}`}
								/>
							</div>
						</div>
					)
				)}
			</div>
			<div className="border-b border-b-neutral-200/30" />
			<table
				className="table-fixed mx-[0.5rem] my-[1.5rem] border-seperate border-spacing-y-24"
				role="table"
			>
				<thead>
					<tr aria-label="Subcription Breakdown Table Header">
						<th className="text-left align-top" colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`What's included`}
								className="text-white-100 font-[600] pr-[1rem]"
							/>
						</th>
						<th className="text-left px-1 align-top">
							<Typography
								type="headline-xsmall"
								label="Probly+"
								className="text-white-100 font-[600] pr-[1rem]"
							/>
						</th>
						<th className="text-left px-1 align-top">
							<Typography
								type="headline-xsmall"
								label="Probly+ Live"
								className="text-white-100 font-[600]"
							/>
						</th>
					</tr>
				</thead>
				<tbody aria-label="Subscription Breakdown Table Body">
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Filter By Probability`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Find bets based upon the likelihood they have to win according to data from top sportsbooks around the world.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Filter By Profitability`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Browse bets by PROBLY Score, our proprietary Expected Value calculation.`}
								className="text-white-100 mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Filter By Last Updated Time`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Search for the most recently updated bets or ones that have stayed available for longer.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Search For Bets By Team or Player`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Users can find and view all markets we have data for by searching for a team or player.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`View Bets And PROBLY Scores For LIVE Games`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Take advantage of the most inefficient — and profitable — markets in betting with Probly's live in-game data.`}
								className="text-white-100 text-left mb-[1rem]"
							/>
						</td>
						<td aria-label="Blank space" />
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
					<tr role="row">
						<td colSpan={2}>
							<Typography
								type="headline-xsmall"
								label={t`Future Products And Features`}
								className="text-white-100 font-[700]"
							/>
							<Typography
								type="label-large"
								label={t`Get all future products that Probly develops, including a daily fantasy optimizer and more.`}
								className="text-white-100 text-left"
							/>
						</td>
						<td aria-label="Blank space" />
						<td aria-label="Checkmark">
							<div className="w-8 h-8 ml-2">
								<Icon src={Icons.check} fillPath className="text-primary-500" />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<div className="w-full lg:w-[320px] fixed bottom-[4.25rem] z-[100] bg-black-900 p-[1.5rem]">
				<Button
					label={buttonText}
					disabled={isSubscribed}
					className="w-[288px] md:w-[450px] lg:w-[320px]"
					classNameDiv="!w-full flex justify-center"
					onClick={() => handlePurchaseRequest(selectedPlanId)}
					style={{ fontSize: '2.5rem', fontWeight: 'bold' }}
				/>
			</div>
		</div>
	)
})
