import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import ReactGA from 'react-ga4'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import clsx from 'clsx'
import { useNavigate, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import { BetSurveyModal, GoodLuck, Icon, Typography } from 'components'
import { ScoresExplanationModal } from 'components/modal/scoresExplanationModal'
import { useCountdown } from 'hooks'
import { Icons, Images, PosterBoxProps } from 'interfaces'
// import { isIOS, isSafari } from 'react-device-detect'
import { useStore } from 'stores'
import { Social } from '..'
import styles from './poster.module.scss'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export const PosterBox: React.FC<PosterBoxProps> = observer(
	({ post, onSlide }): JSX.Element => {
		const {
			storyId,
			marketDescription,
			probability,
			problyScore,
			vs,
			startTime,
			mediaUrl = '',
			comments,
			tracked,
			sportsBook,
			betOdds,
			lastUpdated,
			sportsBookUrl
		} = post
		const { userStore, authStore, appleIAPStore } = useStore()
		const navigate = useNavigate()
		const location = useLocation()
		const { user } = userStore
		const isIOSInApp = appleIAPStore.isIOSIAPCapable
		const startEvent = new Date(startTime)
		const { days, hours, minutes, seconds } = useCountdown(startEvent)
		const [finished, setFinished] = useState<boolean>(false)
		const [isLive, setIsLive] = useState<boolean>(false)
		const [indexActive, setIndexActive] = useState<number>(1)
		const [redirecting, setRedirecting] = useState<boolean>(false)
		const [swiper, setSwiper] = useState<SwiperType | null>(null)
		const [isScoresModalOpen, setIsScoresModalOpen] = useState<boolean>(false)
		const [isDiscarded, setIsDiscarded] = useState<boolean>(false)
		const [agree, setAgree] = useState<string>('neutral')
		const [showBallot, setShowBallot] = useState<boolean>(false)
		const oddSign = parseFloat(betOdds || '') >= 0 ? '+' : ''
		const lastUpdatedDate = new Date(
			lastUpdated ? lastUpdated * 1000 : Date.now()
		)
		const lastUpdatedString = `${dayjs
			.utc(lastUpdatedDate)
			.tz('America/New_York')
			.format('M/DD h:mma')} ET`
		const isUnlocked =
			(isLive &&
				user.subscriptionStatus === 'active' &&
				!!user.subscriptionPlanId?.includes('live')) ||
			!isLive

		const handleOpenScoresModalClick: React.MouseEventHandler = (): void => {
			setIsScoresModalOpen(true)
		}

		const handleOpenScoresModalKeyDown: React.KeyboardEventHandler = (
			evt: React.KeyboardEvent
		): void => {
			if (evt.key === 'Enter') setIsScoresModalOpen(true)
		}

		const handleLockClick = (): void => {
			if (!authStore.token) {
				authStore.showSignInPopUp()
			} else {
				navigate('/subscribe', { state: { from: location } })
			}
		}

		const handleLockKeyDown: React.KeyboardEventHandler = (
			evt: React.KeyboardEvent
		): void => {
			if (evt.key === 'Enter') {
				handleLockClick()
			}
		}

		const handleVoteToggle = (): void => {
			setShowBallot(!showBallot)
		}

		const resetScoresModalAgree = (): void => {
			setAgree('neutral')
		}

		useEffect(() => {
			const startDateTime = startEvent?.getTime()
			if (startDateTime <= Date.now()) {
				setIsLive(true)
				if (
					dayjs(startEvent)?.add(3, 'hours')?.toDate()?.getTime() < Date.now()
				) {
					setIsLive(false)
					setFinished(true)
				}
			}

			return () => {
				setIsLive(false)
				setFinished(false)
			}
		}, [seconds])

		useEffect(() => {
			const interval = setTimeout(() => {
				switch (indexActive) {
					// case 0: {
					// setRedirecting(true)
					// if (isIOS || isSafari) {
					// 	window.open('http://betmgm.com/', '_self', 'popup=true')
					// } else {
					// 	window.open('http://betmgm.com/', '_blank')
					// }
					// break
					// }
					// case 1:
					// 	// navigate('/', { href = : true })
					// 	break
					case 0:
						setAgree('yes')
						if (isUnlocked) {
							setIsScoresModalOpen(true)
						} else {
							handleLockClick()
						}
						break
					case 2:
						// setRedirecting(true)
						// if (isIOS || isSafari) {
						// 	window.open('http://betmgm.com/', '_self', 'popup=true')
						// } else {
						// 	window.open('http://betmgm.com/', '_blank')
						// }
						// setAgree('no')
						// setIsScoresModalOpen(true)
						setIsDiscarded(true)
						break
					default:
						break
				}
				swiper?.slideTo(1)
			}, 800)
			return () => {
				clearTimeout(interval)
			}
		}, [indexActive])

		useEffect(() => {
			if (redirecting) window.location.replace('/')
			return () => {
				setRedirecting(false)
				resetScoresModalAgree()
			}
		}, [redirecting])
		return (
			<Swiper
				className={
					isDiscarded
						? clsx(styles.dislike, styles.posterFeed)
						: styles.posterFeed
				}
				style={{ zIndex: 0, minHeight: '100%', background: '#000' }}
				centeredSlides
				initialSlide={1}
				onSwiper={setSwiper}
				onSlideChange={({ activeIndex }: { activeIndex: number }) => {
					setIndexActive(activeIndex)
					if (onSlide) onSlide(post.storyId, activeIndex !== 1)
					ReactGA.event({
						category: 'Stories',
						action: 'Slide Change',
						label: isIOSInApp ? 'iOS In App' : 'Web' // optional
					})
				}}
			>
				<SwiperSlide>
					<GoodLuck />
				</SwiperSlide>
				<SwiperSlide>
					<div
						className={clsx(
							'relative w-full h-full flex-1 flex justify-center items-center'
						)}
					>
						<img
							src={mediaUrl}
							alt="post"
							className="w-full h-full object-cover absolute inset-0"
						/>
						<div className="absolute inset-0">
							<div className={clsx(styles.gradientTop, 'w-full h-1/2')} />
							<div className={clsx(styles.gradientBottom, 'w-full h-1/2')} />
						</div>
						<div className="relative w-full h-[calc(100dvh-191px)] z-50 p-4 flex flex-col justify-between">
							<div className="flex flex-col justify-center items-center">
								<header className="w-full h-[80px] flex justify-between items-center ">
									<div className="w-full h-full flex items-start justify-start">
										<Typography
											type="title-medium"
											label={marketDescription}
											className="text-white-100 line-clamp-3 my-auto"
										/>
									</div>
									<div className="w-[170px] h-full flex flex-col">
										<div className="w-full h-full flex justify-end items-center">
											<div
												className={clsx(
													styles.scoresContainer,
													'w-4 h-full py-2.5'
												)}
												onClick={handleOpenScoresModalClick}
												onKeyDown={handleOpenScoresModalKeyDown}
												aria-label="Bet Information"
												role="button"
												tabIndex={0}
											>
												<div className="w-4 h-4">
													{isUnlocked ? (
														<Icon
															src={Icons.info}
															fillPath
															className="text-primary-500"
														/>
													) : null}
												</div>
											</div>
											{isUnlocked ? (
												<div
													className={styles.scoresContainer}
													onClick={handleOpenScoresModalClick}
													onKeyDown={handleOpenScoresModalKeyDown}
													aria-hidden="true"
												>
													<Typography
														type="display-medium"
														label={`${Math.round(probability)}%`}
														className="text-primary-500"
														style={{ fontSize: '45px', fontWeight: 'bold' }}
													/>
												</div>
											) : (
												<div
													className={clsx(styles.scoresContainer, 'w-8 h-9')}
													onClick={handleLockClick}
													onKeyDown={handleLockKeyDown}
													aria-hidden="true"
												>
													<Icon
														src={Icons.lock}
														fillPath
														className="text-primary-500 font-bold"
													/>
												</div>
											)}
										</div>
										{isUnlocked ? (
											<>
												<div
													className={styles.scoresContainer}
													onClick={handleOpenScoresModalClick}
													onKeyDown={handleOpenScoresModalKeyDown}
													aria-hidden="true"
												>
													<Typography
														type="label-medium"
														label={`PROBLY ${t`Score`}: ${
															problyScore?.toFixed(1).toString() || 'N/A'
														}`}
														className="text-neutral-50 text-right whitespace-nowrap"
													/>
												</div>
												<div
													className={clsx(styles.scoresContainer, 'flex gap-1')}
													onClick={handleOpenScoresModalClick}
													onKeyDown={handleOpenScoresModalKeyDown}
													aria-hidden="true"
												>
													<Typography
														type="label-medium"
														label={`${t`Best Bet`}: ${sportsBook || 'N/A'}`}
														className="w-[124px] truncate text-neutral-50 text-right whitespace-nowrap"
													/>
													<Typography
														type="label-medium"
														label={`${oddSign}${parseFloat(betOdds || '')
															?.toFixed(0)
															.toString()}`}
														className="text-neutral-50 text-right whitespace-nowrap"
													/>
												</div>
											</>
										) : null}
									</div>
								</header>
								<div className="w-full h-[1px] bg-neutral-200 my-2" />
								<div className="w-full h-[51px] flex flex-col gap-1">
									<div className="relative w-full bg-primary-500 rounded-full flex justify-center items-center">
										<div className="w-full flex justify-center items-center gap-1 mx-6">
											<Typography
												type="label-large"
												label={`${vs.split('vs')[0]}`}
												className="text-white-100 text-right line-clamp-1"
											/>
											<Typography
												type="label-large"
												label="vs"
												className="text-white-100"
											/>
											<Typography
												type="label-large"
												label={`${vs.split('vs')[1]}`}
												className="text-white-100 text-left line-clamp-1"
											/>
										</div>
										<div className="absolute flex gap-1 right-0 pr-2 w-6 h-6">
											<Icon
												src={Icons.sparklesUp}
												fillPath
												className="text-neutral-100"
											/>
										</div>
									</div>
									<div className="w-full flex justify-center items-center">
										{isLive ? (
											<Typography
												type="label-large"
												label={t`Live`}
												className="w-[60px] h-5 text-neutral-50 bg-danger-500 opacity-80 rounded-lg flex justify-center items-center"
											/>
										) : (
											<div>
												{finished ? (
													<Typography
														type="label-large"
														label={t`Finished`}
														className="px-4 h-5 text-neutral-50 bg-primary-500 opacity-80 rounded-lg flex justify-center items-center"
													/>
												) : (
													<div className="flex justify-center items-center gap-1">
														<Typography
															type="title-small"
															label={t`Game Starts in`}
															className="text-neutral-50 font-black"
														/>
														{days > 0 ? (
															<span className="rounded-lg bg-neutral-400 opacity-60 px-3">
																<Typography
																	type="title-small"
																	label={t`Soon`}
																	className="text-neutral-50"
																/>
															</span>
														) : (
															<span className="rounded-lg bg-neutral-400 opacity-60 px-2">
																<Typography
																	type="title-small"
																	label={`${(hours || 0) < 10 ? `0${hours}` : hours}:${
																		(minutes || 0) < 10
																			? `0${minutes}`
																			: minutes
																	}:${(seconds || 0) < 10 ? `0${seconds}` : seconds}`}
																	className="text-neutral-50"
																/>
															</span>
														)}
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="w-full h-full flex-1 flex justify-end items-center bg-transparent">
								<div className="w-min h-full flex justify-center items-center bg-transparent">
									<Social
										storyId={storyId}
										sportsBookUrl={sportsBookUrl}
										tracked={tracked}
										comments={comments}
										onVoteToggle={handleVoteToggle}
									/>
								</div>
							</div>
							{showBallot ? (
								<div
									className={clsx(
										'absolute bottom-8 left-1/2 -translate-x-1/2 w-[212px] flex justify-center item-center bg-transparent transition duration-300 ease-in-out'
									)}
								>
									<BetSurveyModal
										post={post}
										storyId={storyId}
										title="Does this happen?"
									/>
								</div>
							) : (
								<div
									className={clsx(
										'absolute bottom-6 left-1/2 -translate-x-1/2 w-[212px] flex flex-col gap-4 justify-center items-center bg-transparent'
									)}
								>
									<div
										className={clsx(
											'text-center py-[4px] px-[2px]',
											'w-[225px] sm:w-[275px] h-full rounded-2xl bg-neutral-400 opacity-90'
										)}
									>
										<Typography
											type="label-medium"
											label={`${t`Last Updated:`} ${lastUpdatedString}`}
											className="text-neutral-50"
										/>
									</div>
									<div>
										<img
											src={Images.giphyLogoBlackHorizontalLogo}
											alt="giphy logo"
											height="100%"
											width="125px"
										/>
									</div>
								</div>
							)}
							{isUnlocked && (
								<ScoresExplanationModal
									agree={agree}
									story={post}
									isModalOpen={isScoresModalOpen}
									setIsModalOpen={setIsScoresModalOpen}
									onClose={resetScoresModalAgree}
								/>
							)}
						</div>
					</div>
				</SwiperSlide>
				{/* Below if the slide for a left swipe */}
				<SwiperSlide />
			</Swiper>
		)
	}
)
