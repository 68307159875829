import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'
import { t } from '@lingui/macro'
import { uniqBy } from 'lodash'
import ReactGA from 'react-ga4'
import { observer } from 'mobx-react'
import moment from 'moment'
import {
	GET_EVENTS_BY_LAST_KEY,
	GET_STORIES_V2,
	getEventsByLastKey,
	getIndividualStory
} from 'api'
import clsx from 'clsx'
import {
	ExploreBox,
	Input,
	LayoutApp,
	Loading,
	TrackingEventsBox,
	// TrackingEventsBox,
	Typography
} from 'components'
import { useSearchDebounce } from 'hooks'
import { useStore } from 'stores'
import { Icons } from 'interfaces'

export const Explore: React.FC = observer(() => {
	const { storyFilterFormStore, authStore } = useStore()
	const {
		sportID,
		leagueID,
		filterFormInputs,
		isFiltered,
		setIsOrderLocked,
		clearStoryFilterForm
	} = storyFilterFormStore
	const finalToken = authStore.token
	const { ref, inView } = useInView()
	const { register, control } = useForm<{ search: string }>({
		mode: 'onChange'
	})
	const watchSearch = useWatch({
		control,
		name: 'search'
	})
	const { inputSearch, handleChangeDebounce } = useSearchDebounce(watchSearch)
	const [view, setView] = useState<'mostPopularEvents' | 'allBets'>(
		'mostPopularEvents'
	)

	const {
		data: eventsData,
		isLoading,
		fetchNextPage,
		isFetchingNextPage: isFetchingNextEventsPage,
		hasNextPage: hasNextEventsPage,
		refetch: refetchEvents
	} = useInfiniteQuery(
		[GET_EVENTS_BY_LAST_KEY, { inputSearch, sportID, leagueID }],
		({ pageParam = undefined }) =>
			getEventsByLastKey({
				leagueID,
				lastEvaluatedKeys: pageParam,
				search: inputSearch,
				sportID: leagueID ? undefined : sportID
			}),
		{
			cacheTime: 60 * 1000, // 1 minute
			getNextPageParam: lastPage => lastPage.LastEvaluatedKeys,
			enabled: view === 'mostPopularEvents',
			refetchOnWindowFocus: false
		}
	)
	const exploreItems =
		uniqBy(
			eventsData?.pages.flatMap(page => page.events),
			'eventId'
		) ?? []
	const limit =
		!inputSearch && (sportID === 'all' || !sportID) && !isFiltered ? 5 : 10
	const {
		data: betsData,
		isLoading: isLoading2,
		fetchNextPage: fetchNextPage2,
		isFetchingNextPage: isFetchingNextStoriesPage,
		hasNextPage: hasNextBetsPage,
		refetch: refetchStories
	} = useInfiniteQuery(
		[
			GET_STORIES_V2,
			{
				token: finalToken,
				inputSearch,
				sportID,
				leagueID,
				limit,
				isFiltered,
				...filterFormInputs
			}
		],
		({ pageParam = undefined }) =>
			getIndividualStory({
				leagueID,
				accessToken: finalToken,
				lastEvaluatedKeys: pageParam,
				limit,
				sportID,
				search: inputSearch,
				isFiltered,
				...filterFormInputs
			}),
		{
			cacheTime: 60 * 1000, // 1 minute
			getNextPageParam: lastPage => lastPage.LastEvaluatedKeys,
			enabled: view === 'allBets',
			refetchOnWindowFocus: false
		}
	)
	const allEvents =
		uniqBy(
			betsData?.pages.flatMap(page => page.data),
			'storyId'
		) ?? []

	const changeTab = (tab: typeof view): void => {
		setView(tab)
		if (tab === 'allBets') {
			setIsOrderLocked(false)
			refetchStories()
		}
		if (tab === 'mostPopularEvents') {
			clearStoryFilterForm()
			setIsOrderLocked(true)
			refetchEvents()
		}
	}

	useLayoutEffect(() => {
		changeTab('mostPopularEvents')
		return () => {
			setIsOrderLocked(false)
		}
	}, [])

	useEffect(() => {
		if (!inView) return
		if (view === 'mostPopularEvents') fetchNextPage()
		if (view === 'allBets') fetchNextPage2()
	}, [inView])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Explore'
		})
	}, [])

	// Having 2 bets or events is within view but not enough to continue the search
	// So this serves as a workaround.
	useEffect(() => {
		if (isFetchingNextEventsPage || isFetchingNextStoriesPage) {
			return
		}

		if (
			inView &&
			view === 'mostPopularEvents' &&
			exploreItems.length > 0 &&
			exploreItems.length < 3
		) {
			fetchNextPage()
		}

		if (
			inView &&
			view === 'allBets' &&
			allEvents.length > 0 &&
			allEvents.length < 3
		) {
			fetchNextPage2()
		}
	}, [
		view,
		inView,
		exploreItems,
		allEvents,
		isFetchingNextEventsPage,
		isFetchingNextStoriesPage
	])

	return (
		<LayoutApp header footer isLoading={isLoading || isLoading2}>
			<div className="flex flex-col gap-4 w-full h-full bg-black-500">
				<div className="flex flex-col gap-4 px-4">
					<div className="items-center w-full pt-6">
						<Input
							name="search"
							register={register}
							placeholder={t`Find`}
							leftImg={Icons.search}
							type="text"
							onChange={handleChangeDebounce}
						/>
					</div>
					<div className="flex justify-between items-center w-full mb-1">
						<div
							className={clsx(
								'basis-[50%] text-center pb-2 cursor-pointer',
								view === 'mostPopularEvents' &&
									'border-b-2 border-b-primary-500'
							)}
							aria-hidden
							onClick={() => changeTab('mostPopularEvents')}
						>
							<Typography
								type="title-small"
								label={t`Most Popular Events`}
								className="text-neutral-50"
							/>
						</div>
						<div
							className={clsx(
								'basis-[50%] text-center pb-2 cursor-pointer',
								view === 'allBets' && 'border-b-2 border-b-primary-500'
							)}
							aria-hidden
							onClick={() => changeTab('allBets')}
						>
							<Typography
								type="title-small"
								label={t`View All Bets`}
								className="text-neutral-50"
							/>
						</div>
					</div>
				</div>
				<div className="w-full h-[calc(100dvh-322px)] flex flex-col items-center px-4 overflow-hidden overflow-y-scroll hide-scroll-bar">
					{view === 'mostPopularEvents' &&
						(!exploreItems.length ? (
							<Typography
								type="title-small"
								label="Event Not Found"
								className="text-neutral-50"
							/>
						) : (
							<div className="flex flex-col w-full">
								{exploreItems?.map(item => {
									return (
										<ExploreBox
											key={item.eventId}
											{...item}
											vs={`${item.homeTeam} vs ${item.awayTeam}`}
											startTime={moment(item.startTime).toDate()}
										/>
									)
								})}
								{hasNextEventsPage && (
									<div ref={ref} className="min-h-[20px] h-fit">
										{isFetchingNextEventsPage && (
											<div className="w-full flex justify-center items-center py-3 bg-transparent">
												<Loading size="x-small" version="v2" />
											</div>
										)}
									</div>
								)}
							</div>
						))}
					{view === 'allBets' && (
						<div className="flex flex-col gap-4 w-full">
							{allEvents.map(event => (
								<TrackingEventsBox
									key={event.storyId}
									event={{
										...event,
										vs: `${event.homeTeam} vs ${event.awayTeam}`,
										startTime: moment(event.startTime).toDate()
									}}
								/>
							))}
							{hasNextBetsPage && (
								<div ref={ref} className="min-h-[20px] h-fit">
									{isFetchingNextStoriesPage && (
										<div className="w-full flex justify-center items-center pb-3 bg-transparent">
											<Loading size="x-small" version="v2" />
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</LayoutApp>
	)
})
