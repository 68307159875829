import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { observer } from 'mobx-react'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { getProfile, GET_PROFILE } from 'api'
import { useStore } from 'stores'
import { LayoutApp, Loading, Typography } from 'components'
import { useQuery } from 'react-query'
import styles from './styles.module.scss'

export const AppleSubscribeConfirmation: React.FC = observer(
	(): JSX.Element => {
		const { appleIAPStore, authStore, userStore } = useStore()
		const [isLoading, setIsLoading] = useState<boolean>(true)
		const { token } = authStore
		const {
			data,
			isLoading: isProfileLoading,
			refetch: fetchUserProfile
		} = useQuery([GET_PROFILE, token], () => getProfile(token), {
			enabled: false
		})

		useEffect(() => {
			ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
			ReactGA.send({
				hitType: 'pageview',
				page: window.location.pathname + window.location.search,
				title: 'Subscribe Confirmation - iOS'
			})
			setIsLoading(false)

			return () => {
				fetchUserProfile()
					.then(res => {
						if (res?.isError) {
							throw res.error
						}
						if (res?.data?.user) {
							userStore.setUser(res.data?.user)
						}
					})
					.catch(console.error)
			}
		}, [])

		useEffect(() => {
			if (!isLoading) {
				fetchUserProfile()
			}
		}, [isLoading])

		useEffect(() => {
			if (data?.user) {
				userStore.setUser(data?.user)
			}
		}, [data])

		const isSuccess = appleIAPStore.purchaseStatus === 'success'
		const title = isSuccess ? t`Thanks for subscribing!` : t`Error`
		const content = isSuccess
			? `${t`A payment to`} probly.com ${t`will appear on your statement.`}`
			: t`Something went wrong. Please try subscribing again later.`
		return (
			<LayoutApp footer headerSpacing isLoading={false}>
				<div
					className={clsx(
						styles.containerHome,
						'bg-black-900 relative h-[calc(100vh-69px)] overflow-hidden overflow-y-scroll hide-scroll-bar'
					)}
				>
					{isLoading || isProfileLoading ? (
						<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
							<Loading size="large" />
						</div>
					) : (
						<>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyItems: 'center',
									justifyContent: 'center',
									marginTop: '4rem',
									marginBottom: '2rem'
								}}
							>
								{isSuccess ? (
									<CheckCircleIcon className="h-12 w-12 text-green-400" />
								) : (
									<XCircleIcon className="h-12 w-12 text-red-400" />
								)}
							</div>
							<div
								style={{
									display: 'flex',
									flexFlow: 'column nowrap',
									alignItems: 'center',
									justifyItems: 'center',
									justifyContent: 'center',
									paddingLeft: '2rem',
									paddingRight: '2rem',
									textAlign: 'center'
								}}
							>
								<Typography
									type="title-large"
									label={title}
									className="text-white-100"
								/>
								<br />
								<Typography
									type="title-medium"
									label={content}
									className="text-white-100"
								/>
							</div>
						</>
					)}
				</div>
			</LayoutApp>
		)
	}
)
