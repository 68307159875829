import { XIcon } from '@heroicons/react/outline'
import { t } from '@lingui/macro'
import { LeagueItem, GET_LEAGUES, getLeagues } from 'api'
import { Dropdown, Input, Loading, Typography } from 'components'
import { useSearchDebounce } from 'hooks'
import { useStore } from 'stores'
import { Icons } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'

export interface DrawerSearchLeagueProps {
	onClose: (val: boolean) => void
	onLeagueChange: (league: LeagueItem) => void
	sportSelected: string
}

export interface LeagueType {
	countryId: number
	countryName: string
	count: number
	count_page: number
	data: LeagueItem[]
	next: number | null
	page: number
	previous: number | null
	records: number
}

export const DrawerSearchLeague: React.FC<DrawerSearchLeagueProps> = ({
	onClose,
	onLeagueChange,
	sportSelected
}): JSX.Element => {
	const { storyFilterFormStore } = useStore()
	const { setLeagueID } = storyFilterFormStore
	const { ref, inView } = useInView()
	const { register, control } = useForm<{ search: string }>({
		mode: 'onChange'
	})
	const [leagues, setLeagues] = useState<LeagueType[]>([])
	const watchSearch = useWatch({
		control,
		name: 'search'
	})
	const { inputSearch, handleChangeDebounce } = useSearchDebounce(watchSearch)

	const { data, isLoading, fetchNextPage, isFetchingNextPage } =
		useInfiniteQuery(
			[GET_LEAGUES, inputSearch, sportSelected],
			({ pageParam = 1 }) => getLeagues(pageParam, sportSelected, inputSearch),
			{
				cacheTime: 60 * 1000,
				getNextPageParam: lastPage =>
					lastPage?.leagues[0].next ? lastPage?.leagues[0].next : undefined
			}
		)

	useEffect(() => {
		setLeagues([])
		// if (data && data.pages.length === 0) {
		// 	setLeagues([])
		// }
		// if (data && data.pages.length > 0 && data?.pages[0]?.error) {
		// 	setLeagues([])
		// }
		if (data?.pages && data.pages.length > 0) {
			let auxData = [] as LeagueItem[]
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let auxLeague = [] as any[]
			auxLeague = data.pages
				.map(pagex => {
					const info = pagex?.leagues?.[0]
					const auxRes = info?.data
					if (auxRes) auxData = [...auxData, ...auxRes]

					return {
						...info,
						countryId: info?.countryId,
						data: auxData
					}
				})
				.slice(-1)

			if (auxLeague) setLeagues(auxLeague)
		}
	}, [data])

	useEffect(() => {
		if (inView) fetchNextPage()
	}, [inView])

	return (
		<div className="w-full h-full flex flex-col gap-3">
			{isLoading && (
				<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
					<Loading size="large" />
				</div>
			)}
			<div className="w-full h-6 flex justify-between items-center">
				<Typography
					type="label-large"
					label="Find a League"
					className="text-neutral-50"
				/>
				<button
					type="button"
					className="text-neutral-200"
					onClick={() => onClose(false)}
				>
					<span className="sr-only">Close panel</span>
					<XIcon className="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
			<div className="items-center w-full">
				<Input
					name="search"
					register={register}
					placeholder={t`Search by keywords`}
					leftImg={Icons.search}
					type="text"
					onChange={handleChangeDebounce}
				/>
			</div>
			<div className="w-full h-[calc(100%-88px)] flex flex-col gap-1 overflow-hidden overflow-y-scroll hide-scroll-bar">
				{leagues?.map(league => (
					<Dropdown
						key={league.countryId}
						label={league.countryName}
						className="px-[10px] cursor-pointer"
						initialOpen
					>
						<div className="w-full h-full flex flex-col gap-[2px] overflow-hidden overflow-y-scroll hide-scroll-bar">
							{league.data.map(item => (
								<div
									key={item.leagueId}
									className="w-full h-[50px] flex items-center gap-6 bg-neutral-300 px-4 py-2 cursor-pointer"
									aria-hidden
									onClick={() => {
										setLeagueID(item.leagueId)
										onLeagueChange(item)
										onClose(false)
									}}
								>
									<div className="w-[30px] h-[30px] min-w-[30px] rounded-full border border-white-200">
										<img
											src={item.logoUrl}
											alt="logo league"
											className="w-full h-full rounded-full"
										/>
									</div>
									<Typography
										type="label-large"
										label={item.leagueName}
										className="text-white-100"
									/>
								</div>
							))}
							<div ref={ref}>
								{isFetchingNextPage && (
									<div className="w-full flex justify-center items-center p-3 bg-transparent">
										<Loading size="x-small" version="v2" />
									</div>
								)}
							</div>
						</div>
					</Dropdown>
				))}
			</div>
		</div>
	)
}
