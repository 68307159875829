import { debounce, DebouncedFunc } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useSearchDebounce = (
	watchSearch: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): { inputSearch: string; handleChangeDebounce: DebouncedFunc<any> } => {
	const [inputSearch, setInputSearch] = useState('')

	useEffect(() => {
		setInputSearch(watchSearch)
	}, [watchSearch])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setInputSearch(event?.target.value)
	}

	const handleChangeDebounce = useCallback(debounce(handleChange, 1000), [])

	return { inputSearch, handleChangeDebounce }
}
