import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useQuery } from 'react-query'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { SplashLoading } from 'components'
import { useStore } from 'stores'
import { GET_PROFILE, getProfile } from 'api'

export const Splash: React.FC = observer((): JSX.Element => {
	const { authStore, userStore } = useStore()
	const navigate = useNavigate()
	const { token } = authStore
	const { data, isLoading } = useQuery([GET_PROFILE], () => getProfile(token), {
		cacheTime: 60 * 1000, // 1 minute
		refetchOnWindowFocus: false
	})

	useEffect(() => {
		if (!isLoading && data?.user) userStore.setUser(data.user)
	}, [data, isLoading])

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (!isLoading) {
			if (authStore.token && userStore.isSubscribed) {
				timer = setTimeout(() => {
					navigate('/home', { replace: true })
				}, 1000)
			}
			if (authStore.token && !userStore.isSubscribed) {
				timer = setTimeout(() => {
					navigate('/subscribe', { replace: true })
				}, 1000)
			}
			timer = setTimeout(() => {
				navigate('/start', { replace: true })
			}, 1000)
		}

		return () => {
			if (timer) clearTimeout(timer)
		}
	}, [isLoading, authStore.token, userStore.user.subscriptionStatus])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: (window.location?.pathname || '') + (window.location?.search || ''),
			title: 'Splash'
		})
	}, [])

	return <SplashLoading />
})
