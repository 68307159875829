import { BaseURL, BaseURLv2 } from 'api'
import axios from 'axios'
import { LastEvaluatedKeys } from 'interfaces'
import Cookies from 'universal-cookie'

const token = new Cookies()

export interface StoryProbly {
	storyId: string
	sportId: string
	marketDescription: string
	probability: number
	lineName: string
	problyScore: number
	vs: string
	mediaUrl: string
	startTime: Date
	comments: number
	yesPercentage: string | null
	tracked: boolean
	agree: 'No' | 'Yes' | null
}

export interface StoriesResponse {
	next: number | null
	previous: number | null
	page: number
	records: number
	count: number
	count_page: number
	user_status: string
	data: StoryProbly[]
}

export type StoryV2 = {
	storyId: string
	user_status?: string
	agree: 'Yes' | 'No' | null
	yesPercentage: string | null
	sportId: string
	marketDescription: string
	probability: number
	problyScore: number
	startTime: string | Date
	lineName: string
	leagueName?: string
	betId?: string
	betOdds?: string
	awayTeam?: string
	homeTeam?: string
	leagueId?: string
	comments: number
	oddsJamBetId?: string
	oddsJamEventId?: string
	mediaUrl: string
	tracked: boolean
	sportsBook?: string
	sportsBookUrl?: string
	mobileSportsBookUrl?: string
	lastUpdated?: number
	createdAt?: number
}

export interface StoriesResponseV2 {
	LastEvaluatedKeys?: LastEvaluatedKeys
	data: StoryV2[]
}

export const getIndividualStory = async (options?: {
	accessToken?: string
	sportID?: string
	leagueID?: string
	lastEvaluatedKeys?: LastEvaluatedKeys
	limit?: number
	search?: string
	probabilityMin?: number
	probabilityMax?: number
	problyScoreMin?: number
	lastUpdatedMax?: number
	isLive?: boolean
	isFiltered?: boolean
	sportsbooks?: string[] | string
}): Promise<StoriesResponseV2> => {
	return (
		await axios.post(
			`${BaseURLv2}/stories`,
			{
				LastEvaluatedKeys: options?.lastEvaluatedKeys,
				sportsbooks: options?.sportsbooks
			},
			{
				params: {
					sport_id: options?.sportID || 'all',
					league_id: options?.leagueID,
					limit: options?.limit,
					search: options?.search,
					probability_max: options?.probabilityMax,
					probability_min: options?.probabilityMin,
					probly_score_min: options?.problyScoreMin,
					last_updated_max: options?.lastUpdatedMax,
					is_live: options?.isLive,
					is_filtered: options?.isFiltered
				},
				timeout: 60000,
				headers: {
					...(options?.accessToken
						? { Authorization: `Bearer ${options.accessToken}` }
						: {})
				}
			}
		)
	).data
}

export const getStory = async (storyId: string): Promise<StoryV2 | null> => {
	let res = null as StoryV2 | null
	await axios.get(`${BaseURL}/story/${storyId}`).then(
		async response => {
			res = response.data
		},
		error => {
			console.error(error)
		}
	)
	return res
}

export interface Comment {
	commentId: string
	userId: string
	comment: string
	storyId: string
	userName: string
	userImage: string | null
}
export interface CommentsResponse {
	next: number
	previous: number
	page: number
	records: number
	count_page: number
	data: Comment[]
}

export const getComments = async (
	page: number,
	storyId: string
): Promise<CommentsResponse | null> => {
	let res = null as CommentsResponse | null
	await axios
		.get(`${BaseURL}/stories/${storyId}/comments?page=${page}&records=10`, {
			headers: {
				Authorization: `Bearer ${token.get('token')}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export interface StoryEventResponse {
	userStatus: string
	stories: StoryProbly[]
}

export const getStoryEvent = async (
	eventId: string
): Promise<StoryEventResponse | null> => {
	let res = null as StoryEventResponse | null
	const accessToken = token.get('token')
	const config = accessToken
		? {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
		: {}
	await axios.get(`${BaseURL}/stories/${eventId}`, config).then(
		async response => {
			res = response.data
		},
		error => {
			console.error(error)
		}
	)
	return res
}

export interface StoriesPollResponse {
	poll_response: {
		sportId: string
		startTime: string
		probability: string
		createAt: string
		userId: string
		agree: 'Yes' | 'No' | null
		pollResponseId: string
		cutoffTime: string
		leagueId: string
		description: string
		storyId: string
	}[]
	message?: string
}

export const getStoriesPollResponse =
	async (): Promise<StoriesPollResponse | null> => {
		let res = null as StoriesPollResponse | null
		await axios
			.get(`${BaseURL}/stories/poll-response`, {
				headers: {
					Authorization: `Bearer ${token.get('token')}`
				}
			})
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
		return res
	}
