import { BaseURL, BaseURLv2 } from 'api'
import axios from 'axios'
import { LastEvaluatedKeys } from 'interfaces'

export interface EventsResponse {
	count: number
	count_page: number
	data: {
		eventId: string
		logoUrl: string
		sportId: string
		sportName: string
		leagueId: string
		leagueName: string
		vs: string
		startTime: Date
		oddsJamEventId: string
	}[]
	page: number
	next: number | null
	previous: number | null
	records: number
}

export type EventsByLastKeyResponse = {
	LastEvaluatedKeys?: LastEvaluatedKeys
	events: EventByKey[]
}

export type EventByKey = {
	sportId: string
	sportName: string
	startTime: string
	eventId: string
	isLocked: string
	homeTeam: string
	leagueId: string
	id: string
	awayTeam: string
	oddsJamEventId: string
	leagueName: string
	logoUrl: string
}

export const getEventsByLastKey = async (options?: {
	sportID?: string
	leagueID?: string
	search?: string
	lastEvaluatedKeys?: LastEvaluatedKeys
}): Promise<EventsByLastKeyResponse> => {
	return (
		await axios.post(
			`${BaseURLv2}/events`,
			{
				LastEvaluatedKeys: options?.lastEvaluatedKeys
			},
			{
				params: {
					sport_id: options?.sportID || 'all',
					league_id: options?.leagueID,
					search: options?.search
				},
				timeout: 120000
			}
		)
	).data
}

export const getEvents = async (
	page: number,
	sportID: string,
	leagueID: string,
	search: string
): Promise<EventsResponse | null> => {
	let res = null as EventsResponse | null
	await axios
		.get(
			`${BaseURL}/events?page=${page}&records=10${
				sportID && sportID !== '' ? `&sportId=${sportID}` : ''
			}${leagueID ? `&leagueId=${leagueID}` : ''}${
				search ? `&search=${search}` : ''
			}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
