/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { XIcon } from '@heroicons/react/outline'
import { t } from '@lingui/macro'
import {
	GET_COMMENTS,
	GET_STORIES,
	GET_STORIES_V2,
	StoriesResponseV2,
	getComments,
	postComment
} from 'api'
import clsx from 'clsx'
import { Icon, Loading } from 'components'
import { useStore } from 'stores'
import { useToast } from 'hooks'
import { Icons } from 'interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import {
	InfiniteData,
	useInfiniteQuery,
	useMutation,
	useQueryClient
} from 'react-query'
import { CommentBox } from '../box/comment/comment-box'

export interface DrawerCommentsProps {
	storyId: string
	onClose: (val: boolean) => void
}

export type FormInputComment = {
	comment: string
}

export interface CommentsType {
	commentId: string
	userId: string
	comment: string
	storyId: string
	userName: string
	userImage: string | null
}

export const DrawerComments: React.FC<DrawerCommentsProps> = observer(
	({ storyId, onClose }): JSX.Element => {
		const MAX_COMMENT_LENGHT = 70
		const { showToast } = useToast()
		const { ref, inView } = useInView()
		const { storyFilterFormStore, authStore, userStore } = useStore()
		const { userId, userName } = userStore.user
		const { register, handleSubmit, reset } = useForm<FormInputComment>()
		const [comments, setComments] = useState<CommentsType[]>([])
		const [isLoadingComment, setIsLoadingComment] = useState(false)
		const queryClient = useQueryClient()
		const finalToken = authStore.token
		const { sportID, leagueID, filterFormInputs } = storyFilterFormStore

		const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch } =
			useInfiniteQuery(
				[GET_COMMENTS, storyId],
				({ pageParam = 1 }) => getComments(pageParam, storyId),
				{
					cacheTime: 30 * 1000, // 30 seconds
					getNextPageParam: lastPage =>
						lastPage?.next ? lastPage.next : undefined
				}
			)

		useEffect(() => {
			if (data?.pages) {
				let auxData = [] as CommentsType[]
				data.pages.forEach(pagex => {
					const auxRes = pagex?.data
					if (auxRes) auxData = [...auxData, ...auxRes]
				})
				setComments(auxData)
			}
		}, [data])

		useEffect(() => {
			if (inView) fetchNextPage()
		}, [inView])

		const mutationSave = useMutation(postComment, {
			onMutate: async () => {
				await queryClient.cancelQueries([GET_STORIES_V2])
				const previousStories = queryClient.getQueryData<
					InfiniteData<StoriesResponseV2> | undefined
				>([
					GET_STORIES_V2,
					{ token: finalToken, sportID, leagueID, ...filterFormInputs }
				])
				const auxData = previousStories?.pages.map(page => {
					return {
						...page,
						data: page.data.map(story =>
							story.storyId === storyId
								? { ...story, comments: comments.length + 1 }
								: story
						)
					}
				})
				const finalData = {
					...previousStories,
					pages: auxData
				}
				queryClient.setQueryData(
					[
						GET_STORIES_V2,
						{ token: finalToken, sportID, leagueID, ...filterFormInputs }
					],
					finalData
				)
				return { finalData }
			},
			// eslint-disable-next-line
		onError: (_err, _user, context: any) => {
				queryClient.setQueryData(
					[
						GET_STORIES_V2,
						{ token: finalToken, sportID, leagueID, ...filterFormInputs }
					],
					context?.previousStories
				)
				showToast('Save', 'Error: comment Story', 'error')
			},
			onSuccess: () => {
				showToast('Comment', 'Comment successfully', 'success')
				setIsLoadingComment(false)
				refetch()
				reset()
			},
			onSettled: () => {
				queryClient.invalidateQueries([
					GET_STORIES,
					finalToken,
					filterFormInputs,
					sportID,
					leagueID
				])
			}
		})

		const validateComment = (comment: string): string | null => {
			if (comment.trim().length === 0) {
				return t`Comments cannot be empty`
			}
			if (comment.length > MAX_COMMENT_LENGHT) {
				return t`Comments must have a maximum of ${MAX_COMMENT_LENGHT} characters`
			}
			return null
		}

		const onSubmit: SubmitHandler<FormInputComment> = async datax => {
			setIsLoadingComment(true)
			const error = validateComment(datax.comment)
			if (error) {
				showToast(t`Invalid comment`, error, 'warning')
				setIsLoadingComment(false)
				return
			}
			const body = {
				storyId,
				userId,
				comment: datax.comment
			}
			mutationSave.mutate(body)
		}

		return (
			<div className="w-full h-full max-h-[100dvh] flex flex-col">
				{isLoading && (
					<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				)}
				<div className="w-full h-6 flex justify-end items-center">
					<button
						type="button"
						className={clsx('text-neutral-200')}
						onClick={() => onClose(false)}
					>
						<span className="sr-only">Close panel</span>
						<XIcon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div className="w-full flex-1 max-h-[calc(100dvh-88px)] overflow-hidden overflow-y-scroll hide-scroll-bar">
					{comments.map(comment => (
						<CommentBox key={comment.commentId} {...comment} />
					))}
					<div ref={ref}>
						{isFetchingNextPage && (
							<div className="w-full flex justify-center items-center p-3 bg-transparent">
								<Loading size="x-small" version="v2" />
							</div>
						)}
					</div>
				</div>
				{isLoadingComment && (
					<div className="w-full flex justify-center items-center p-3 bg-transparent">
						<Loading size="x-small" version="v2" />
					</div>
				)}
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="w-full h-10 flex gap-1">
						<div className="w-10 h-full flex justify-center items-center p-[5px]">
							<div className="w-full h-full rounded-full bg-transparent border-2 border-white-200 flex justify-center items-center">
								<span className="text-xl leading-10 font-bold text-primary-500">
									{userName?.split('')[0]?.toUpperCase()}
								</span>
							</div>
						</div>
						<div className="flex-1 flex justify-center items-center py-[5px]">
							<div
								className={clsx(
									'w-full h-8 flex gap-3 items-center px-[10px] py-1',
									'bg-neutral-500 rounded'
								)}
							>
								<input
									type="text"
									placeholder="Comment here . . ."
									autoComplete="off"
									className={clsx(
										'w-full h-full bg-transparent appearance-none focus:outline-none text-[12px] leading-[18px] font-normal text-white-200',
										'placeholder:text-[12px] placeholder:leading-[18px] placeholder:text-white-200/30'
									)}
									maxLength={MAX_COMMENT_LENGHT}
									disabled={isLoadingComment}
									{...register('comment')}
								/>
								<button
									type="submit"
									aria-label="Submit"
									className="w-6 h-6 cursor-pointer"
									disabled={isLoadingComment}
								>
									<Icon
										src={Icons.send}
										fillPath
										className={clsx(
											'w-full h-full',
											isLoadingComment
												? 'text-primary-500/40'
												: 'text-primary-500'
										)}
									/>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
)
