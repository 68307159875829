/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'
import ReactGA from 'react-ga4'
import { observer } from 'mobx-react'
import moment from 'moment'
import { GET_STORIES_V2, getIndividualStory } from 'api'
import clsx from 'clsx'
import { LayoutApp, Loading, PosterBox } from 'components'
import { useStore } from 'stores'
import styles from './styles.module.scss'

export const Home = observer((): JSX.Element => {
	const { authStore, storyFilterFormStore } = useStore()
	const finalToken = authStore.token
	const { sportID, leagueID, setStorySlided, filterFormInputs, isFiltered } =
		storyFilterFormStore
	const { ref, inView } = useInView()
	const { ref: loadingRef, inView: loadingInView } = useInView()
	const storiesContainer = useRef<HTMLDivElement>(null)
	const prevFilters = useRef({
		sportID,
		leagueID,
		...filterFormInputs
	})

	const {
		data: storiesProbly,
		isLoading,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage
	} = useInfiniteQuery(
		[
			GET_STORIES_V2,
			{ token: finalToken, sportID, leagueID, isFiltered, ...filterFormInputs }
		],
		({ pageParam = undefined }) =>
			getIndividualStory({
				accessToken: finalToken,
				sportID,
				leagueID,
				limit: isFiltered ? 10 : 5,
				lastEvaluatedKeys: pageParam,
				isFiltered,
				...filterFormInputs
			}),
		{
			cacheTime: 60 * 1000,
			getNextPageParam: lastStory => lastStory.LastEvaluatedKeys,
			refetchOnWindowFocus: false
		}
	)

	const filtersChanged = (): boolean => {
		return (
			prevFilters.current.sportID !== sportID ||
			prevFilters.current.probabilityMax !== filterFormInputs.probabilityMax ||
			prevFilters.current.leagueID !== leagueID ||
			prevFilters.current.probabilityMin !== filterFormInputs.probabilityMin ||
			prevFilters.current.isLive !== filterFormInputs.isLive ||
			prevFilters.current.lastUpdatedMax !== filterFormInputs.lastUpdatedMax ||
			prevFilters.current.problyScoreMin !== filterFormInputs.problyScoreMin
		)
	}

	if (inView && !isFetchingNextPage && !isLoading) fetchNextPage()

	useEffect(() => {
		if (filtersChanged()) {
			const { current } = storiesContainer
			if (current) current.scrollTop = 0
			prevFilters.current = {
				sportID,
				leagueID,
				...filterFormInputs
			}
		}
	}, [storiesProbly, filterFormInputs, isFiltered])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Home'
		})
	}, [])

	return (
		<LayoutApp header footer isLoading={isLoading}>
			<div
				ref={storiesContainer}
				className={clsx(
					!loadingInView && styles.containerHome,
					'relative h-[calc(100vh-191px)] overflow-hidden overflow-y-scroll hide-scroll-bar'
				)}
			>
				{storiesProbly?.pages
					.flatMap(page => page.data)
					.map(post => (
						<PosterBox
							key={post.storyId}
							post={{
								...post,
								vs: `${post?.homeTeam || ''} vs ${post?.awayTeam || ''}`,
								startTime: moment(post?.startTime).toDate()
							}}
							onSlide={(storyId, goodLookSlide) => {
								setStorySlided(goodLookSlide ? storyId : '')
							}}
						/>
					))}
				{hasNextPage && (
					<div ref={ref} style={{ minHeight: 50 }}>
						{isFetchingNextPage && (
							<div
								className="w-full flex justify-center items-center p-3 bg-transparent"
								ref={loadingRef}
							>
								<Loading size="x-small" version="v2" />
							</div>
						)}
					</div>
				)}
			</div>
		</LayoutApp>
	)
})
