/* eslint-disable max-len */
import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { t } from '@lingui/macro'
import { HeaderProfile, LayoutApp } from 'components'
import { Icons } from 'interfaces'
import './terms.scss'
import Cookies from 'universal-cookie'

export const Terms: React.FC = () => {
	const token = new Cookies()
	const title = t`Terms of Service`

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Terms and Conditions'
		})
	}, [])

	return (
		<LayoutApp desktop>
			<>
				<HeaderProfile
					title="Terms and conditions"
					icon={Icons.arrowBackward}
					to={token.get('token') ? '/profile' : '/register'}
				/>
				<div className="w-full h-[calc(100dvh-54px)] flex flex-col items-center gap-6 px-4 py-6 bg-black-500 overflow-hidden overflow-y-scroll hide-scroll-bar">
					<iframe
						width="100%"
						height="500"
						title={title}
						src="https://www.termsfeed.com/live/6405ee42-7f61-40cf-bae8-d99d338acf9b"
					/>
				</div>
			</>
		</LayoutApp>
	)
}
