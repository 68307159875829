import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { BetSurveyModal, DrawerSignin, Icon, Typography } from 'components'
import { ScoresExplanationModal } from 'components/modal/scoresExplanationModal'
import { Icons, TrackingEventsType } from 'interfaces'
import { useStore } from 'stores'
import { useCountdown } from 'hooks'
import styles from './tracking-event-box.module.scss'

export const TrackingEventsBox: React.FC<TrackingEventsType> = observer(
	({ event, onVote }) => {
		const {
			storyId,
			marketDescription,
			probability,
			problyScore,
			sportsBook,
			startTime,
			betOdds,
			homeTeam,
			awayTeam
		} = event
		const startEvent = new Date(startTime)
		const navigate = useNavigate()
		const location = useLocation()
		const { authStore, userStore } = useStore()
		const { user } = userStore
		const [view, setView] = useState<'info' | 'survey'>('info')
		const [showDrawer, setShowDrawer] = useState<boolean>(false)
		const [isExtendedTitleView, setIsExtendedTitleView] =
			useState<boolean>(false)
		const cardDescriptionRef = useRef<HTMLParagraphElement>(null)
		const [cardDescriptionIsTooLarge, setCardDescriptionIsTooLarge] =
			useState<boolean>(false)
		const [isScoresModalOpen, setIsScoresModalOpen] = useState<boolean>(false)
		const [scoresAgree, setScoresAgree] = useState<string>('neutral')
		const [isLive, setIsLive] = useState<boolean>(false)
		const { seconds } = useCountdown(startEvent)
		const oddSign = parseFloat(betOdds || '') >= 0 ? '+' : ''

		const isUnlocked =
			(isLive &&
				user.subscriptionStatus === 'active' &&
				user.subscriptionPlanId?.includes('live')) ||
			!isLive

		useEffect(() => {
			const { current: text } = cardDescriptionRef
			setCardDescriptionIsTooLarge(
				text ? text.scrollHeight > text.clientHeight : false
			)
		}, [cardDescriptionRef])

		const handleOpenScoresModalClick: React.MouseEventHandler = (): void => {
			setIsScoresModalOpen(true)
		}

		const handleOpenScoresModalKeyDown: React.KeyboardEventHandler = (
			evt: React.KeyboardEvent
		): void => {
			if (evt.key === 'Enter') setIsScoresModalOpen(true)
		}

		const handleLockClick = (): void => {
			if (!authStore.token) {
				authStore.showSignInPopUp()
			} else {
				navigate('/subscribe', { state: { from: location } })
			}
		}

		const handleLockKeyDown: React.KeyboardEventHandler = (
			evt: React.KeyboardEvent
		): void => {
			if (evt.key === 'Enter') {
				handleLockClick()
			}
		}

		const handleBetDescriptionClick: React.MouseEventHandler = (): void => {
			if (cardDescriptionIsTooLarge) setIsExtendedTitleView(prev => !prev)
			navigate(`/market/${storyId}`, { state: { from: location } })
		}

		const handleBetDescriptionKeyDown: React.KeyboardEventHandler = (
			evt: React.KeyboardEvent
		): void => {
			if (evt.key === 'Enter') {
				if (cardDescriptionIsTooLarge) setIsExtendedTitleView(prev => !prev)
				navigate(`/market/${storyId}`, { state: { from: location } })
			}
		}

		const handleBetNowClick: React.MouseEventHandler = (): void => {
			if (!isUnlocked) {
				handleLockClick()
				return
			}
			setScoresAgree('yes')
			setIsScoresModalOpen(true)
		}
		const handleBetNowKeyDown: React.KeyboardEventHandler = (
			evt: React.KeyboardEvent
		): void => {
			if (evt.key === 'Enter') {
				setScoresAgree('yes')
				setIsScoresModalOpen(true)
			}
		}

		useEffect(() => {
			const startDateTime = startEvent.getTime()
			if (startDateTime <= Date.now()) {
				if (
					dayjs(startEvent)?.add(3, 'hours')?.toDate()?.getTime() < Date.now()
				) {
					setIsLive(false)
				} else {
					setIsLive(true)
				}
			}

			return () => {
				setIsLive(false)
			}
		}, [seconds])

		return (
			<div className="flex w-full h-auto">
				{view === 'info' && (
					<div className="flex justify-center items-center w-full h-full min-h-[5rem]">
						{/* <a
						href={url}
						target="_blank"
						rel="noreferrer"
						className="relative flex justify-center items-center text-center bg-danger-500 basis-[32px] rounded-l-[16px] h-full max-w-[32px]"
					> */}
						{/* Replace div with link once links are up */}
						<div className="relative flex justify-center items-center text-center bg-neutral-1000 basis-[16px] rounded-l-[16px] h-full max-w-[16px]">
							<Typography
								type="label-medium"
								// label={t`Bet Against`}
								label=""
								className="text-white-100 text-[11px] whitespace-nowrap my-auto transform rotate-[270deg]"
							/>
						</div>
						{/* </a> */}
						<div className="bg-neutral-1000 w-full h-full px-3 py-2">
							<div className="flex-auto flex justify-between items-center h-full w-full">
								<div className="basis-[65%] flex flex-col justify-between h-full">
									<button
										className="h-full overflow-y-auto"
										type="button"
										onClick={handleBetDescriptionClick}
										onKeyDown={handleBetDescriptionKeyDown}
									>
										<Typography
											ref={cardDescriptionRef}
											type="label-medium"
											label={`${marketDescription} - ${homeTeam} vs ${awayTeam}`}
											className={clsx(
												'text-white-100 overflow-hidden text-left',
												!isExtendedTitleView && 'line-clamp-3'
											)}
										/>
									</button>
								</div>
								{isUnlocked ? (
									<div
										className={clsx(
											'basis-[23%] text-right',
											styles.scoresContainer
										)}
										onClick={handleOpenScoresModalClick}
										onKeyDown={handleOpenScoresModalKeyDown}
										aria-hidden="true"
									>
										<Typography
											type="headline-medium"
											label={`${Math.round(probability)}%`}
											className="text-primary-500 !font-bold"
										/>
										<Typography
											type="label-xsmall"
											label={
												'PROBLY ' +
												`Score:  ${
													parseFloat(problyScore.toString() || '')
														?.toFixed(1)
														?.toString() || 'N/A'
												}`
											}
											className="text-neutral-50 opacity-60"
										/>
										<Typography
											type="label-xsmall"
											label={`${t`Best Bet:`} ${
												sportsBook || ''
											} ${oddSign}${parseFloat(betOdds || '')
												?.toFixed(0)
												.toString()}`}
											className="text-neutral-50 opacity-60"
										/>
									</div>
								) : (
									<div
										className={clsx(
											'basis-[13%] w-7 h-8',
											styles.scoresContainer
										)}
										onClick={handleLockClick}
										onKeyDown={handleLockKeyDown}
										aria-hidden="true"
									>
										<Icon
											src={Icons.lock}
											fillPath
											className="text-primary-500 font-bold"
										/>
									</div>
								)}
							</div>
						</div>
						{/* <a
						href={url}
						target="_blank"
						rel="noreferrer"
						className="relative flex text-center justify-center items-center bg-primary-500 basis-[32px] rounded-r-[16px] h-full max-w-[32px]"
					> */}
						<button
							type="button"
							onClick={handleBetNowClick}
							onKeyDown={handleBetNowKeyDown}
							className="relative flex text-center justify-center items-center bg-primary-500 basis-[32px] rounded-r-[16px] h-full max-w-[32px]"
						>
							<Typography
								type="label-medium"
								label={t`View Bet`}
								className="text-white-100 text-[11px] whitespace-nowrap my-auto transform rotate-[270deg]"
							/>
						</button>
						{/* </a> */}
						{showDrawer && <DrawerSignin onClose={setShowDrawer} />}
					</div>
				)}
				{view === 'survey' && (
					<BetSurveyModal
						storyId={storyId}
						post={event}
						wfull
						title="Do you Believe in this Probly?"
						setView={setView}
						onVote={onVote}
					/>
				)}
				{isUnlocked && (
					<ScoresExplanationModal
						agree={scoresAgree}
						story={event}
						isModalOpen={isScoresModalOpen}
						setIsModalOpen={setIsScoresModalOpen}
						onClose={() => setScoresAgree('neutral')}
					/>
				)}
			</div>
		)
	}
)
