import { getProfile, GET_PROFILE } from 'api'
import { AuthContext } from 'context'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useStore } from 'stores'
import Cookies from 'universal-cookie'

interface Props {
	children: JSX.Element
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
	const { userStore } = useStore()
	const [accessToken, setAccessToken] = useState<string | undefined>()
	const [userID, setUserID] = useState('')
	const [userName, setUserName] = useState('')
	const cookies = new Cookies()
	const token = cookies.get('token')
	const { accessToken: accessToken2 } = useContext(AuthContext)
	const finalToken = (accessToken2 || token) as string

	const { data } = useQuery([GET_PROFILE, finalToken], () =>
		getProfile(finalToken)
	)

	useEffect(() => {
		if (data) {
			setUserID(data.user.userId)
			setUserName(data.user.userName)
			userStore.setUser(data?.user)
		}
	}, [data])

	const authState = useMemo(
		() => ({
			accessToken,
			setAccessToken,
			userID,
			userName
		}),
		[accessToken, setAccessToken, userID, userName]
	)

	return (
		<AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
	)
}
