/* eslint-disable react/require-default-props */
import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { Icons } from 'interfaces'
import React, { useState } from 'react'

export interface DropdownProps {
	label: string
	hideView?: boolean
	border?: boolean
	className?: string
	profile?: boolean
	children?: JSX.Element
	initialOpen?: boolean
	// index?: number
	// oneItem?: boolean
}

export const Dropdown: React.FC<DropdownProps> = ({
	label,
	hideView = false,
	border = false,
	className,
	profile = false,
	initialOpen = false,
	// index,
	// oneItem,
	children
}): JSX.Element => {
	const [open, setOpen] = useState(initialOpen)
	return (
		<>
			<div
				className={clsx(
					'w-full h-12 flex justify-between items-center',
					border ? 'border-b border-b-neutral-200/30' : 'border-none',
					className,
					// (index === 0 || oneItem) && 'border-t border-t-neutral-200',
					open && 'border-b-0',
					profile ? 'bg-neutral-500/0' : 'bg-neutral-500'
				)}
				aria-hidden
				onClick={() => setOpen(!open)}
				style={{
					minHeight: '48px'
				}}
			>
				<Typography
					type="label-large"
					label={label}
					className="text-neutral-200"
				/>
				{hideView ? (
					<div className="flex items-center gap-2">
						<Typography
							type="label-medium"
							label={open ? 'Hide' : 'View'}
							className="text-primary-500"
						/>
						<div className="flex items-center justify-center w-6 h-6">
							<Icon
								src={open ? Icons.arrowDropUp : Icons.arrowDropDown}
								fillPath
								className={clsx('text-primary-500')}
							/>
						</div>
					</div>
				) : (
					<div className="w-6 h-6">
						<Icon
							src={open ? Icons.arrowDropUp : Icons.arrowDropDown}
							fillPath
							className={clsx(
								profile ? 'text-primary-500' : 'text-neutral-100'
							)}
						/>
					</div>
				)}
			</div>
			{open && (
				<div className="border-b border-b-neutral-200/30">{children}</div>
			)}
		</>
	)
}
