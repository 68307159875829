/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
// import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import {
	Footer,
	Header,
	Loading
	// AppleSignUpInterstitial,
	// WebSignUpInterstitial
} from 'components'
import { Images, LayoutAppProps } from 'interfaces'
import { useStore } from 'stores'

export const LayoutApp: React.FC<LayoutAppProps> = observer(
	({
		children,
		header = false,
		footer = false,
		desktop = false,
		isLoading = false,
		headerSpacing = false
	}): JSX.Element => {
		const location = useLocation()
		const {
			// adStore,
			// userStore,
			appleIAPStore
		} = useStore()

		useEffect(() => {
			appleIAPStore.initialize()
			// Time delay is either 15 seconds or 5 minutes
			// const adTimeDelayMs = adStore.hasShownAd ? 60000 * 5 : 15000
			// const adTimerId = setTimeout(() => {
			// 	if (!userStore.isSubscribed) {
			// 		if (appleIAPStore.isIOSIAPCapable) {
			// 			adStore.showAppleInterstitial()
			// 		} else {
			// 			adStore.showWebInterstitial()
			// 		}
			// 		adStore.setHasShownAd(true)
			// 	}
			// }, adTimeDelayMs)

			// return () => {
			// 	clearTimeout(adTimerId)
			// }
		}, [])
		return (
			<div
				id="container"
				className="w-screen min-h-screen max-h-screen flex flex-col justify-start items-center overflow-hidden sm:bg-black-400 bg-neutral-500"
			>
				{header && (
					<header className="w-full xs:w-full lg:w-[390px] h-[122px] bg-neutral-500">
						<Header />
					</header>
				)}
				{headerSpacing && (
					<header className="w-full xs:w-full lg:w-[390px] h-[122px] bg-neutral-500 flex justify-center items-center">
						<Link to="/home" state={{ from: location }}>
							<img src={Images.logoProblyName} alt="logo" width={160} />
						</Link>
					</header>
				)}
				<main
					className={clsx(
						'w-full xs:w-full lg:w-[390px] flex-1 overflow-hidden',
						{
							'xs:absolute xs:left-1/2 xs:-translate-x-1/2 xs:bg-black-500':
								desktop
						},
						{ 'max-h-[calc(100vh-191px)] xs:h-screen': header && footer },
						{ 'max-h-[calc(100vh-69px)] xs:h-screen': !header && footer },
						{ 'max-h-[calc(100vh-122px)] xs:h-screen': header && !footer },
						{
							'max-h-screen xs:h-screen': !header && !footer
						}
					)}
				>
					{isLoading && (
						<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
							<Loading size="large" />
						</div>
					)}
					{children}
				</main>
				{footer && (
					<footer
						className={clsx(
							'fixed bottom-0 w-full xs:w-full lg:w-[390px] h-[69px]'
						)}
					>
						<Footer />
					</footer>
				)}
				{/* {!location.pathname?.includes('start') &&
					!location.pathname?.includes('signin') &&
					!location.pathname?.includes('register') &&
					adStore.shouldShowAppleInterstitial && <AppleSignUpInterstitial />}
				{!location.pathname?.includes('start') &&
					!location.pathname?.includes('signin') &&
					!location.pathname?.includes('register') &&
					adStore.shouldShowWebInterstitial && <WebSignUpInterstitial />} */}
			</div>
		)
	}
)
