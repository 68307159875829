import { createContext } from 'react'

export const AuthContext = createContext<{
	accessToken: string | undefined
	setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>
	userID: string
	userName: string
}>({
	accessToken: '',
	setAccessToken: () => null,
	userID: '',
	userName: ''
})
