export const GET_CHARACTERS = 'GET_CHARACTERS'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_TERMS = 'GET_TERMS'
export const GET_FAQS = 'GET_FAQS'
export const GET_IMAGE = 'GET_IMAGE'
export const GET_LEAGUES = 'GET_LEAGUES'
export const GET_SPORTS = 'GET_SPORTS'
export const GET_EVENTS_BY_LAST_KEY = 'GET_EVENTS_BY_LAST_KEY'
export const GET_EVENTS = 'GET_EVENTS'
export const GET_STORIES_V2 = 'GET_STORIES_V2'
export const GET_STORIES = 'GET_STORIES'
export const GET_STORY = 'GET_STORY'
export const GET_COMMENTS = 'GET_COMMENTS'
export const GET_TRACKED_EVENTS = 'GET_TRACKED_EVENTS'
export const GET_STORIES_EVENTS = 'GET_STORIES_EVENTS'
export const GET_HISTORY_STORIES = 'GET_HISTORY_STORIES'
export const CREATE_APPLE_SUBSCRIPTION = 'CREATE_APPLE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
