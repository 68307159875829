import { useCountdownType } from 'interfaces'
import { useEffect, useState } from 'react'

export const useCountdown = (deadline: Date): useCountdownType => {
	const calculateTime = (): useCountdownType => {
		const time = deadline.getTime() - new Date().getTime()
		return {
			days: Math.floor(time / (1000 * 60 * 60 * 24)),
			hours: Math.floor((time / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((time / 1000 / 60) % 60),
			seconds: Math.floor((time / 1000) % 60)
		}
	}

	const [datetimeData, setDatetimeData] =
		useState<useCountdownType>(calculateTime())

	useEffect(() => {
		const timeout = setInterval(() => {
			setDatetimeData(calculateTime())
		}, 1000)

		return () => {
			clearInterval(timeout)
		}
	}, [datetimeData])

	return { ...datetimeData }
}
