import React from 'react'
import { LayoutApp } from 'components'
import { Images } from 'interfaces'

export const SplashLoading: React.FC = (): JSX.Element => {
	return (
		<LayoutApp desktop>
			<div className="w-full h-screen flex-1 flex justify-center items-center bg-black-500">
				<img
					src={Images?.splash}
					alt="logo splash"
					className="w-[172px] h-[76px]"
				/>
			</div>
		</LayoutApp>
	)
}
