import { BaseURL } from 'api'
import axios from 'axios'
import { CodePhone } from 'components'
import Cookies from 'universal-cookie'

export interface UpdateProfileResponse {
	message: string
	statusCode?: number
	error?: string
}

export const updateProfile = async (body: {
	email: string | null
	phone: number | null
	userName: string | null
	code: string | null
}): Promise<UpdateProfileResponse | null> => {
	const token = new Cookies()
	let res = null as UpdateProfileResponse | null
	await axios
		.patch(
			`${BaseURL}/user/profile`,
			{
				email: body.email,
				phone: body.phone,
				userName: body.userName,
				code: CodePhone
			},
			{
				headers: {
					Authorization: `Bearer ${token.get('token')}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)
	return res
}

export const updateProfileAvatar = async (body: {
	avatarUrl: string
}): Promise<UpdateProfileResponse | null> => {
	const token = new Cookies()
	let res = null as UpdateProfileResponse | null
	await axios
		.patch(
			`${BaseURL}/user/profile`,
			{
				avatarUrl: body.avatarUrl
			},
			{
				headers: {
					Authorization: `Bearer ${token.get('token')}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)
	return res
}
