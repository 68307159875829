import { t } from '@lingui/macro'
import {
	GET_STORIES_EVENTS,
	PollResponse,
	StoryEventResponse,
	StoryProbly,
	getStoryEvent
} from 'api'
import clsx from 'clsx'
import { Icon, Loading, Typography } from 'components'
import { useCountdown } from 'hooks'
import { ExploreBoxType, Icons, IconsType } from 'interfaces'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { TrackingEventsBox } from '..'

export const ExploreBox: React.FC<ExploreBoxType> = ({
	// eventId,
	logoUrl,
	startTime,
	leagueName,
	sportName,
	vs,
	oddsJamEventId: eventId
}) => {
	const [open, setOpen] = useState(false)
	const [finished] = useState(false)
	const [storiesEvents, setStoriesEvents] = useState<StoryProbly[]>([])
	const isLive = (): boolean => moment.utc().isAfter(moment.utc(startTime))

	const queryClient = useQueryClient()

	const { days, hours, minutes, seconds } = useCountdown(
		moment.utc(startTime).toDate()
	)

	const timeLeftFormated = (): string => {
		const hoursClamp = hours < 0 ? 0 : hours
		const minutesClamp = minutes < 0 ? 0 : minutes
		const secondsClamp = seconds < 0 ? 0 : seconds
		return moment({
			hours: hoursClamp,
			minutes: minutesClamp,
			seconds: secondsClamp
		}).format('HH:mm:ss')
	}

	const {
		data,
		isLoading,
		refetch: loadBets
	} = useQuery([GET_STORIES_EVENTS, eventId], () => getStoryEvent(eventId), {
		enabled: false,
		cacheTime: 60 * 1000
	})

	const toogleBetsView = async (): Promise<void> => {
		setOpen(!open)
		if (!open) loadBets()
	}

	const refreshCache = (storyId: string, vote: PollResponse | null): void => {
		if (!vote) return
		queryClient.setQueryData<StoryEventResponse | null>(
			[GET_STORIES_EVENTS, eventId],
			event => {
				if (!event) return null
				const updatedStories = event.stories.map(story => {
					if (story.storyId !== storyId) return story
					return {
						...story,
						agree: vote.agree,
						yesPercentage: vote.yesPercentage
					}
				})
				return {
					...event,
					stories: updatedStories
				}
			}
		)
	}

	useEffect(() => {
		if (data) setStoriesEvents(data.stories)
	}, [data])

	return (
		<>
			<div
				className={clsx(
					'flex flex-col gap-2 py-4',
					open ? 'border-b-0' : 'border-b border-b-neutral-200/30'
				)}
				aria-hidden
				onClick={toogleBetsView}
			>
				<div className="flex justify-between">
					<div className="flex flex-col">
						<div className="flex items-center gap-2">
							<div className="w-[14px] h-[16px] flex items-center justify-center">
								<Icon
									src={logoUrl as IconsType}
									fillPath
									className="w-[12px] h-[12px] text-primary-500"
								/>
							</div>
							<Typography
								type="label-medium"
								label={sportName}
								className="text-neutral-200"
							/>
						</div>
						<div className="flex gap-2">
							<div className="w-[14px] h-[16px] flex items-center justify-center">
								<Icon
									src={Icons.medal}
									fillPath
									className="w-[12px] h-[12px] text-primary-500"
								/>
							</div>
							<Typography
								type="label-medium"
								label={leagueName}
								className="text-neutral-200"
							/>
						</div>
					</div>
					<div className="text-[4px] text-white-100">
						{isLive() ? (
							<div className="flex gap-2 items-center">
								<div className="w-3 h-3">
									<Icon
										src={Icons.rocketLaunch}
										fillPath
										className="text-neutral-200"
									/>
								</div>
								<Typography
									type="label-medium"
									label={t`Live`}
									className="text-neutral-200"
								/>
							</div>
						) : finished ? (
							<div className="flex gap-2 items-center">
								<div className="w-3 h-3">
									<Icon
										src={Icons.check}
										fillPath
										className="text-neutral-200"
									/>
								</div>
								<Typography
									type="label-medium"
									label={t`Finished`}
									className="text-neutral-200"
								/>
							</div>
						) : days > 0 ? (
							<div className="flex gap-2 items-center">
								<div className="w-3 h-3">
									<Icon
										src={Icons.schedule}
										fillPath
										className="text-neutral-200"
									/>
								</div>
								<Typography
									type="label-medium"
									label={t`Soon`}
									className="text-neutral-200"
								/>
							</div>
						) : (
							<div className="flex gap-2 items-center">
								<div className="w-3 h-3">
									<Icon
										src={Icons.schedule}
										fillPath
										className="text-neutral-200"
									/>
								</div>
								<Typography
									type="label-medium"
									label={`${t`Starts in`} ${timeLeftFormated()}`}
									className="text-neutral-200 whitespace-nowrap"
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between items-center">
					<Typography
						type="headline-xxsmall"
						label={vs}
						className="text-white-200 mr-2"
					/>
					<div className="flex items-center gap-2 cursor-pointer">
						<Typography
							type="label-medium"
							label={open ? t`Hide` : t`View`}
							className="text-primary-500"
						/>
						<div className="flex items-center justify-center w-6 h-6">
							<Icon
								src={open ? Icons.arrowDropUp : Icons.arrowDropDown}
								fillPath
								className={clsx('text-primary-500')}
							/>
						</div>
					</div>
				</div>
			</div>
			{open &&
				(isLoading ? (
					<div className="w-full flex justify-center items-center p-1 bg-transparent">
						<Loading size="x-small" version="v2" />
					</div>
				) : (
					<div className="flex flex-col gap-4 w-full pb-4 border-b border-b-neutral-200">
						<Typography
							type="label-medium"
							label={`${storiesEvents.length} ${t`bet opportunities`}`}
							className="text-primary-500"
						/>
						{storiesEvents.map(item => (
							<TrackingEventsBox
								key={item.storyId}
								event={item}
								onVote={vote => refreshCache(item.storyId, vote)}
							/>
						))}
					</div>
				))}
		</>
	)
}
