import { useNavigate } from 'react-router-dom'
import { useStore } from 'stores'

export interface useAuthProps {
	// auth: boolean
	signOut: () => void
}

export const useAuth = (): useAuthProps => {
	const { userStore, authStore } = useStore()
	const navigate = useNavigate()

	const signOut = (): void => {
		authStore.signOut()
		userStore.reset()
		navigate('/')
	}

	return { signOut }
}
