import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { useClickOutside } from 'hooks'
import { Icons, IconsType } from 'interfaces'
import React, { useRef, useState } from 'react'

export interface CodePhoneProps {
	setCodePhone: React.Dispatch<React.SetStateAction<string>>
}
interface Item {
	id: string
	icon: IconsType
	code: string
}
const codePhones = [
	{
		id: 'usa-1',
		icon: Icons.usa,
		code: '+1'
	}
	// {
	// 	id: 'usa-2',
	// 	icon: Icons.usa,
	// 	code: '+2'
	// },
	// {
	// 	id: 'usa-3',
	// 	icon: Icons.usa,
	// 	code: '+3'
	// }
]

export const CodePhone: React.FC<CodePhoneProps> = ({
	setCodePhone
}): JSX.Element => {
	const divRef = useRef<HTMLDivElement | null>(null)
	const [showItems, setShowItems] = useState(false)
	const [itemSelected, setItemSelected] = useState<Item>({
		id: '',
		icon: Icons.usa,
		code: ''
	})
	useClickOutside(divRef, () => setShowItems(false))

	const handleClick = (item: Item): void => {
		setItemSelected(item)
		setCodePhone(item.code)
		console.warn('code-phone', item.code)
		setShowItems(false)
	}

	return (
		<div className="relative">
			<div
				aria-hidden
				onClick={() => setShowItems(true)}
				className="w-auto flex"
			>
				<div className="flex justify-center items-center w-6 h-6">
					<Icon src={itemSelected.icon} />
				</div>
				<div className="w-6 h-6">
					<Icon
						src={Icons.arrowDropDown}
						fillPath
						className={clsx(
							'text-neutral-100',
							showItems ? 'rotate-180' : 'rotate-0'
						)}
					/>
				</div>
			</div>
			{showItems && (
				<div
					ref={divRef}
					className="absolute top-7 -left-3 z-30 w-[84px] h-auto flex flex-col justify-start bg-black-500 rounded-lg border border-neutral-200/30"
				>
					{codePhones.map(item => (
						<div
							key={item.id}
							aria-hidden
							onClick={() => handleClick(item)}
							className="w-auto flex gap-4 border-b border-b-neutral-200/30 px-3 py-2"
						>
							<div className="flex justify-center items-center w-6 h-6">
								<Icon src={item.icon} />
							</div>
							<Typography
								type="body-large"
								label={item.code}
								className="text-neutral-200"
							/>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
