import { t } from '@lingui/macro'
import { Button, DrawerSignin, Typography } from 'components'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

export interface FooterRegisterLoginProps {
	type: string
	digitValidation: string
	onClick: () => void
}

export const FooterRegisterLogin: React.FC<FooterRegisterLoginProps> = ({
	type,
	digitValidation,
	onClick
}): JSX.Element => {
	const [showDrawer, setShowDrawer] = useState(false)
	const location = useLocation()
	return (
		<footer className="relative flex flex-col justify-end w-full h-[190px] p-4">
			{type === '/register' ? (
				<>
					<div className="w-full flex flex-col justify-center items-center">
						<Typography
							type="label-medium"
							label={t`At creating an account you are accepting our`}
							className="text-neutral-200"
						/>
						<Link to="/terms" target="_blank" rel="noopener noreferrer">
							<Typography
								type="label-medium"
								label={t`Terms and Conditions`}
								className="underline text-neutral-50"
							/>
						</Link>
					</div>
					<Button
						label={t`Register`}
						classNameDiv="!w-full py-4 mb-4 border-b-2 border-b-neutral-300"
						onClick={onClick}
						buttonLabelClassName="!text-[16px] !font-medium"
						disabled={!(digitValidation.length === 5)}
					/>
					<div className="w-full flex justify-center items-center gap-1">
						<Typography
							type="label-large"
							label={t`Already have an account?`}
							className="text-neutral-200"
						/>
						<Link to="/signin">
							<Typography
								type="label-large"
								label={t`Log In`}
								className="text-primary-500"
							/>
						</Link>
					</div>
					{showDrawer && <DrawerSignin onClose={setShowDrawer} />}
				</>
			) : (
				<>
					<Button
						label={t`Log In`}
						classNameDiv="!w-full"
						onClick={onClick}
						buttonLabelClassName="!text-[16px] !font-medium"
						disabled={!(digitValidation.length === 5)}
					/>
					<div className="w-full flex justify-center items-center gap-1 pt-4 mt-4 border-t-2 border-t-neutral-300 px-4">
						<Typography
							type="label-large"
							label={t`Don't have an account?`}
							className="text-neutral-200"
						/>
						<Link to="/register" state={{ from: location }}>
							<Typography
								type="label-large"
								label={t`Register`}
								className="text-primary-500"
							/>
						</Link>
					</div>
				</>
			)}
		</footer>
	)
}
