import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { isIOS } from 'react-device-detect'
import { useToast } from 'hooks'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import { observer } from 'mobx-react'
import { Button, LayoutApp, Loading, Typography } from 'components'
import { useStore } from 'stores'
import { DELETE_SUBSCRIPTION, cancelSubscription } from 'api'
import styles from './styles.module.scss'

export const SubscriptionCancel: React.FC = observer((): JSX.Element => {
	const { showToast } = useToast()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { userStore, appleIAPStore } = useStore()
	const { user } = userStore
	const { data, refetch } = useQuery(
		[DELETE_SUBSCRIPTION, user.userId],
		() => cancelSubscription(user.userId),
		{
			enabled: false // prevent query from automatically running on page load
		}
	)
	const handleCancelSubscription = async (): Promise<void> => {
		// User is an Apple subscriber who is trying to unsubscribe on either web or Android
		if (user.isAppleSubscriber) {
			showToast(
				t`Error`,
				`Apple ${t`Users: Cancel your subscription or trial by going to Settings on your iPhone. Then click your iCloud name >> Subscriptions. Click the Cancel button next to`} Probly.`,
				'error'
			)
			return
		}

		// User is a non-Apple subscriber who is on the iOS app and trying to unsubscribe
		if (!user.isAppleSubscriber && isIOS && appleIAPStore.isIOSIAPCapable) {
			showToast(
				t`Error`,
				// eslint-disable-next-line
				`${t`We're sorry. As a Stripe user, you cannot cancel your subscription directly inside the iOS app. To cancel, you must unsubscribe on the Probly website at`} probly.com/subscription/cancel`,
				'error'
			)
			return
		}

		setIsLoading(true)
		await refetch()
		setIsLoading(false)
	}
	const hasDeletedSubscription =
		!!data && !data.error && data?.message === 'cancelled'
	const unsubscribeTitle = user.isAppleSubscriber
		? `Apple ${t`Users`}`
		: t`Are you sure you want to cancel your subscription?`
	const title = hasDeletedSubscription
		? t`Your subscription has been cancelled, but it will remain active until the end of the billing cycle.`
		: unsubscribeTitle
	const unsubscribeMessage = user.isAppleSubscriber
		? ` ${t`Cancel your subscription or trial by going to Settings on your iPhone. Then click your iCloud name >> Subscriptions. Click the Cancel button next to`} Probly.`
		: t`Click Cancel to immediately unsubscribe from Probly`
	const content = hasDeletedSubscription
		? t`(We're sorry to see you go but we hope you'll come back.)`
		: unsubscribeMessage

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Subscription Cancel'
		})
	}, [])
	return (
		<LayoutApp footer headerSpacing isLoading={false}>
			<div
				className={clsx(
					styles.containerHome,
					'bg-neutral-500 relative h-[calc(100vh-69px)] overflow-hidden overflow-y-scroll hide-scroll-bar'
				)}
			>
				{isLoading ? (
					<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexFlow: 'column nowrap',
							alignItems: 'center',
							justifyItems: 'center',
							justifyContent: 'center',
							paddingTop: '4rem',
							paddingLeft: '2rem',
							paddingRight: '2rem',
							textAlign: 'center'
						}}
					>
						<Typography
							type="title-large"
							label={title}
							className="text-white-100"
						/>
						<br />
						<Typography
							type="title-medium"
							label={content}
							className="text-white-100"
						/>
						<br />
						{!hasDeletedSubscription && (
							<Button
								label={t`Cancel`}
								color="Primary"
								fill="transparent"
								size="small"
								className="group hover:bg-primary-500"
								buttonLabelClassName="text-primary-500 group-hover:text-white-100"
								onClick={handleCancelSubscription}
								disabled={user.isAppleSubscriber}
							/>
						)}
					</div>
				)}
			</div>
		</LayoutApp>
	)
})
