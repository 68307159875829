import React, { ChangeEvent, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { Slider } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import {
	BoxLock,
	Icon,
	Typography,
	CustomSwitch,
	CustomInput
} from 'components'
import { useStore } from 'stores'
import { DropdownOrganizeProps, Icons, StoryFilterInputs } from 'interfaces'
// import { useClickOutside } from 'hooks'
import { Sportsbooks } from 'util/constants'

type SliderMark = {
	value: number
	label?: string
}

type DropdownOption = {
	id: number | string
	icon: Icons
	label: string
	value: string
	lock: boolean
	status: false
}

const ITEM_HEIGHT = 28
const ITEM_PADDING_TOP = 4
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
			width: 175,
			backgroundColor: '#39393D',
			color: '#FFFFFF',
			overflow: 'scroll',
			zIndex: 500
		}
	}
}

export const DropdownOrganize: React.FC<DropdownOrganizeProps> = observer(
	({ profile = false }): JSX.Element => {
		const store = useStore()
		const { userStore, storyFilterFormStore } = store
		const { subscriptionStatus, subscriptionPlanId, isVerified } =
			userStore.user
		const isSubscribed = subscriptionStatus === 'active'
		const isAllAccessUser = isSubscribed && subscriptionPlanId?.includes('live')
		const {
			submitStoryFilterForm,
			clearStoryFilterForm,
			storySlided,
			isOrderLocked,
			isFiltered,
			filterFormInputs,
			isLastUpdatedFiltered,
			isLiveFiltered,
			isProbabilityFiltered,
			isProblyScoreFiltered,
			isSportsbookListFiltered
		} = storyFilterFormStore

		// Local state is used to adjust the form.  We then update the MobX store
		// with the final values of the local state that the user has selected
		// when submitting the form.
		const [probabilityValues, setProbabilityValues] = useState<number[]>([
			filterFormInputs.probabilityMin,
			filterFormInputs.probabilityMax
		])
		const [lastUpdatedValue, setLastUpdatedValue] = useState<number>(
			filterFormInputs.lastUpdatedMax
		)
		const [problyScoreMinValue, setProblyScoreMinValue] = useState<number>(
			filterFormInputs.problyScoreMin
		)
		const [isLive, setIsLive] = useState<boolean>(filterFormInputs.isLive)
		const [sportsbooks, setSportsbooks] = useState<string[]>(
			filterFormInputs.sportsbooks
		)
		const divRef = useRef<HTMLDivElement | null>(null)
		const [open, setOpen] = useState(false)
		const Items: DropdownOption[] = [
			{
				id: 0,
				icon: Icons.dollarSign,
				label: t`Profitability`,
				value: 'profitable',
				lock: !isSubscribed,
				status: false
			},
			{
				id: 1,
				icon: Icons.dice,
				label: t`Probability`,
				value: 'probable',
				lock: !isSubscribed,
				status: false
			},
			{
				id: 2,
				icon: Icons.dateRangeCalendar,
				label: t`Last Updated`,
				value: 'lastUpdated',
				lock: !isVerified,
				status: false
			},
			{
				id: 3,
				icon: Icons.book,
				label: t`Sportsbooks`,
				value: 'sportsbooks',
				lock: !isSubscribed,
				status: false
			},
			{
				id: 4,
				icon: Icons.liveTV,
				label: t`Live`,
				value: 'live',
				lock: !isAllAccessUser,
				status: false
			}
		]
		const problyScoreMarks: SliderMark[] = [
			{
				value: 2.5
			},
			{
				value: 5
			},
			{
				value: 7.5
			}
		]
		const probabilityMarks: SliderMark[] = [
			{
				value: 25
			},
			{
				value: 50
			},
			{
				value: 75
			}
		]
		const lastUpdatedMarks: SliderMark[] = [
			{
				value: 6.25
			},
			{
				value: 12.5
			},
			{
				value: 18.75
			}
		]
		const books: string[] = Object.keys(Sportsbooks).map(
			(key: string) => Sportsbooks[key as keyof typeof Sportsbooks]
		)

		// useClickOutside(divRef, () => setOpen(false))

		const handleSportsbookChange = (
			event: SelectChangeEvent<unknown>
		): void => {
			const {
				target: { value }
			} = event
			setSportsbooks(
				// On autofill we get a stringified value.
				typeof value === 'string' ? value.split(',') : (value as string[])
			)
		}

		const handleSubmitForm = (): void => {
			if (!isVerified) {
				setOpen(false)
				return
			}

			// The probabilityValues array can be either sorted by least to greatest
			// or by greatest to least
			const firstProbability = probabilityValues[0]
			const secondProbability = probabilityValues[1]
			const probabilityMax =
				firstProbability > secondProbability
					? firstProbability
					: secondProbability
			const probabilityMin =
				firstProbability < secondProbability
					? firstProbability
					: secondProbability
			const filterInputs: StoryFilterInputs = {
				probabilityMin,
				probabilityMax,
				problyScoreMin: problyScoreMinValue,
				lastUpdatedMax: lastUpdatedValue,
				isLive,
				sportsbooks
			}
			submitStoryFilterForm(filterInputs)
			setOpen(false)
		}

		const handleSubmitFormKeyDown = (evt: React.KeyboardEvent): void => {
			if (evt.key === 'Enter') {
				handleSubmitForm()
			}
		}

		const handleClearForm = (): void => {
			setIsLive(false)
			setProbabilityValues([0, 100])
			setProblyScoreMinValue(0)
			setLastUpdatedValue(24)
			clearStoryFilterForm()
			setOpen(false)
		}

		const handleClearFormKeyDown = (evt: React.KeyboardEvent): void => {
			if (evt.key === 'Enter') {
				handleClearForm()
			}
		}

		const renderDropdownOption = (item: DropdownOption): JSX.Element | null => {
			switch (item.value) {
				case 'live':
					return (
						<CustomSwitch
							disabled={item.lock}
							checked={isLive}
							onChange={(_: ChangeEvent, isChecked: boolean) => {
								setIsLive(isChecked)
							}}
							sx={{ marginRight: -2.5 }}
						/>
					)
				case 'probable':
					return (
						<Slider
							disabled={item.lock}
							step={1}
							marks={probabilityMarks}
							value={probabilityValues}
							onChange={(_: Event, newValue: number | number[]) => {
								setProbabilityValues(newValue as number[])
							}}
							getAriaValueText={value => {
								if (item.value === 'probable') {
									return `${value.toString()}%`
								}
								return value.toString()
							}}
							getAriaLabel={() => t`item.label`}
							valueLabelFormat={value => {
								if (item.value === 'probable') {
									return `${value.toString()}%`
								}
								return value.toString()
							}}
							valueLabelDisplay="auto"
							sx={{
								width: item.lock ? 90 : 130,
								color: '#05a6a6'
							}}
						/>
					)
				case 'profitable':
					return (
						<Slider
							aria-label={t`${item.label}`}
							disabled={item.lock}
							step={1}
							min={0}
							max={10}
							marks={problyScoreMarks}
							value={problyScoreMinValue}
							track="inverted"
							onChange={(_: Event, newValue: number | number[]) => {
								setProblyScoreMinValue(newValue as number)
							}}
							getAriaValueText={value => {
								if (!value) {
									return 'All Probly Scores'
								}
								return `${value.toString()}+ Probly Score`
							}}
							valueLabelFormat={value => {
								if (!value) {
									return 'All Probly Scores'
								}
								return `${value.toString()}+ Probly Score`
							}}
							valueLabelDisplay="auto"
							sx={{
								width: item.lock ? 90 : 130,
								color: '#05a6a6'
							}}
						/>
					)
				case 'lastUpdated':
					return (
						<Slider
							aria-label={t`item.label`}
							disabled={item.lock}
							step={1}
							min={1}
							max={25}
							marks={lastUpdatedMarks}
							value={lastUpdatedValue}
							onChange={(_: Event, newValue: number | number[]) => {
								setLastUpdatedValue(newValue as number)
							}}
							getAriaValueText={value => {
								if (value < 1) {
									return 'Last min'
								}
								if (value === 25) {
									return 'Anytime'
								}
								return `Last${value > 1 ? ` ${value.toString()}` : ''} hour${
									value > 1 ? 's' : ''
								}`
							}}
							valueLabelFormat={value => {
								if (value < 1) {
									return 'Last min'
								}
								if (value === 25) {
									return 'Anytime'
								}
								return `Last${value > 1 ? ` ${value.toString()}` : ''} hour${
									value > 1 ? 's' : ''
								}`
							}}
							valueLabelDisplay="auto"
							sx={{
								width: item.lock ? 90 : 130,
								color: '#05a6a6'
							}}
						/>
					)
				case 'sportsbooks':
					return (
						<div className="mb-1">
							<FormControl
								sx={{
									m: 0,
									width: item.lock ? 90 : 131
								}}
								size="small"
							>
								<InputLabel id="sportsbooks-label" />
								<Select
									labelId="sportsbooks-label"
									id="sportsbooks-checkbox"
									multiple
									disabled={item.lock}
									value={sportsbooks}
									onChange={handleSportsbookChange}
									input={<CustomInput />}
									renderValue={selected => (selected as string[]).join(', ')}
									MenuProps={MenuProps}
								>
									{books.map(book => (
										<MenuItem
											key={book}
											value={book}
											style={{ backgroundColor: '#39393D' }}
										>
											<Checkbox
												checked={sportsbooks.indexOf(book) > -1}
												style={{ color: '#FFF' }}
											/>
											<ListItemText primary={book} style={{ color: '#FFF' }} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					)
				default:
					return null
			}
		}

		return (
			<div className={clsx(profile ? 'w-max' : 'w-[90%]')}>
				<div
					className={clsx(
						'flex justify-start items-center h-6',
						!isOrderLocked && 'cursor-pointer'
					)}
					aria-hidden
					onClick={() => {
						if (isOrderLocked) {
							setOpen(false)
							return
						}
						if (!storySlided) setOpen(!open)
					}}
				>
					<Typography
						type="headline-xxsmall"
						label={t`${isFiltered ? 'Filtered:' : 'Filter by:'}`}
						className={clsx(
							'inline text-neutral-200',
							isOrderLocked && 'opacity-50'
						)}
					/>
					<div className="w-6 h-full mr-1">
						<Icon
							src={open ? Icons.arrowDropUp : Icons.arrowDropDown}
							fillPath
							className={clsx(
								'text-primary-500',
								isOrderLocked && 'opacity-50'
							)}
						/>
					</div>
					{isProbabilityFiltered && (
						<div className="w-4 h-4">
							<Icon
								src={Icons.dice}
								fillPath
								className="w-full h-full text-primary-500"
							/>
						</div>
					)}
					{isProblyScoreFiltered && (
						<div className="w-4 h-4">
							<Icon
								src={Icons.dollarSign}
								fillPath
								className="w-full h-full text-primary-500"
							/>
						</div>
					)}
					{isLastUpdatedFiltered && (
						<div className="w-4 h-4">
							<Icon
								src={Icons.dateRangeCalendar}
								fillPath
								className="w-full h-full text-primary-500"
							/>
						</div>
					)}
					{isSportsbookListFiltered && (
						<div className="w-4 h-4 ml-1">
							<Icon
								src={Icons.book}
								fillPath
								className="w-full h-full text-primary-500"
							/>
						</div>
					)}
					{isLiveFiltered && (
						<div className="w-4 h-4 ml-1">
							<Icon
								src={Icons.liveTV}
								fillPath
								className="w-full h-full text-primary-500"
							/>
						</div>
					)}
				</div>
				{open && (
					<div
						className={clsx(
							'absolute top-[30px] z-[100] w-full xs:w-[390px] my-0 mx-auto',
							profile ? 'top-[40px] -left-4 w-screen' : ' left-0'
						)}
					>
						<div
							ref={divRef}
							className="w-full flex flex-col px-4 bg-neutral-500 rounded-b-lg"
						>
							{Items.map(item => (
								<div
									key={item.value}
									className={clsx(
										'border-b border-b-neutral-300 mb-1'
										// orderKey === item.value && 'border-b-transparent'
									)}
								>
									<div
										key={item.id}
										className={clsx(
											'w-full flex justify-between p-2 rounded-lg',
											!item.lock && 'cursor-pointer'
											// orderKey === item.value && 'bg-neutral-300'
										)}
										aria-hidden
									>
										{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
										<div
											className={clsx(
												'w-full min-w-30 flex items-center gap-2',
												!item.lock && 'cursor-pointer'
											)}
										>
											<div className="w-6 h-6">
												{/* Use lazy evaluation for the Icon src */}
												<Icon
													src={((): Icons => item.icon)()}
													fillPath
													className="w-full h-full text-primary-500"
												/>
											</div>
											<Typography
												type="body-medium"
												label={item.label}
												className="min-w-30 text-neutral-50"
											/>
										</div>
										<div className="flex gap-4 justify-center items-center">
											{item.lock && (
												<div aria-hidden>
													<BoxLock lock={false} />
												</div>
											)}
											<div
												className={clsx(
													'w-15 h-5 flex justify-end justify-items-end items-center px-0'
												)}
											>
												{renderDropdownOption(item)}
											</div>
										</div>
									</div>
								</div>
							))}
							<div
								className="w-full flex justify-center items-center gap-8 py-[6px] cursor-pointer"
								aria-hidden
							>
								<div
									onClick={handleSubmitForm}
									onKeyDown={handleSubmitFormKeyDown}
									aria-label={t`Apply Filter Settings`}
									role="button"
									tabIndex={0}
								>
									<Typography
										type="body-medium"
										label={t`Apply`}
										className="text-white-100 underline"
									/>
								</div>
								<div
									onClick={handleClearForm}
									onKeyDown={handleClearFormKeyDown}
									aria-label={t`Clear Filter Settings`}
									role="button"
									tabIndex={0}
								>
									<Typography
										type="body-medium"
										label={t`Clear`}
										className="text-white-100 underline"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
)
