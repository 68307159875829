/* eslint-disable no-console */
import axios from 'axios'
import { updateImageBody } from 'interfaces'

// eslint-disable-next-line
const getFormData = (object: any): any =>
	Object.keys(object).reduce((formData, key) => {
		formData.append(key, object[key])
		return formData
	}, new FormData())

export const updateImage = async (
	url: string,
	body: updateImageBody
): Promise<void> => {
	const formdata = getFormData(body)
	// {
	// 	acl: body.acl,
	// 	key: body.key,
	// 	'x-amz-algorithm': body['x-amz-algorithm'],
	// 	'x-amz-credential': body['x-amz-credential'],
	// 	'x-amz-date': body['x-amz-date'],
	// 	'x-amz-security-token': body['x-amz-security-token'],
	// 	policy: body.policy,
	// 	'x-amz-signature': body['x-amz-signature'],
	// 	file: body.file
	// },
	await axios.post(url, formdata, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
