import axios from 'axios'
import { BaseURL } from 'api'

export interface SportsResponse {
	sports: {
		logoUrl: string
		sportId: string
		sportName: string
		totalBets: number
	}[]
}

export const getSports = async (): Promise<SportsResponse | null> => {
	let res = null as SportsResponse | null
	await axios.get(`${BaseURL}/sports`).then(
		async response => {
			res = response.data
		},
		error => {
			console.error(error)
		}
	)
	return res
}
