import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Icon } from 'components'
import { Icons, Images } from 'interfaces'
import { useStore } from 'stores'
import { Transition } from '@headlessui/react'

export const AppleSignUpInterstitial: React.FC = observer((): JSX.Element => {
	const { adStore, userStore } = useStore()
	const navigate = useNavigate()
	const location = useLocation()
	const { shouldShowAppleInterstitial } = adStore
	const handleAdClose = (): void => {
		adStore.closeAds()
	}
	const handleAdCloseKeyDown = (evt: React.KeyboardEvent): void => {
		if (evt.key === 'Enter') {
			handleAdClose()
		}
	}
	const handleAdClick = (): void => {
		handleAdClose()
		if (!userStore.user.isVerified) {
			navigate('/register', { state: { from: location } })
		} else {
			navigate('/subscribe', { state: { from: location } })
		}
	}
	const handleAdKeyDown = (evt: React.KeyboardEvent): void => {
		if (evt.key === 'Enter') {
			handleAdClick()
		}
	}
	return (
		<Transition.Root
			appear
			show={shouldShowAppleInterstitial}
			as={React.Fragment}
		>
			<Transition.Child
				as={React.Fragment}
				enter="transition-opacity duration-150"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div className="absolute top-0 h-[100vh] w-[100vw] xs:w-full sm:w-[390px]">
					<div className="fixed left-0 h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.70)] z-[99999]" />
					<div className="h-full w-full">
						<div
							className="z-[100005] absolute top-[0.5rem] right-[0.5rem] w-[20px] h-[20px] items-center"
							onClick={handleAdClose}
							onKeyDown={handleAdCloseKeyDown}
							aria-label="Close Promo Ad"
							role="button"
							tabIndex={0}
						>
							<Icon
								src={Icons.close}
								fillPath
								className="w-[20px] h-[20px] text-white-100"
							/>
						</div>
						<div
							className="z-[100000] w-full h-full absolute top-0"
							onClick={handleAdClick}
							onKeyDown={handleAdKeyDown}
							role="button"
							tabIndex={0}
						>
							<img
								src={Images.adInterstitialApple}
								alt="Sign Up Promo"
								className="w-full h-full object-fill"
							/>
						</div>
					</div>
				</div>
			</Transition.Child>
		</Transition.Root>
	)
})
