import React, { useEffect, useState } from 'react'
import { InfiniteData, useMutation, useQueryClient } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { t } from '@lingui/macro'
import {
	GET_STORIES,
	GET_STORIES_V2,
	StoriesResponseV2,
	saveStories
} from 'api'
import clsx from 'clsx'
import {
	Drawer,
	DrawerComments,
	DrawerShareStory,
	DrawerSignin,
	Icon,
	Loading,
	Typography
} from 'components'
import { useToast } from 'hooks'
import { Icons } from 'interfaces'
import { useStore } from 'stores'

export interface SocialProps {
	storyId: string
	sportsBookUrl?: string
	mobileSportsBookUrl?: string
	comments?: number
	tracked: boolean
	onVoteToggle?: () => void
}

export const Social: React.FC<SocialProps> = observer(
	({ storyId, tracked, sportsBookUrl }): JSX.Element => {
		const navigate = useNavigate()
		const location = useLocation()
		const { showToast } = useToast()
		const { authStore, storyFilterFormStore, userStore } = useStore()
		const { subscriptionStatus, isVerified } = userStore.user
		const isSubscribed =
			subscriptionStatus === 'active' || subscriptionStatus?.includes('trial')
		const { sportID, leagueID, filterFormInputs } = storyFilterFormStore
		const finalToken = authStore.token
		const queryClient = useQueryClient()

		const [showDrawer, setShowDrawer] = useState(false)
		const [openDrawer, setOpenDrawer] = useState(false)
		const [openShare, setOpenShare] = useState(false)
		const [isLoading, setIsLoading] = useState(false)
		const [storySelected, setStorySelected] = useState('')

		useEffect(() => {
			setStorySelected(storyId)
		}, [])

		const mutationSave = useMutation(saveStories, {
			onMutate: async () => {
				await queryClient.cancelQueries([
					GET_STORIES,
					finalToken,
					filterFormInputs
				])
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onError: (_err, _user, context: any) => {
				queryClient.setQueryData(
					[
						GET_STORIES_V2,
						{ token: finalToken, sportID, leagueID, ...filterFormInputs }
					],
					context?.previousStories
				)
				showToast('Save', 'Error: save Story', 'error')
				setIsLoading(false)
			},
			onSuccess: () => {
				showToast('Save', 'Story was saved successfullly', 'success')
				const previousStories = queryClient.getQueryData([
					GET_STORIES_V2,
					{ token: finalToken, sportID, leagueID, ...filterFormInputs }
				]) as InfiniteData<StoriesResponseV2 | null> | undefined
				const auxData = previousStories?.pages?.map(page => {
					return {
						...page,
						data: page?.data.map(story => {
							if (storyId === story.storyId) {
								return { ...story, tracked: !story.tracked }
							}
							return story
						})
					}
				})
				const finalData = {
					...previousStories,
					pages: auxData
				}
				queryClient.setQueryData(
					[
						GET_STORIES_V2,
						{ token: finalToken, sportID, leagueID, ...filterFormInputs }
					],
					finalData
				)
				setIsLoading(false)
			}
		})

		// const handleComment = (): void => {
		// 	setStorySelected(storyId)
		// 	if (!finalToken) {
		// 		setShowDrawer(true)
		// 	} else {
		// 		setOpenDrawer(true)
		// 		setShowDrawer(false)
		// 	}
		// }

		const handleShare = (): void => {
			setOpenShare(true)
			console.warn('share')
		}

		const handleRegister = (): void => {
			navigate('/register', { state: { from: location } })
		}

		const handleUpgrade = (): void => {
			navigate('/subscribe', { state: { from: location } })
		}

		const handleSave = async (): Promise<void> => {
			if (finalToken) {
				setIsLoading(true)
				mutationSave.mutate({
					storyId
				})
			} else {
				setShowDrawer(true)
			}
		}

		// const handleToggleStoryBallotKeyDown: React.KeyboardEventHandler = (
		// 	evt: React.KeyboardEvent
		// ): void => {
		// 	if (evt.code === 'Enter') {
		// 		onVoteToggle()
		// 	}
		// }

		// Make sure these are translated before being added to template
		// to avoid display errors where nothing is shown
		const socialLabels = {
			BetNow: t`Bet`,
			Register: t`Register`,
			Save: t`Save`,
			Share: t`Share`,
			Upgrade: t`Upgrade`,
			Vote: t`Vote`
		}

		return (
			<div className="w-full h-48 flex flex-col justify-center items-center gap-1">
				{/* <div className="w-full flex flex-col justify-center items-center gap-[2px] py-1">
					<div className="w-8 h-8" aria-hidden onClick={handleComment}>
						<Icon
							src={Icons.comments}
							fillPath
							className="w-full h-full text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
						/>
					</div>
					<Typography
						type="label-small"
						label={`${comments}`}
						className="text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
					/>
				</div> */}
				{/* <div
					className="w-full flex flex-col justify-center items-center gap-[2px] py-1"
					aria-hidden
					onClick={onVoteToggle}
					onKeyDown={handleToggleStoryBallotKeyDown}
				>
					<div className="w-8 h-8 text-center" aria-hidden>
						{isLoading ? (
							<div className="w-full flex justify-center items-center p-1 bg-transparent">
								<Loading size="x-small" version="v2" />
							</div>
						) : (
							<Icon
								src={Icons.ballot}
								fillPath
								className={clsx(
									'drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]',
									tracked ? 'text-primary-500' : 'text-white-100'
								)}
							/>
						)}
					</div>
					<Typography
						type="label-small"
						label={socialLabels.Vote}
						className={clsx(
							'drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]',
							tracked ? 'text-primary-500' : 'text-white-100'
						)}
					/>
				</div> */}
				{!isVerified && (
					<div
						className="w-full flex flex-col justify-center items-center gap-[2px] py-1"
						aria-hidden
						onClick={handleRegister}
					>
						<div className="w-8 h-8">
							<Icon
								src={Icons.registerUser}
								fillPath
								className="w-full h-full text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
							/>
						</div>
						<Typography
							type="label-small"
							label={socialLabels.Register}
							className="text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
						/>
					</div>
				)}
				{!isSubscribed && isVerified && (
					<div
						className="w-full flex flex-col justify-center items-center gap-[2px] py-1"
						aria-hidden
						onClick={handleUpgrade}
					>
						<div className="w-10 h-10">
							<Icon
								src={Icons.upgrade}
								fillPath
								className="w-full h-full text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
							/>
						</div>
						<Typography
							type="label-small"
							label={socialLabels.Upgrade}
							className="text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
						/>
					</div>
				)}
				{sportsBookUrl &&
					!sportsBookUrl.includes('<STATE>') &&
					!sportsBookUrl.includes('<COUNTRY>') && (
						<a target="_blank" rel="noopener noreferrer" href={sportsBookUrl}>
							<div
								className="w-full flex flex-col justify-center items-center gap-[2px] py-1"
								aria-hidden
							>
								<div className="w-8 h-8">
									<Icon
										src={Icons.circleDollarSign}
										fillPath
										className="w-full h-full text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
									/>
								</div>
								<Typography
									type="label-small"
									label={socialLabels.BetNow}
									className="text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
								/>
							</div>
						</a>
					)}
				<div
					className="w-full flex flex-col justify-center items-center gap-[2px] py-1"
					aria-hidden
					onClick={handleShare}
				>
					<div className="w-8 h-8">
						<Icon
							src={Icons.send}
							fillPath
							className="w-full h-full text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
						/>
					</div>
					<Typography
						type="label-small"
						label={socialLabels.Share}
						className="text-white-100 drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]"
					/>
				</div>
				<div
					className="w-full flex flex-col justify-center items-center gap-[2px] py-1"
					aria-hidden
					onClick={handleSave}
				>
					<div className="w-8 h-8">
						{isLoading ? (
							<div className="w-full flex justify-center items-center p-1 bg-transparent">
								<Loading size="x-small" version="v2" />
							</div>
						) : (
							<Icon
								src={Icons.save}
								fillPath
								className={clsx(
									'drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]',
									tracked ? 'text-primary-500' : 'text-white-100'
								)}
							/>
						)}
					</div>
					<Typography
						type="label-small"
						label={socialLabels.Save}
						className={clsx(
							'drop-shadow-[0px_2px_2px_rgba(0,0,0,0.25)]',
							tracked ? 'text-primary-500' : 'text-white-100'
						)}
					/>
				</div>
				{openDrawer && (
					<Drawer
						open={openDrawer}
						setOpen={(val: boolean) => setOpenDrawer(val)}
						mobileFull
					>
						<DrawerComments
							storyId={storySelected}
							onClose={(val: boolean) => setOpenDrawer(val)}
						/>
					</Drawer>
				)}
				{showDrawer && <DrawerSignin onClose={setShowDrawer} />}
				{openShare && (
					<DrawerShareStory storyId={storySelected} onClose={setOpenShare} />
				)}
			</div>
		)
	}
)
