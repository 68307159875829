import React from 'react'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

export const CustomInput: React.FC = styled(InputBase)(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(0)
	},
	'& .MuiInputBase-input': {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: '#ced4da',
		border: '1px solid #ced4da',
		color: '#39393D',
		fontSize: 12,
		padding: '4px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#05a6a6',
			boxShadow: '0 0 0 0.2rem rgba(#05a6a6,.25)'
		}
	}
}))
