import { ContextUserType, UserType } from 'interfaces'
import { createContext, useCallback, useState } from 'react'

const UserContextDefaultValue: ContextUserType = {
	user: {
		email: '',
		token: '',
		address: '',
		date_of_birth: '',
		first_name: '',
		gender: '',
		get_email_notifications: true,
		id: 0,
		invite_sent_at: '',
		last_login_at: '',
		last_name: '',
		managed_by_id: '',
		phone: '',
		registered_at: '',
		roles: []
	},
	setUser: () => null
}

export const UserContext = createContext<ContextUserType>(
	UserContextDefaultValue
)

export const useContextUser = (): ContextUserType => {
	const [user, setUserVal] = useState<UserType>(UserContextDefaultValue.user)

	const setUser = useCallback(
		(data: UserType) => {
			setUserVal(data)
		},
		[setUserVal]
	)

	return {
		user,
		setUser
	}
}
