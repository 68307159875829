export enum Images {
	adInterstitialApple = 'https://d140d0hp4ken9g.cloudfront.net/apple_app_ad_interstitial.gif',
	adInterstitialWeb = 'https://d140d0hp4ken9g.cloudfront.net/web_app_ad_interstitial.gif',
	giphyLogoBlackHorizontalText = 'https://d140d0hp4ken9g.cloudfront.net/giphy_poweredby_horiztext_200px_black.png',
	giphyLogoBlackHorizontalLogo = 'https://d140d0hp4ken9g.cloudfront.net/giphy_poweredby_horizlogo_200px_black.png',
	landingPageCarousel1 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-1.png',
	landingPageCarousel2 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-2.png',
	landingPageCarousel3 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-3.png',
	landingPageCarousel4 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-4.png',
	landingPageCarousel5 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-5.png',
	landingPageCarousel6 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-6.png',
	landingPageCarousel7 = 'https://d140d0hp4ken9g.cloudfront.net/landing-carousel-7.png',
	logoProbly = 'https://d140d0hp4ken9g.cloudfront.net/logo-probly.png',
	logoProblyP = 'https://d140d0hp4ken9g.cloudfront.net/logo-probly-p.png',
	logoProblyName = 'https://d140d0hp4ken9g.cloudfront.net/probly_name_logo.png',
	logoGoodLuck = 'https://d140d0hp4ken9g.cloudfront.net/logo-probly-goodluck.png',
	mockPost0 = 'https://d140d0hp4ken9g.cloudfront.net/mock-post-0.png',
	mockPost1 = 'https://d140d0hp4ken9g.cloudfront.net/mock-post-1.png',
	mockPost2 = 'https://d140d0hp4ken9g.cloudfront.net/mock-post-2.png',
	notifications = 'https://d140d0hp4ken9g.cloudfront.net/notifications.png',
	paymentProfile = 'https://d140d0hp4ken9g.cloudfront.net/paymentProfile.png',
	performanceProfile = 'https://d140d0hp4ken9g.cloudfront.net/performanceProfile.png',
	program_cover = 'https://d140d0hp4ken9g.cloudfront.net/program_cover.png',
	steven = 'https://d140d0hp4ken9g.cloudfront.net/Steven.png',
	splash = 'https://d140d0hp4ken9g.cloudfront.net/logo_splash.png'
}

export enum Icons {
	apple = '/asset/icons/apple.svg',
	arrow = '/asset/icons/arrow.svg',
	arrowBackIos = '/asset/icons/arrowBackIos.svg',
	arrowBackward = '/asset/icons/arrowBackward.svg',
	arrowDownward = '/asset/icons/arrowDownward.svg',
	arrowDropDown = '/asset/icons/arrowDropDown.svg',
	arrowDropUp = '/asset/icons/arrowDropUp.svg',
	arrowForward = '/asset/icons/arrowForward.svg',
	arrowForwardIos = '/asset/icons/arrowForwardIos.svg',
	arrowUpward = '/asset/icons/arrowUpward.svg',
	ballot = '/asset/icons/ballot.svg',
	basketball = '/asset/icons/basketball.svg',
	basketballFill = '/asset/icons/basketballFill.svg',
	bike = '/asset/icons/bike.svg',
	book = '/asset/icons/book.svg',
	call = '/asset/icons/call.svg',
	callFill = '/asset/icons/callFill.svg',
	cancel = '/asset/icons/cancel.svg',
	check = '/asset/icons/check.svg',
	circleDollarSign = '/asset/icons/circleDollarSign.svg',
	close = '/asset/icons/close.svg',
	clock = '/asset/icons/clock.svg',
	comments = '/asset/icons/comments.svg',
	creditCard = '/asset/icons/creditCard.svg',
	creditCardFill = '/asset/icons/creditCardFill',
	dateRangeCalendar = '/asset/icons/dateRange.svg',
	dice = '/asset/icons/dice.svg',
	dollarSign = '/asset/icons/dollarSign.svg',
	edit = '/asset/icons/edit.svg',
	editFill = '/asset/icons/editFill.svg',
	facebook = '/asset/icons/facebook.svg',
	facebookBlack = '/asset/icons/facebookBlack.svg',
	telegramBlack = '/asset/icons/telegramBlack.svg',
	whatsappBlack = '/asset/icons/whatsappBlack.svg',
	link = '/asset/icons/link.svg',
	liveTV = '/asset/icons/liveTV.svg',
	filterList = '/asset/icons/filterList.svg',
	fire = '/asset/icons/fire.svg',
	fireFill = '/asset/icons/fireFill',
	football = '/asset/icons/football.svg',
	footballFill = '/asset/icons/footballFill.svg',
	google = '/asset/icons/google.svg',
	info = '/asset/icons/info.svg',
	keyboardDoubleArrowDown = '/asset/icons/keyboardDoubleArrowDown.svg',
	keyboardDoubleArrowUp = '/asset/icons/keyboardDoubleArrowUp.svg',
	keyboardDoubleArrowLeft = '/asset/icons/keyboardDoubleArrowDLeft.svg',
	keyboardDoubleArrowRight = '/asset/icons/keyboardDoubleArrowRight.svg',
	lock = '/asset/icons/lock.svg',
	logIn = '/asset/icons/logIn.svg',
	logOut = '/asset/icons/logOut.svg',
	mail = '/asset/icons/mail.svg',
	mailFill = '/asset/icons/mailFill.svg',
	medal = '/asset/icons/medal.svg',
	medalFill = '/asset/icons/medalFill.svg',
	mma = '/asset/icons/mma.svg',
	mmaFill = '/asset/icons/mmaFill.svg',
	mockLeague = '/asset/icons/mock-league.svg',
	outgoingMail = '/asset/icons/outgoingMail.svg',
	outgoingMailFill = '/asset/icons/outgoingMailFill.svg',
	person = '/asset/icons/person.svg',
	personFill = '/asset/icons/personFill.svg',
	personAdd = '/asset/icons/personAdd.svg',
	personAddFill = '/asset/icons/personAddFill.svg',
	personRemove = '/asset/icons/personRemove.svg',
	personRemoveFill = '/asset/icons/personRemoveFill.svg',
	profile = '/asset/icons/profile.svg',
	registerUser = '/asset/icons/registerUser.svg',
	rocketLaunch = '/asset/icons/rocketLaunch.svg',
	rocketLaunchFill = '/asset/icons/rocketLaunchFill.svg',
	save = '/asset/icons/save.svg',
	schedule = '/asset/icons/schedule.svg',
	scheduleFill = '/asset/icons/scheduleFill.svg',
	search = '/asset/icons/search.svg',
	searchFill = '/asset/icons/searchFill.svg',
	send = '/asset/icons/send.svg',
	settings = '/asset/icons/settings.svg',
	settingsFill = '/asset/icons/settingsFill.svg',
	soccer = '/asset/icons/soccer.svg',
	sparkles = '/asset/icons/sparkles.svg',
	sparklesUp = '/asset/icons/sparklesUp.svg',
	sparklesFill = '/asset/icons/sparklesFill.svg',
	share = '/asset/icons/share.svg',
	tennis = '/asset/icons/tennis.svg',
	tennisFill = '/asset/icons/tennisFill.svg',
	thumbUp = '/asset/icons/thumbUp.svg',
	thumbUpFill = '/asset/icons/thumbUpFill.svg',
	thumbDown = '/asset/icons/thumbDown.svg',
	thumbDownFill = '/asset/icons/thumbDownFill.svg',
	trendingDown = '/asset/icons/trendingDown.svg',
	trendingUp = '/asset/icons/trendingUp.svg',
	upgrade = '/asset/icons/upgrade.svg',
	usa = '/asset/icons/usa.svg',
	visibility = '/asset/icons/visibility.svg',
	visibilityFill = '/asset/icons/visibilityFill.svg',
	visibilityOff = '/asset/icons/visibilityOff.svg',
	visibilityOffFill = '/asset/icons/visibilityOffFill.svg'
}

export type IconsType = Icons
