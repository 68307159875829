import React, { createContext, useContext, useState } from 'react'
import { UserStore } from './UserStore'
import { AuthStore } from './AuthStore'
import { AppleIAPStore } from './AppleIAPStore'
import { StoryFilterFormStore } from './StoryFilterFormStore'
import { AdStore } from './AdStore'

export class RootStore {
	userStore: UserStore

	authStore: AuthStore

	appleIAPStore: AppleIAPStore

	storyFilterFormStore: StoryFilterFormStore

	adStore: AdStore

	constructor() {
		this.userStore = new UserStore()
		this.authStore = new AuthStore()
		this.appleIAPStore = new AppleIAPStore()
		this.storyFilterFormStore = new StoryFilterFormStore()
		this.adStore = new AdStore()
	}
}

// eslint-disable-next-line
export const RootStoreContext = createContext<RootStore>(null!)
export const RootStoreProvider = ({
	children
}: {
	children: React.ReactNode
}): JSX.Element => {
	const [store] = useState(new RootStore())
	return (
		<RootStoreContext.Provider value={store}>
			{children}
		</RootStoreContext.Provider>
	)
}
export const useStore = (): RootStore => useContext(RootStoreContext)
