import { BaseURL } from 'api'
import axios from 'axios'

export interface LeagueItem {
	logoUrl?: string
	sportId: string
	leagueId: string
	leagueName: string
}

export interface LeaguesResponse {
	leagues: {
		countryId: number
		countryName: string
		count: number
		count_page: number
		data: LeagueItem[]
		next: number | null
		page: number
		previous: number | null
		records: number
	}[]
	error?: string
}

export const getLeagues = async (
	page: number = 1,
	sportId: string = 'all',
	search: string = ''
): Promise<LeaguesResponse | null> => {
	let res = null as LeaguesResponse | null
	await axios
		.get(
			`${BaseURL}/leagues/${sportId}?page=${page}&records=10${
				search ? `&search=${search}` : ''
			}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
