import { GET_HISTORY_STORIES, getStoriesPollResponse } from 'api'
import { HeaderProfile, HistoryBox, LayoutApp, Loading } from 'components'
// import { useSearchDebounce } from 'hooks'
import { Icons } from 'interfaces'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
// import { useForm, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'

export interface HistorialType {
	sportId: string
	startTime: string
	probability: string
	createAt: string
	userId: string
	agree: 'Yes' | 'No' | null
	pollResponseId: string
	cutoffTime: string
	leagueId: string
	description: string
	storyId: string
}

export const History: React.FC = () => {
	// const { register, control } = useForm<{ search: string }>({
	// 	mode: 'onChange'
	// })
	// const watchSearch = useWatch({
	// 	control,
	// 	name: 'search'
	// })
	// const { handleChangeDebounce } = useSearchDebounce(watchSearch)

	const [historial, setHistorial] = useState<HistorialType[]>([])

	const { data, isLoading } = useQuery([GET_HISTORY_STORIES], () =>
		getStoriesPollResponse()
	)

	useEffect(() => {
		if (data?.poll_response) setHistorial(data.poll_response)
	}, [data])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'History'
		})
	}, [])
	return (
		<LayoutApp desktop>
			<div className="w-full h-full">
				{isLoading && (
					<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				)}
				<HeaderProfile title="History" icon={Icons.arrowBackward} to="-1" />
				<div className="w-full h-[calc(100vh-52px)] flex flex-col items-center gap-4 p-4 overflow-hidden">
					{/* <div className="w-full h-10 flex items-center"> */}
					{/* <Input
							name="search"
							register={register}
							placeholder={t`Find Bets`}
							leftImg={Icons.search}
							type="text"
							onChange={handleChangeDebounce}
						/> */}
					{/* </div> */}
					<div className="w-full h-[calc(100dvh-140px)] flex flex-col gap-4 overflow-hidden overflow-y-scroll hide-scroll-bar">
						{historial.map(item => {
							return (
								<HistoryBox
									key={item.storyId}
									description={item.description}
									result={item.agree}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</LayoutApp>
	)
}
