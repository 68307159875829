export const api = {
	online: `${process.env.REACT_APP_API_URL}/v1`,
	onlinev2: `${process.env.REACT_APP_API_URL}/v2`,
	dev: 'https://wow7ra6te5.execute-api.ca-central-1.amazonaws.com'
}

export const BaseURL = api.online
export const BaseURLv2 = api.onlinev2 // This EP is used for the new home stories flow
export const isProduction =
	process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'prod'
export const BaseURLDUMMY = 'https://rickandmortyapi.com/api'
