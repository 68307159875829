import { makeAutoObservable } from 'mobx'
import Cookies from 'universal-cookie'

export class AuthStore {
	showSignInFooter = false

	private accessToken = ''

	constructor() {
		makeAutoObservable(this)
		const cookies = new Cookies()
		this.accessToken = cookies.get('token') || ''
	}

	get token(): string {
		const cookies = new Cookies()
		return this.accessToken || cookies.get('token') || ''
	}

	setAccessToken = (token = ''): void => {
		const cookies = new Cookies()
		const isLocalEnvironment =
			!process.env.REACT_APP_ENVIRONMENT?.includes('prod') &&
			!process.env.REACT_APP_ENVIRONMENT?.includes('dev') &&
			!process.env.REACT_APP_ENVIRONMENT?.includes('test')
		cookies.set('token', token, {
			path: '/',
			maxAge: 60 * 60 * 24 * 365 * 5, // 5 years
			domain: !isLocalEnvironment ? 'probly.com' : undefined
		})
		this.accessToken = token
	}

	setShowSignInFooter = (showSignInFooter = false): void => {
		this.showSignInFooter = showSignInFooter
	}

	showSignInPopUp = (): void => {
		this.showSignInFooter = true
	}

	closeSignInPopUp = (): void => {
		this.showSignInFooter = false
	}

	signIn = (accessToken = ''): void => {
		this.setAccessToken(accessToken)
	}

	signOut = (): void => {
		const cookies = new Cookies()
		cookies.remove('token', {
			domain: 'probly.com'
		})
		// In case the user is in iOS app or is a local developer
		// we also make sure to remove the token for the unspecified domain
		cookies.remove('token')
		this.accessToken = ''
	}
}
