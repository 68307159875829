/* eslint-disable react/require-default-props */
import clsx from 'clsx'
import React from 'react'

export interface TypographyProps extends React.HTMLProps<HTMLElement> {
	/**
	 * This is size of the typography
	 */
	type:
		| 'display-large'
		| 'display-medium'
		| 'display-small'
		| 'headline-large'
		| 'headline-medium'
		| 'headline-small'
		| 'headline-xsmall'
		| 'headline-xxsmall'
		| 'title-large'
		| 'title-medium'
		| 'title-small'
		| 'label-large'
		| 'label-medium'
		| 'label-small'
		| 'label-xsmall'
		| 'body-large'
		| 'body-medium'
		| 'body-small'
		| 'good-luck'
	/**
	 * This is text in label
	 */
	label: string
	className?: string
	style?: React.CSSProperties
}

export const Typography = React.forwardRef<
	HTMLHeadingElement | HTMLParagraphElement,
	TypographyProps
>(
	(
		{ type = 'display-large', label = 'no label', className, style = {} },
		ref
	) => {
		return (
			<>
				{type === 'display-large' && ( // 0
					<h1
						ref={ref}
						className={clsx(
							'text-[57px] leading-[64px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h1>
				)}
				{type === 'display-medium' && ( // 0
					<h2
						ref={ref}
						className={clsx(
							'text-[45px] leading-[52px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h2>
				)}
				{type === 'display-small' && ( // 0
					<h3
						ref={ref}
						className={clsx(
							'text-[36px] leading-[44px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h3>
				)}
				{type === 'headline-large' && ( // 0
					<h4
						ref={ref}
						className={clsx(
							'text-[32px] leading-[40px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h4>
				)}
				{type === 'headline-medium' && ( // 0
					<h4
						ref={ref}
						className={clsx(
							'text-[28px] leading-[36px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h4>
				)}
				{type === 'headline-small' && ( // 0
					<h4
						ref={ref}
						className={clsx(
							'text-[24px] leading-[32px] font-semibold font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h4>
				)}
				{type === 'headline-xsmall' && ( // 0
					<h4
						ref={ref}
						className={clsx(
							'text-[16px] leading-[24px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h4>
				)}
				{type === 'headline-xxsmall' && ( // 0
					<h4
						ref={ref}
						className={clsx(
							'text-[14px] leading-[20px] font-regular font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h4>
				)}
				{type === 'title-large' && ( // 0
					<p
						ref={ref}
						className={clsx(
							'text-[22px] leading-[28px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'title-medium' && ( // 0.15
					<p
						ref={ref}
						className={clsx(
							'text-[16px] leading-[24px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'title-small' && ( // 0.1
					<h4
						ref={ref}
						className={clsx(
							'text-[14px] leading-[20px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</h4>
				)}
				{type === 'label-large' && ( // 0.1
					<p
						ref={ref}
						className={clsx(
							'text-[14px] leading-[20px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'label-medium' && ( // 0.5
					<p
						ref={ref}
						className={clsx(
							'text-[12px] leading-[16px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'label-small' && ( // 0.5
					<p
						ref={ref}
						className={clsx(
							'text-[11px] leading-[16px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'label-xsmall' && ( // 0.5
					<p
						ref={ref}
						className={clsx(
							'text-[10px] leading-[12px] font-medium font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'body-large' && ( // 0.15
					<p
						ref={ref}
						className={clsx(
							'text-[16px] leading-[24px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'body-medium' && ( // 0.25
					<p
						ref={ref}
						className={clsx(
							'text-[14px] leading-[20px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'body-small' && ( // 0.4
					<p
						ref={ref}
						className={clsx(
							'text-[12px] leading-[16px] font-normal font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
				{type === 'good-luck' && ( // 0.4
					<p
						ref={ref}
						className={clsx(
							'text-[22px] leading-[32px] font-semibold font-poppins',
							className
						)}
						style={style}
					>
						{label}
					</p>
				)}
			</>
		)
	}
)

export default Typography
