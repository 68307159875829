import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import { DrawerSignin, Icon } from 'components'
import { Icons } from 'interfaces'
import { useStore } from 'stores'

export const Footer: React.FC = observer((): JSX.Element => {
	const { authStore, userStore, storyFilterFormStore } = useStore()
	const location = useLocation()
	const { storySlided } = storyFilterFormStore
	const urlPath = `/${location.pathname.split('/')[1]}`
	const navList = [
		{
			id: 'Search',
			icon: Icons.search,
			href: userStore.isSubscribed ? '/search' : '/subscribe',
			current: urlPath === '/search' || urlPath.search('/search') > -1
		},
		{
			id: 'Home',
			icon: Icons.fire,
			href: userStore.isSubscribed ? '/home' : '/subscribe',
			current: urlPath === '/home'
		},
		{
			id: 'Profile',
			icon: Icons.profile,
			href: authStore.token ? '/profile' : '/home',
			current: urlPath === '/profile' || urlPath.search('/profile') > -1
		}
	]

	const handleClick = (id: string): void => {
		if (storySlided) {
			authStore.closeSignInPopUp()
			return
		}
		if (authStore.token && id === 'Profile') {
			authStore.closeSignInPopUp()
		} else {
			authStore.showSignInPopUp()
		}
	}

	const handleSignInClose = (): void => {
		authStore.closeSignInPopUp()
	}

	return (
		<div className="w-full h-full flex justify-center items-center gap-5 bg-neutral-600 px-10">
			{navList.map(nav => (
				<Link
					key={nav.id}
					to={nav.href}
					replace
					className="w-16 h-full flex justify-center items-center"
					onClick={() => handleClick(nav.id)}
				>
					<div className={`${nav.current ? 'w-10 h-10' : 'w-6 h-6'}`}>
						<Icon
							src={nav.icon}
							fillPath
							className={clsx(
								nav.current ? 'text-neutral-100' : 'text-neutral-200'
							)}
						/>
					</div>
				</Link>
			))}
			{!authStore.token && authStore.showSignInFooter && (
				<DrawerSignin onClose={handleSignInClose} />
			)}
		</div>
	)
})
