import { ApplePurchaseStatus, IAPProduct, IAPTransaction } from 'interfaces'
import { makeAutoObservable } from 'mobx'

declare const window: Window & {
	// eslint-disable-next-line
	webkit?: any
}

// eslint-disable-next-line
declare const AppleID: any

export class AppleIAPStore {
	isIOSIAPCapable = false

	subscriptionProductIds: string[] = [
		'probly_plus_live_year',
		'probly_plus_live_month',
		'probly_plus_year',
		'probly_plus_month'
	]

	private productTransactions: IAPTransaction[] = []

	private products: IAPProduct[] = []

	isSubscriptionRecordCreated = false

	purchaseStatus: ApplePurchaseStatus | '' = ''

	constructor() {
		makeAutoObservable(this)
	}

	get transactions(): IAPTransaction[] {
		return this.productTransactions
	}

	get subscriptions(): IAPProduct[] {
		return this.products
	}

	setProductTransactions = (transactions: IAPTransaction[] = []): void => {
		this.productTransactions = transactions
	}

	setSubscriptions = (subscriptions: IAPProduct[] = []): void => {
		this.products = subscriptions
	}

	setIsSubRecordCreated = (isSubRecordCreated: boolean): void => {
		this.isSubscriptionRecordCreated = isSubRecordCreated
	}

	setPurchaseStatus = (status: ApplePurchaseStatus): void => {
		this.purchaseStatus = status
	}

	setIsIOSIAPCapable = (isIOSIAPCapable: boolean): void => {
		this.isIOSIAPCapable = isIOSIAPCapable
	}

	initialize = (): void => {
		// Use webkit messageHandlers and window event listeners to fetch subscription products
		// from native Swift layer
		if (
			window.webkit &&
			window.webkit.messageHandlers &&
			window.webkit.messageHandlers['iap-purchase-request'] &&
			window.webkit.messageHandlers['iap-transactions-request'] &&
			window.webkit.messageHandlers['iap-products-request']
		) {
			this.isIOSIAPCapable = true
		}
	}
}
