/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'

export function useClickOutside(
	ref: React.MutableRefObject<any>,
	handler: (value: React.SetStateAction<boolean>) => void
): void {
	useEffect(
		() => {
			const listener = (event: any) => {
				if (!ref.current || ref.current.contains(event.target)) {
					// click inside
					return
				}

				// click outside
				handler(event)
			}

			document.addEventListener('mousedown', listener)
			document.addEventListener('touchstart', listener)

			return () => {
				document.removeEventListener('mousedown', listener)
				document.removeEventListener('touchstart', listener)
			}
		},
		// Add ref and handler to effect dependencies
		// It's worth noting that because passed in handler is a new ...
		// ... function on every render that will cause this effect ...
		// ... callback/cleanup to run every render. It's not a big deal ...
		// ... but to optimize you can wrap handler in useCallback before ...
		// ... passing it into this hook.
		[ref, handler]
	)
}
