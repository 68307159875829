import { Typography } from 'components'
import { Images } from 'interfaces'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const GoodLuck: React.FC = (): JSX.Element => {
	const navigate = useNavigate()
	const location = useLocation()
	const handleBackHome = (): void => {
		navigate('/home', { state: { from: location } })
	}

	return (
		<div className="w-full min-h-[calc(100vh-191px)] flex-1 flex flex-col justify-center items-center bg-black-400 gap-10">
			<div
				className="cursor-pointer"
				aria-hidden
				onClick={() => handleBackHome()}
			>
				<img
					src={Images.logoGoodLuck}
					alt="logo-probly"
					className="w-44 h-16"
				/>
			</div>
			<Typography
				type="title-medium"
				label="Taking you to the best available bet for your selection."
				className="text-neutral-200 px-10 text-center"
			/>
			<Typography
				type="good-luck"
				label="Good Luck!"
				className="text-neutral-200"
			/>
		</div>
	)
}
