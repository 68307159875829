import { UserProfile } from 'api'
import { otpResponse } from 'interfaces'
import { makeAutoObservable } from 'mobx'

export class UserStore {
	user: UserProfile = {
		userId: '',
		userName: '',
		email: '',
		phone: '',
		avatarUrl: '',
		lastOptCode: '',
		subscriptionStatus: '',
		subscriptionPlanId: '',
		createAt: '',
		isVerified: '',
		updateAt: ''
	}

	constructor() {
		makeAutoObservable(this)
	}

	get isSubscribed(): boolean {
		return this.user?.subscriptionStatus === 'active'
	}

	setUser(user?: UserProfile): void {
		if (user)
			this.user = {
				...this.user,
				...user
			}
	}

	setUserWithOtpVerificationResponse(user: otpResponse): void {
		if (user) {
			this.user = {
				...this.user,
				email: user.email,
				userId: user.userId,
				phone: user.phone,
				subscriptionStatus:
					user.subscriptionStatus || this.user.subscriptionStatus || '',
				subscriptionPlanId:
					user.subscriptionPlanId || this.user.subscriptionPlanId || ''
			}
		}
	}

	reset(): void {
		this.user = {
			userId: '',
			userName: '',
			email: '',
			phone: '',
			avatarUrl: '',
			lastOptCode: '',
			subscriptionStatus: '',
			subscriptionPlanId: '',
			createAt: '',
			isVerified: '',
			updateAt: ''
		}
	}
}
