/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { InfiniteData, Query, useMutation, useQueryClient } from 'react-query'
import { observer } from 'mobx-react'
import {
	GET_STORIES_V2,
	PollResponse,
	postPollResponse,
	StoriesResponseV2
} from 'api'
import clsx from 'clsx'
import { DrawerSignin, Loading, Typography } from 'components'
import { useToast } from 'hooks'
import { Images, StoryPost } from 'interfaces'
import { useStore } from 'stores'

export interface BetSurveyModalProps
	extends React.HTMLAttributes<HTMLDivElement> {
	storyId: string
	title: string
	wfull?: boolean
	setView?: React.Dispatch<React.SetStateAction<'info' | 'survey'>>
	post: StoryPost
	onVote?: (vote: PollResponse | null) => void
}

export const BetSurveyModal: React.FC<BetSurveyModalProps> = observer(
	({ storyId, title, wfull = false, setView, post, onVote }): JSX.Element => {
		const { agree, yesPercentage } = post
		const { showToast } = useToast()
		const { authStore } = useStore()
		const finalToken = authStore.token
		const queryClient = useQueryClient()
		const [showDrawer, setShowDrawer] = useState(false)
		const [voted, setVoted] = useState(false)
		const [agreeYesNo, setAgreeYesNo] = useState<'No' | 'Yes' | undefined>()
		const [percentage, setPercentage] = useState<{ yes: number; no: number }>({
			yes: 0,
			no: 0
		})

		useEffect(() => {
			if (yesPercentage) {
				setVoted(true)
				setPercentage({
					yes: parseInt(yesPercentage, 10),
					no: 100 - parseInt(yesPercentage, 10)
				})
			} else {
				setVoted(false)
			}
		}, [post])

		useEffect(() => {
			const interval = setInterval(() => {
				if (setView) {
					if (voted) {
						setView('info')
					} else {
						setView('survey')
					}
				}
			}, 1000) // TODO: validate wait time

			return () => {
				clearInterval(interval)
			}
		}, [voted])

		const updateStoryAgreeOnQueries = (
			currentData: InfiniteData<StoriesResponseV2> | undefined,
			voteData: PollResponse
		): InfiniteData<StoriesResponseV2> | undefined => {
			if (!currentData?.pages) return currentData
			const updatedQueryPages = currentData.pages.map(page => {
				const storyIndex = page.data.findIndex(
					story => storyId === story.storyId
				)
				const pageContainsStory = storyIndex !== -1
				if (!pageContainsStory) return page
				const updatedData = [...page.data]
				const story = updatedData[storyIndex]
				updatedData[storyIndex] = {
					...story,
					agree: voteData.agree,
					yesPercentage: voteData.yesPercentage
				}
				return { ...page, data: updatedData }
			})
			return {
				...currentData,
				pages: updatedQueryPages
			}
		}

		const setAgreePercentage = (data: PollResponse | null): void => {
			if (!data) return
			setPercentage({
				yes: parseInt(data.yesPercentage, 10),
				no: 100 - parseInt(data.yesPercentage, 10)
			})
			setAgreeYesNo(data.agree)
			setVoted(true)
		}

		const refreshQueriesCache = (data: PollResponse | null): void => {
			if (!data) return
			const queriesFilter = (query: Query): boolean => {
				const keys = query.queryKey as [string, { token?: string }]
				const isGetStoriesQuery =
					keys.includes(GET_STORIES_V2) && keys[1].token === finalToken
				return isGetStoriesQuery
			}
			queryClient.setQueriesData<InfiniteData<StoriesResponseV2> | undefined>(
				{ predicate: queriesFilter },
				currentData => updateStoryAgreeOnQueries(currentData, data)
			)
		}

		const { mutate: saveStoryVote, isLoading } = useMutation(postPollResponse, {
			onError: () => {
				showToast('Poll', 'Error: request poll', 'error')
			},
			onSuccess: async data => {
				setAgreePercentage(data)
				refreshQueriesCache(data)
				if (onVote) onVote(data)
			}
		})

		const handleVote = async (vote: string): Promise<void> => {
			if (!finalToken) {
				setShowDrawer(true)
			} else {
				setShowDrawer(false)
				if (!voted) {
					saveStoryVote({
						storyId,
						agree: vote === 'YES' ? 'Yes' : vote === 'NO' ? 'No' : null
					})
				}
			}
		}

		return (
			<div
				className={clsx(
					wfull ? 'w-full' : 'w-[300px]',
					'h-full rounded-2xl bg-neutral-400 opacity-90'
				)}
			>
				<header
					className="w-full h-[29px] flex justify-center items-center"
					aria-hidden
					onClick={() => {
						if (setView) setView('info')
					}}
				>
					<Typography
						type="label-medium"
						label={title}
						className="w-full text-neutral-50 text-center"
					/>
				</header>
				<div className="w-full bg-neutral-200 opacity-20 h-[1px]" />
				{isLoading ? (
					<footer
						className={clsx(
							'w-full h-[50px] flex items-center',
							voted ? 'justify-between' : 'justify-center'
						)}
					>
						<div className="w-full flex justify-center items-center p-3 bg-transparent">
							<Loading size="x-small" version="v2" />
						</div>
					</footer>
				) : (
					<footer
						className={clsx(
							'w-full h-[50px] flex items-center',
							voted ? 'justify-between' : 'justify-center'
						)}
					>
						{voted ? (
							<div
								className={clsx(
									'w-10 h-full cursor-pointer flex justify-center items-center',
									(agree === 'No' || agreeYesNo === 'No') && 'bg-danger-500',
									percentage.no === 0 && 'hidden'
								)}
								aria-hidden
								onClick={() => handleVote('NO')}
								style={{
									width: `${percentage.no}%`,
									borderRadius: '0px 16px 16px 16px'
								}}
							>
								<Typography
									type="label-medium"
									label={`NO ${percentage.no}%`}
									className="text-white-100"
								/>
							</div>
						) : (
							<div
								className="w-10 h-full cursor-pointer flex justify-center items-center"
								aria-hidden
								onClick={() => handleVote('NO')}
							>
								<Typography
									type="label-medium"
									label="NO"
									className="text-white-100"
								/>
							</div>
						)}
						{!voted && (
							<img
								src={Images.logoProbly}
								alt="logo"
								className="w-[68px] h-5"
							/>
						)}
						{voted ? (
							<div
								className={clsx(
									'w-10 h-full cursor-pointer flex justify-center items-center',
									(agree === 'Yes' || agreeYesNo === 'Yes') && 'bg-primary-500',
									percentage.yes === 0 && 'hidden'
								)}
								aria-hidden
								onClick={() => handleVote('YES')}
								style={{
									width: `${percentage.yes}%`,
									borderRadius: '16px 0px 16px 16px'
								}}
							>
								<Typography
									type="label-medium"
									label={`YES ${percentage.yes}%`}
									className="text-white-100"
								/>
							</div>
						) : (
							<div
								className="w-10 h-full cursor-pointer flex justify-center items-center"
								aria-hidden
								onClick={() => handleVote('YES')}
							>
								<Typography
									type="label-medium"
									label="YES"
									className="text-white-100"
								/>
							</div>
						)}
					</footer>
				)}
				{showDrawer && <DrawerSignin onClose={setShowDrawer} />}
			</div>
		)
	}
)
