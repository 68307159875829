/* eslint-disable react/require-default-props */
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { Fragment } from 'react'

export interface DrawerProps {
	open: boolean
	setOpen: (val: boolean) => void
	stretch?: boolean
	mobileFull?: boolean
	children: JSX.Element
}

export const Drawer: React.FC<DrawerProps> = ({
	open,
	setOpen,
	children,
	stretch,
	mobileFull = false
}): JSX.Element => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="flex justify-center items-center fixed inset-0 overflow-hidden z-20"
				onClose={() => setOpen(false)}
			>
				<div className="w-full h-full justify-center items-center overflow-hidden bg-[#343a40b0]">
					<Dialog.Overlay className="absolute inset-0" />

					<div
						className={clsx(
							`pointer-events-none max-w-full h-full flex justify-center pl-0 ${
								mobileFull ? 'top-0' : ''
							}`,
							'shadow-[0px_2px_5px_3px_rgba(0,0,0,0.2)]',
							'md:inset-y-0 md:shadow-none'
						)}
					>
						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-y-full md:translate-x-full md:translate-y-0"
							enterTo="translate-y-0 md:translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-y-0 md:translate-x-0"
							leaveTo="translate-y-full md:translate-x-full md:translate-y-0"
						>
							<div className="pointer-events-auto">
								<div className="w-[100dvw] xs:w-[390px] h-full flex flex-col overflow-y-hidden bg-neutral-400 shadow-xl">
									<div
										className={clsx('relative w-full h-full flex-1', {
											'py-3 px-4 sm:px-6': !stretch
										})}
									>
										{children}
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
