import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm, useWatch } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import { InfiniteData, useInfiniteQuery, useQueryClient } from 'react-query'
import { observer } from 'mobx-react'
import { t } from '@lingui/macro'
import {
	GET_TRACKED_EVENTS,
	PollResponse,
	TrackingEventsResponse,
	getTrackingEventsByKey
} from 'api'
import {
	DropdownOrganize,
	HeaderProfile,
	Input,
	LayoutApp,
	Loading,
	TrackingEventsBox
} from 'components'
import { useSearchDebounce } from 'hooks'
import { useStore } from 'stores'
import { Icons, StoryProblyType } from 'interfaces'

export const TrackingEvents: React.FC = observer(() => {
	const { authStore, storyFilterFormStore } = useStore()
	const { ref, inView } = useInView()
	const { register, control } = useForm<{ search: string }>({
		mode: 'onChange'
	})
	const watchSearch = useWatch({
		control,
		name: 'search'
	})
	const { inputSearch, handleChangeDebounce } = useSearchDebounce(watchSearch)

	const { filterFormInputs } = storyFilterFormStore

	const [trackedEvents, setTrackedEvents] = useState<StoryProblyType[]>([])
	const finalToken = authStore.token

	const queryClient = useQueryClient()

	const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
		useInfiniteQuery(
			[GET_TRACKED_EVENTS, { search: inputSearch, ...filterFormInputs }],
			({ pageParam = undefined }) =>
				getTrackingEventsByKey({
					token: finalToken,
					lastEvaluatedKey: pageParam,
					search: inputSearch,
					...filterFormInputs
				}),
			{
				getNextPageParam: lastPage => lastPage.LastEvaluatedKey,
				cacheTime: 0
			}
		)

	useEffect(() => {
		if (data?.pages) {
			let auxData = [] as StoryProblyType[]
			data.pages.forEach(pagex => {
				const auxRes = pagex?.data
				if (auxRes) auxData = [...auxData, ...auxRes]
			})
			setTrackedEvents(auxData)
		}
	}, [data])

	useEffect(() => {
		if (inView && hasNextPage) fetchNextPage()
	}, [inView])

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Tracking Events'
		})
	}, [])

	const refreshCache = (storyId: string, vote: PollResponse | null): void => {
		if (!vote) return
		queryClient.setQueriesData<
			InfiniteData<TrackingEventsResponse> | undefined
		>([GET_TRACKED_EVENTS], savedBets => {
			if (!savedBets) return savedBets
			const updatedPages = savedBets.pages.map(page => {
				const storyIndex = page.data.findIndex(
					story => story.storyId === storyId
				)
				const storyIsInPage = storyIndex !== -1
				if (!storyIsInPage) return page
				const story = page.data[storyIndex]
				const updatedData = [...page.data]
				updatedData[storyIndex] = {
					...story,
					agree: vote.agree,
					yesPercentage: vote.yesPercentage
				}
				return {
					...page,
					data: updatedData
				}
			})
			return {
				...savedBets,
				pages: updatedPages
			}
		})
	}

	return (
		<LayoutApp desktop>
			<>
				{isLoading && (
					<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
						<Loading size="large" />
					</div>
				)}
				<HeaderProfile
					title={t`Saved Bets`}
					icon={Icons.arrowBackward}
					to="/profile"
				/>
				<div className="flex flex-col items-center gap-4 p-4 bg-black-500">
					<Input
						name="search"
						register={register}
						placeholder={t`Find Bets`}
						leftImg={Icons.search}
						type="text"
						// value={inputSearch}
						onChange={handleChangeDebounce}
					/>
					<div className="relative flex justify-between items-center w-full">
						<DropdownOrganize profile />
					</div>
					<div className="w-full h-[calc(100dvh-188px)] flex flex-col items-center gap-4 overflow-x-hidden overflow-y-scroll hide-scroll-bar">
						{trackedEvents.map(event => (
							<TrackingEventsBox
								key={event.storyId}
								event={event}
								onVote={vote => refreshCache(event.storyId, vote)}
							/>
						))}
						<div ref={ref}>
							{isFetchingNextPage && (
								<div className="w-full flex justify-center items-center p-3 bg-transparent">
									<Loading size="x-small" version="v2" />
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		</LayoutApp>
	)
})
