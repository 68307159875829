import { BaseURL } from 'api'
import axios from 'axios'
import Cookies from 'universal-cookie'

export interface PollResponse {
	agree: 'No' | 'Yes'
	yesPercentage: string
	message?: string
}

const token = new Cookies()

export const postPollResponse = async (body: {
	storyId: string
	agree: 'No' | 'Yes' | null
}): Promise<PollResponse | null> => {
	const { data } = await axios.post(
		`${BaseURL}/stories/poll-response`,
		{
			storyId: body.storyId,
			agree: body.agree
		},
		{
			headers: {
				Authorization: `Bearer ${token.get('token')}`
			}
		}
	)
	return data
}

export interface PostCommentResponse {
	comment: boolean
	message?: string
}

export const postComment = async (body: {
	storyId: string
	userId: string
	comment: string
}): Promise<PostCommentResponse | null> => {
	let res = null as PostCommentResponse | null
	await axios
		.post(
			`${BaseURL}/stories/comments`,
			{
				storyId: body.storyId,
				userId: body.userId,
				comment: body.comment
			},
			{
				headers: {
					Authorization: `Bearer ${token.get('token')}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export interface SaveStoriesResponse {
	save: boolean
	message?: string
}

export interface saveBody {
	storyId: string
}
export const saveStories = async ({
	storyId
}: saveBody): Promise<SaveStoriesResponse | null> => {
	let res = null as SaveStoriesResponse | null
	await axios
		.post(
			`${BaseURL}/stories/track`,
			{
				storyId
			},
			{
				headers: {
					Authorization: `Bearer ${token.get('token')}`
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
