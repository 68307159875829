/* eslint-disable react/require-default-props */
import { Typography } from 'components'
import React from 'react'

export interface CommentBoxProps {
	comment: string
	userName: string
	userImage: string | null
}

export const CommentBox: React.FC<CommentBoxProps> = ({
	comment,
	userName,
	userImage
}): JSX.Element => {
	return (
		<div className="w-full h-12 flex justify-center items-center gap-3 my-[6px]">
			<div className="w-10 h-10 flex justify-center items-center p-[5px]">
				<div className="w-[30px] h-[30px] rounded-full bg-transparent border-2 border-white-200 flex justify-center items-center">
					{!userImage ? (
						<span className="text-xl leading-10 font-bold text-primary-500">
							{userName.split('')[0].toUpperCase()}
						</span>
					) : (
						<img
							src={userImage}
							alt={userName}
							className="object-cover w-full h-full"
						/>
					)}
				</div>
			</div>
			<div className="w-full h-full flex flex-col">
				<Typography
					type="label-medium"
					label={userName}
					className="text-white-200 !font-semibold"
				/>
				<Typography
					type="body-small"
					label={comment}
					className="text-white-200 line-clamp-2"
				/>
			</div>
		</div>
	)
}
