import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import ReactHTMLParser from 'react-html-parser'
import { t } from '@lingui/macro'
import { HeaderProfile, LayoutApp, Loading } from 'components'
import { Icons } from 'interfaces'

export const PrivacyPolicy: React.FC = (): JSX.Element => {
	const [policyHTMLString, setPolicyHTMLString] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(true)
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
			title: 'Privacy Policy'
		})
		fetch('/privacy-policy.txt')
			.then(res => res.text())
			.then(text => {
				setPolicyHTMLString(text)
				setIsLoading(false)
			})
			.catch(console.error)
	}, [])

	return (
		<LayoutApp desktop>
			<>
				<HeaderProfile
					title={t`Privacy Policy`}
					icon={Icons.arrowBackward}
					to="/subscribe"
				/>
				<div className="w-full h-[calc(100dvh-54px)] flex flex-col items-center gap-6 px-4 py-6 bg-white-100 overflow-hidden overflow-y-scroll hide-scroll-bar">
					{isLoading ? (
						<div className="flex w-full h-full items-center justify-center">
							<Loading size="large" />
						</div>
					) : (
						<div className="w-full h-full mx-[0.5rem] my-[0.5rem] bg-white">
							{policyHTMLString &&
								(ReactHTMLParser(policyHTMLString) as React.ReactNode)}
						</div>
					)}
				</div>
			</>
		</LayoutApp>
	)
}
