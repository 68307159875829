import { makeAutoObservable } from 'mobx'
import { StoryFilterInputs } from 'interfaces'
import { Sportsbooks } from 'util/constants'

const sportsbookList = Object.keys(Sportsbooks).map(
	(key: string) => Sportsbooks[key as keyof typeof Sportsbooks]
)
const initialFilterFormState: StoryFilterInputs = {
	probabilityMin: 0,
	probabilityMax: 100,
	problyScoreMin: 0,
	lastUpdatedMax: 25,
	isLive: false,
	sportsbooks: []
}
export class StoryFilterFormStore {
	private sport_id = 'all'

	private league_id = ''

	private is_order_locked = false

	private story_slided = ''

	private is_filtered = false

	private is_sportsbook_menu_open = false

	private story_filter_form_inputs: StoryFilterInputs = {
		...initialFilterFormState
	}

	constructor() {
		makeAutoObservable(this)
	}

	// ================= Getters =================
	get sportID(): string {
		return this.sport_id
	}

	get leagueID(): string {
		return this.league_id
	}

	get isOrderLocked(): boolean {
		return this.is_order_locked
	}

	get storySlided(): string {
		return this.story_slided
	}

	get filterFormInputs(): StoryFilterInputs {
		return this.story_filter_form_inputs
	}

	get isFiltered(): boolean {
		return this.is_filtered
	}

	get isProbabilityFiltered(): boolean {
		return (
			this.story_filter_form_inputs.probabilityMin !==
				initialFilterFormState.probabilityMin ||
			this.story_filter_form_inputs.probabilityMax !==
				initialFilterFormState.probabilityMax
		)
	}

	get isProblyScoreFiltered(): boolean {
		return (
			this.story_filter_form_inputs.problyScoreMin !==
			initialFilterFormState.problyScoreMin
		)
	}

	get isLastUpdatedFiltered(): boolean {
		return (
			this.story_filter_form_inputs.lastUpdatedMax !==
			initialFilterFormState.lastUpdatedMax
		)
	}

	get isLiveFiltered(): boolean {
		return (
			this.story_filter_form_inputs.isLive !== initialFilterFormState.isLive
		)
	}

	get isSportsbookListFiltered(): boolean {
		return (
			this.story_filter_form_inputs?.sportsbooks?.length !== 0 &&
			this.story_filter_form_inputs?.sportsbooks?.length !==
				sportsbookList?.length
		)
	}

	get isSportsbookMenuOpen(): boolean {
		return this.is_sportsbook_menu_open
	}

	// ================= Setters =================
	setSportID = (sportID = 'all'): void => {
		this.sport_id = sportID
	}

	setLeagueID = (leagueID = ''): void => {
		this.league_id = leagueID
	}

	setStorySlided = (storySlided = ''): void => {
		this.story_slided = storySlided
	}

	setIsOrderLocked = (isOrderLocked = false): void => {
		this.is_order_locked = isOrderLocked
	}

	setIsSportsbookMenuOpen = (isSportsBookMenuOpen = false): void => {
		this.is_sportsbook_menu_open = isSportsBookMenuOpen
	}

	submitStoryFilterForm = (
		storyFilterInputs: StoryFilterInputs = { ...initialFilterFormState }
	): void => {
		this.setIsFormFiltered(storyFilterInputs)
		this.story_filter_form_inputs = storyFilterInputs
	}

	clearStoryFilterForm = (): void => {
		const newForm: StoryFilterInputs = {
			...initialFilterFormState
		}
		this.story_filter_form_inputs = newForm
		this.is_filtered = false
	}

	// ================= Private Methods =================
	private setIsFormFiltered = (filterFormInputs: StoryFilterInputs): void => {
		this.is_filtered = Object.keys(initialFilterFormState).some(key => {
			return (
				filterFormInputs[key as keyof StoryFilterInputs] !==
				initialFilterFormState[key as keyof StoryFilterInputs]
			)
		})
	}
}
