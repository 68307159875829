import { Button, Typography } from 'components'
import { IconsType } from 'interfaces'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export interface HeaderProfileProps {
	title: string
	icon: IconsType
	to: string
}

export const HeaderProfile: React.FC<HeaderProfileProps> = ({
	title,
	icon,
	to
}): JSX.Element => {
	const navigate = useNavigate()
	const location = useLocation()
	return (
		<header className="flex items-center justify-between w-full h-[52px] px-4 bg-neutral-500">
			<Button
				color="Secondary"
				className="!h-6 !w-6 !p-[unset] !border-none"
				iconRight={icon}
				iconRightDivClassName="!w-full !h-full"
				iconRightClassName="text-primary-500"
				onClick={() => {
					if (to === '-1') {
						navigate(-1)
					} else {
						navigate(to, { state: { from: location } })
					}
				}}
			/>
			<Typography
				type="title-medium"
				label={title}
				className="text-white-200"
			/>
			<div className="w-6 h-6" />
		</header>
	)
}
