import axios from 'axios'
import { BaseURL } from 'api/environment'
import Cookies from 'universal-cookie'

export interface DeleteUserResponse {
	message?: string
	statusCode?: number
	error?: string
}

export const deleteProfile = async (): Promise<DeleteUserResponse | null> => {
	const cookies = new Cookies()
	let res = null as DeleteUserResponse | null
	await axios
		.delete(`${BaseURL}/user/profile`, {
			headers: {
				Authorization: `Bearer ${cookies.get('token')}`
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)
	return res
}
