import { EventContext } from 'context'
import React, { useMemo, useState } from 'react'

interface Props {
	children: JSX.Element
}

export const EventProvider = ({ children }: Props): JSX.Element => {
	const [sportID, setSportID] = useState('')
	const [leagueID, setLeagueID] = useState('')
	const [orderKey, setOrderKey] = useState('')
	const [storySlided, setStorySlided] = useState<string | null>(null)
	const [orderLocked, setOrderLocked] = useState<boolean>(false)

	const eventState = useMemo(
		() => ({
			sportID,
			setSportID,
			leagueID,
			setLeagueID,
			orderKey,
			setOrderKey,
			storySlided,
			setStorySlided,
			orderLocked,
			setOrderLocked
		}),
		[
			sportID,
			setSportID,
			leagueID,
			setLeagueID,
			orderKey,
			setOrderKey,
			storySlided,
			setStorySlided,
			orderLocked,
			setOrderLocked
		]
	)

	return (
		<EventContext.Provider value={eventState}>{children}</EventContext.Provider>
	)
}
