import { t } from '@lingui/macro'
import clsx from 'clsx'
import { Button, Loading, Typography } from 'components'
import { loadGapiInsideDOM } from 'gapi-script'
import { useClickOutside } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styles from './drawerSignin.module.scss'

const portal = document.getElementById('portal-modal') as HTMLElement

export interface DrawerSigninProps {
	onClose:
		| React.Dispatch<React.SetStateAction<boolean>>
		| ((shouldShow: boolean) => void)
}

export const DrawerSignin: React.FC<DrawerSigninProps> = ({
	onClose
}): JSX.Element => {
	const divRef = useRef<HTMLDivElement | null>(null)
	useClickOutside(divRef, () => onClose(false))
	// const { showToast } = useToast()
	const navigate = useNavigate()
	const location = useLocation()
	const handleClick = (): void => {
		navigate('/signin', { state: { from: location } })
	}

	const [isLoading, setIsLoading] = useState(true)

	const initGoogleLogin = async (): Promise<void> => {
		await loadGapiInsideDOM()
	}

	useEffect(() => {
		initGoogleLogin()
			.then(() => setIsLoading(false))
			.catch(err => console.error('error', err))
	}, [])

	// const responseFacebook = async (
	// 	response: ReactFacebookLoginInfo
	// ): Promise<void> => {
	// 	setIsLoading(true)
	// 	try {
	// 		console.warn('facebook reponse', response)
	// 		const res = await socialLogin(
	// 			response.email,
	// 			response.accessToken,
	// 			'facebook'
	// 		)
	// 		console.warn('res-fb', res)
	// 		if (res?.accessToken) {
	// 			cookies.set('token', res?.accessToken, { path: '/' , maxAge: 60 * 60 * 24 * 30 // 30 days,  domain: 'probly.com' })
	// 			setAccessToken(res?.accessToken)
	// 			showToast('Login Success', 'Login with Facebook is success', 'success')
	// 			onClose(false)
	// 		} else {
	// 			cookies.remove('token')
	// 			showToast(
	// 				'Error Login',
	// 				'Error: Login with Facebook is not valid',
	// 				'error'
	// 			)
	// 		}
	// 	} catch (error) {
	// 		console.error(error)
	// 	} finally {
	// 		onClose(false)
	// 		setIsLoading(false)
	// 	}
	// }

	// const responseApple = (response: any): void => {
	// 	onClose(false)
	// 	console.warn('apple response', response)
	// }

	// const responseGoogle = async (response: any): Promise<void> => {
	// 	setIsLoading(true)
	// 	// console.warn('google response', response)
	// 	try {
	// 		const res = await socialLogin(
	// 			response.wt.cu,
	// 			response.xc.access_token,
	// 			'google'
	// 		)
	// 		// console.warn('res-go', res)
	// 		if (res?.accessToken) {
	// 			cookies.set('token', res?.accessToken, { path: '/', maxAge: 60 * 60 * 24 * 30 // 30 days, domain: 'probly.com' })
	// 			setAccessToken(res?.accessToken)
	// 			showToast('Login Success', 'Login with Google is success', 'success')
	// 			onClose(false)
	// 		} else {
	// 			showToast(
	// 				'Error Login',
	// 				'Error: Login with Google is not valid',
	// 				'error'
	// 			)
	// 			cookies.remove('token')
	// 		}
	// 	} catch (error) {
	// 		console.error(error)
	// 	} finally {
	// 		onClose(false)
	// 		setIsLoading(false)
	// 	}
	// }

	return createPortal(
		<>
			{isLoading && (
				<div className="absolute inset-0 z-10 w-full h-screen flex flex-col justify-center items-center bg-black-500/80 backdrop-blur-[1px]">
					<Loading size="large" />
				</div>
			)}
			<div
				className={clsx(
					styles.drawerSigninBackdrop,
					'fixed w-screen h-screen inset-0 backdrop-blur-[1px] flex justify-center items-end bg-neutral-600/50'
				)}
			/>
			<div
				ref={divRef}
				// xs:w-full lg:w-[390px] h-[460px]
				className={clsx(
					styles.drawerSigninContainer,
					'fixed bottom-0 w-full xs:w-full lg:w-[390px] h-[300px] flex-1 bg-black-500 rounded-t-2xl py-5'
				)}
			>
				<div className="h-full flex-1">
					<div className="flex flex-col justify-center items-center gap-4">
						<Typography
							type="headline-small"
							label="Welcome"
							className="text-white-100"
						/>
						<Typography
							type="title-small"
							label={t`Sign Up to PROBLY for a Better Experience`}
							className="text-neutral-200"
						/>
					</div>
					{/* <div className="flex justify-center items-center gap-5 py-8">
						<GoogleLogin
							socialId={process.env.REACT_APP_GOOGLE__SOCIAL_ID || ''}
							className="w-20 h-[74px] flex justify-center items-center rounded-2xl bg-neutral-400 cursor-pointer"
							scope="profile email"
							fetchBasicProfile={false}
							responseHandler={responseGoogle}
							buttonText=""
						>
							<div className="w-[34px] h-[34px]">
								<Icon src={Icons.google} className="w-full h-full" />
							</div>
						</GoogleLogin>
						<AppleLogin
							clientId={process.env.REACT_APP_APPLE_ID || ''}
							redirectURI="http://localhost:3000"
							callback={responseApple}
							render={props => (
								<div
									className="w-20 h-[74px]  flex justify-center items-center rounded-2xl bg-neutral-400 cursor-pointer"
									aria-hidden
									onClick={props.onClick}
								>
									<div className="w-[34px] h-[34px]">
										<Icon src={Icons.apple} className="" />
									</div>
								</div>
							)}
						/>
						<FacebookLogin
							appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
							// autoLoad
							fields="name,email,picture"
							callback={responseFacebook}
							icon={
								<div className="w-[34px] h-[34px]">
									<Icon src={Icons.facebook} className="" />
								</div>
							}
							textButton=""
							cssClass="w-20 h-[74px] flex justify-center items-center rounded-2xl bg-neutral-400 cursor-pointer"
						/>
					</div> */}
					{/* <div className="w-full flex justify-center items-center px-4">
						<div className="w-[90px] h-[1px] bg-neutral-200" />
						<Typography
							type="label-medium"
							label="Or"
							className="w-[calc(100%-180px)] text-neutral-200 text-center"
						/>
						<div className="w-[90px] h-[1px] bg-neutral-200" />
					</div> */}
					<div className="w-full flex flex-col justify-center items-center gap-4 mt-10 px-4">
						<div className="w-full">
							<Button
								label={t`Log In`}
								classNameDiv="!w-full"
								onClick={handleClick}
							/>
						</div>
						<div
							className="cursor-pointer"
							aria-hidden
							onClick={() => onClose(false)}
						>
							<Typography
								type="title-small"
								label="Keep exploring"
								className="underline text-neutral-200"
							/>
						</div>
					</div>
					<div className="w-full flex px-4">
						<div className="w-full flex justify-center items-center gap-1 pt-4 mt-4 border-t-2 border-t-neutral-300">
							<Typography
								type="label-large"
								label={t`Don't have an account?`}
								className="text-neutral-200"
							/>
							<Link to="/register" state={{ from: location }}>
								<Typography
									type="label-large"
									label={t`Register`}
									className="text-primary-500"
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>,
		portal
	)
}
