import * as React from 'react'
import clsx from 'clsx'
import { LoadingProps } from 'interfaces'
import styles from './loading.module.scss'

export const Loading: React.FC<LoadingProps> = ({
	size = 'small',
	type = 'circular',
	version = 'v1',
	color = 'Primary'
}) => {
	// const background = {
	// 	background:
	// 		'linear-gradient(45deg,var(--color-secondary),var(--color-primary))'
	// } as React.CSSProperties

	return (
		<div
			className={clsx(
				'border',
				{
					'border-t-primary-500':
						color === 'Primary' && type === 'circular' && version === 'v1'
				},
				{
					'border-primary-500':
						color === 'Primary' && type === 'circular' && version === 'v2'
				},
				{
					'border-primary-500':
						color === 'Primary' && type === 'circular' && version === 'v3'
				},
				{
					'bg-primary-500':
						color === 'Primary' && type === 'dots' && version === 'v1'
				},
				{ 'w-4 h-4 border-2': size === 'x-small' },
				{ 'w-8 h-8 border-4': size === 'small' },
				{ 'w-10 h-10 border-8': size === 'medium' },
				{ 'w-20 h-20 border-[10px]': size === 'large' },
				{ 'w-[150px] h-[150px] border-[20px]': size === 'x-large' },
				{
					'w-1 h-1':
						type === 'dots' &&
						version === 'v2' &&
						(size === 'small' || size === 'x-small')
				},
				{
					'w-[12px] h-[12px]':
						type === 'dots' && version === 'v2' && size === 'medium'
				},
				{
					'w-[16px] h-[16px]':
						type === 'dots' &&
						version === 'v2' &&
						(size === 'large' || size === 'x-large')
				},
				type === 'circular' && version === 'v1' && `animate-spin rounded-full`,
				type === 'circular' &&
					version === 'v2' &&
					`animate-spin rounded-full ${styles.spinner2}`,
				type === 'circular' &&
					version === 'v3' &&
					`animate-spin rounded-full ${styles.spinner3}`,
				type === 'dots' &&
					version === 'v1' &&
					`border-none ${styles.dotsLoading1}`,
				type === 'dots' &&
					version === 'v2' &&
					`border-none rounded-full ${styles.dotsLoading2}`,
				type === 'dots' &&
					version === 'v3' &&
					`border-none ${styles.dotsLoading3}`
			)}
			style={{
				borderTopColor: color,
				background:
					version === 'v3' ? `conic-gradient(#0000, ${color}) content-box` : ''
			}}
		/>
	)
}
